import React, { FC, useEffect, useState } from "react";
import { Flex } from "components";
import { IFaqs } from "types/faq";
import { getFaqs } from "services/faq";
import { FaqAccordion } from "components/FaqAccordion";

export const FAQView: FC = () => {
  const [faqs, setFaqs] = useState<IFaqs>();

  useEffect(() => {
    getFaqs().then((res) => setFaqs(res));
  }, []);

  return (
    <Flex justifyContent="space-between" flexWrap="wrap">
      {faqs &&
        faqs.data.map((el) => (
          <FaqAccordion key={el.id} question={el.question} answer={el.answer} />
        ))}
    </Flex>
  );
};
