import { Flex } from "components";
import styled from "styled-components";
import { device } from "config/theme";
import { ReactComponent as MenuIcon } from "assets/icons/bx-x.svg";
import logo from "assets/img/big-white-logo.png";
import BgPattern from "assets/img/BgPattern.jpeg";
import euLogotypes from "assets/img/eu-logotypes.png";

export const EuLogo = styled.div`
  background: url(${euLogotypes});
  min-height: 20px;
  min-width: 230px;
  max-width: 100%;
  max-height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

export const Logo = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  background: url(${logo});
  min-height: 55px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`;

export const Wrapper = styled(Flex)<{ isSidebarOpen: boolean }>`
  background-size: cover !important;
  background: linear-gradient(0deg, #0460a5, #0460a5), #ffffff;
  background-blend-mode: multiply, normal, normal;
  background-position: 100%;
  transition: all 0.4s ease-in-out;
  z-index: 1000;
  position: relative;
  grid-area: sidebar;
  overflow-y: auto;
  overflow-x: hidden;
  ${({ theme }) => `
    box-shadow: 0 0px 12px  ${theme.palette.neutral.medium};
  `}

  ::after {
    content: "";
    background: url(${BgPattern});
    background-position: 45%;
    background-size: cover;
    opacity: 10%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }

  @media ${device.tablet} {
    position: absolute;
    height: 100%;
    width: 75%;
    ${({ isSidebarOpen }) => `
       margin-left: ${isSidebarOpen ? "0px" : "-100%"};
   `}
  }
`;

export const MenuToggleIcon = styled(MenuIcon)`
  align-self: flex-start;
  padding: 10px;
  height: 40px;
  margin-left: 14px;
  width: 40px;
  cursor: pointer;
  display: none;

  @media ${device.tablet} {
    display: block;
  }
`;

export const SidebarFooter = styled(Flex)`
  width: 100%;
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.dark};
  `}
`;

export const LangWrapper = styled(Flex)`
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.white};
    border-radius: 30px;
  `}
`;
