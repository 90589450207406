import { rgba } from "utilities/rgba";

export const device = {
  mobile: `(max-width: 576px)`,
  tablet: `(max-width: 768px)`,
  laptop: `(max-width: 992px)`,
  desktop: `(max-width: 1200px)`,
};

const palettePrimaryMain = "#0460A5";

export const theme = {
  palette: {
    primary: {
      main: palettePrimaryMain,
      mainDark: "#0444A5",
      dark: "#061827",
    },
    neutral: {
      mainBlue: "#005DEA",
      dark: "#061827",
      grey: "#6A7482",
      background: "#EFF1F9",
      lightGrey: "#FBFBFB",
      mediumLightGrey: "#F4F4F4",
      lightBlue: "#DFF2FB",
      light: "#EFEFEF",
      white: "#FFFFFF",
      veryLight: "#F6F6F6",
      medium: "#C7C7C7",
      orange: "#F98849",
    },
    accent: {
      yellow: "#F5D73F",
      orange: "#F5843F",
      lightBlue: "#26B9E7",
      blue: "#76b7e5",
      red: "#FB5C00",
      green: "#D09B34",
      lightGreen: "#FFF4DE",
    },
    text: {
      primary: "#1B1B1B",
      mainDark: "#0444A5",
      main: palettePrimaryMain,
      secondary: rgba("##283038", 0.7),
    },
    background: "#E2E2E2",
  },
  textStyles: {
    button: {
      fontSize: 16,
      lineHeight: "18px",
    },
    body2: {
      fontSize: 16,
      lineHeight: "18px",
    },
    body: {
      fontSize: 14,
      lineHeight: "18px",
    },
    h4: {
      fontSize: 12,
      lineHeight: "20px",
      fontWeight: 700,
    },
    h3: {
      fontSize: 20,
      lineHeight: "22px",
      fontWeight: 700,
    },
    h2: {
      fontSize: 42,
      lineHeight: "44px",
      fontWeight: 700,
    },
    h1: {
      fontSize: 62,
      lineHeight: "64px",
      fontWeight: 800,
    },
  },
};
