import React, { useRef } from "react";
import { Flex, Box, Avatar, CustomDatePicker } from "components";
import { useNavigate } from "react-router-dom";
import {
  Button,
  TextField,
  TextareaField,
  Select,
  FieldLabel,
} from "components/_form";
import { useAuthStateContext } from "contexts/AuthContext";
import { theme } from "config/theme";
import { Controller } from "react-hook-form";
import { API_PHOTO_URL } from "constants/paths";
import {
  StyledFlex,
  StyledLine,
  ResponsiveFlex,
  ResponsiveBox,
} from "views/Profile/ProfileView.styled";
import { useTranslation } from "react-i18next";
import { useFetchDictionaryLanguages } from "hooks";
import { IUserForm } from "types/user";
import { IOption } from "components/_form/Select/Select";

interface ICandidateProfileForm {
  uploadedFile?: File;
  setUploadedFile: any;
  preview?: string;
  register: any;
  control: any;
  errors: any;
  available: boolean;
  setAvailable: any;
  selectedOptions?: IUserForm["language_dic"];
}

export const CandidateProfileForm = ({
  uploadedFile,
  setUploadedFile,
  preview,
  register,
  control,
  errors,
  available,
  setAvailable,
  selectedOptions,
}: ICandidateProfileForm): JSX.Element => {
  const { dictionaryLanguages } = useFetchDictionaryLanguages();
  const { userProfile } = useAuthStateContext();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <ResponsiveFlex mb={3} alignItems="center">
        <Avatar
          size="large"
          color="grey"
          url={preview || `${API_PHOTO_URL}${userProfile?.profile?.avatar}`}
        />

        <Flex flexDirection="column" ml={3}>
          <Button
            variant="primary"
            onClick={(event) => {
              event.preventDefault();
              fileInputRef.current?.click();
            }}
          >
            {uploadedFile ? uploadedFile.name : t("changeImage")}
          </Button>
          <input
            type="file"
            multiple
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={(event) => {
              if (event.target.files) {
                setUploadedFile(event.target.files[0]);
              }
            }}
          />
        </Flex>
      </ResponsiveFlex>

      <StyledLine my={2} />

      <Flex justifyContent="space-between">
        <ResponsiveBox>
          <TextField
            label={t("firstName")}
            error={errors.first_name?.message}
            {...register("first_name")}
          />
        </ResponsiveBox>
        <ResponsiveBox>
          <TextField
            label={t("lastName")}
            error={errors.last_name?.message}
            {...register("last_name")}
          />
        </ResponsiveBox>
      </Flex>

      <StyledLine my={2} />

      <Flex justifyContent="space-between">
        <ResponsiveBox>
          <Select
            label={t("employeeStatus") as string}
            onChange={(val: IOption) => setAvailable(val?.value == "dostępny")}
            options={[
              { value: "dostępny", label: t("available") },
              { value: "niedostępny", label: t("unavailable") },
            ]}
            selectedOptions={[
              {
                value: available ? "dostępny" : "niedostępny",
                label: available ? t("available") : t("unavailable"),
              },
            ]}
          />
        </ResponsiveBox>
        <ResponsiveBox>
          {available && (
            <>
              <FieldLabel htmlFor="start_at" aria-label="start_at">
                {t("availableFrom")}
              </FieldLabel>
              <Controller
                control={control}
                name="available_from"
                render={({ field: { value, onChange } }) => (
                  <CustomDatePicker
                    value={value}
                    onChange={onChange}
                    id="available_from"
                    error={errors.available_from?.message}
                  />
                )}
              />
            </>
          )}
        </ResponsiveBox>
      </Flex>

      <StyledLine my={2} />

      <Flex justifyContent="space-between">
        <ResponsiveBox>
          <TextField
            label={t("contactNumber")}
            error={errors.contact_number?.message}
            {...register("contact_number")}
          />
        </ResponsiveBox>
        <ResponsiveBox>
          <TextField
            label={t("applicationLocalisation")}
            error={errors.location?.message}
            {...register("location")}
          />
        </ResponsiveBox>
      </Flex>

      <StyledLine my={2} />

      <Box width="100%" p={1}>
        <TextareaField
          rows={3}
          bgColor={theme.palette.neutral.white}
          label={t("experience")}
          error={errors.experience?.message}
          {...register("experience")}
        />
      </Box>
      <Box width="100%" p={1}>
        <TextareaField
          rows={3}
          bgColor={theme.palette.neutral.white}
          label={t("skills")}
          error={errors.skills?.message}
          {...register("skills")}
        />
      </Box>
      <Box width="100%" p={1}>
        <TextareaField
          rows={3}
          bgColor={theme.palette.neutral.white}
          label={t("coursesAndTraining")}
          error={errors.courses_and_training?.message}
          {...register("courses_and_training")}
        />
      </Box>
      <Box width="100%" p={1}>
        <TextField
          label={t("interests")}
          error={errors.interests?.message}
          {...register("interests")}
        />
      </Box>
      <Box width="100%" p={1}>
        <Controller
          control={control}
          name="language_dic"
          render={({ field: { onChange } }) => (
            <Select
              label={t("languages") as string}
              selectedOptions={selectedOptions}
              placeholder={t("languages") as string}
              isMulti={true}
              onChange={(val) => onChange(val)}
              options={dictionaryLanguages || []}
              ariaLabel="language_dic"
            />
          )}
        />
      </Box>

      <StyledFlex justifyContent="end" alignItems="end" mt={4}>
        <Button
          bordered={false}
          unsetBG
          variant="white"
          onClick={() => userProfile && navigate("/app/candidate-user-details")}
        >
          {t("cancel")}
        </Button>
        <Button type="submit">{t("save")}</Button>
      </StyledFlex>
    </>
  );
};
