import { Flex, Box } from "components";
import { Button } from "components/_form";
import { ColouredBox } from "components/ColouredBox";
import { StyledFlex } from "../Profile/ProfileView.styled";
import { H3, P } from "components/Typography";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import {
  ICreateCertificate,
  IUpdateCertificate,
} from "types/forms/certificate";
import { useTranslation } from "react-i18next";

interface ICertificateFormSummaryView {
  data: ICreateCertificate | IUpdateCertificate;
  setIsPreviewMode: any;
  submitTitle: string;
  summaryTitle: string;
}

export const CertificateFormSummaryView = ({
  data,
  setIsPreviewMode,
  submitTitle,
  summaryTitle,
}: ICertificateFormSummaryView) => {
  const { t } = useTranslation();
  const {
    certificate_name,
    certificate_number,
    release_date,
    expiration_date,
    file,
  } = data.certificate;

  return (
    <Flex flexWrap="wrap" justifyContent="center" alignItems="center">
      <>
        <H3 variant="h3" fontWeight={400} my={3}>
          {summaryTitle}
        </H3>
        <ColouredBox width="100%" p={3} my={2}>
          <P variant="body">
            {t("certificateName")}: {certificate_name}
          </P>
        </ColouredBox>
        <Flex
          width="100%"
          flexWrap="wrap"
          my={2}
          justifyContent="space-between"
        >
          <ColouredBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="49%"
            p={3}
          >
            <P variant="body">
              {t("releaseDate")}: {release_date}
            </P>
            <Box ml={2}>
              <CalendarIcon />
            </Box>
          </ColouredBox>
          <ColouredBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="49%"
            p={3}
          >
            <P variant="body">
              {t("expirationDate")}: {expiration_date}
            </P>
            <Box ml={2}>
              <CalendarIcon />
            </Box>
          </ColouredBox>
        </Flex>
        <ColouredBox width="100%" p={3} my={2}>
          <P variant="body">
            {t("certificateNumber")}: {certificate_number}
          </P>
        </ColouredBox>
        <Flex width="100%" mt={2}>
          <Button fullWidth>{file?.name}</Button>
        </Flex>
      </>
      <StyledFlex justifyContent="end" alignItems="end" mt={4}>
        <Button
          mr={2}
          type="button"
          onClick={() => {
            setIsPreviewMode(false);
          }}
        >
          {t("cancel")}
        </Button>
        <Button type="submit">{submitTitle}</Button>
      </StyledFlex>
    </Flex>
  );
};
