import { useAuthDispatchContext } from "contexts/AuthContext";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerUser } from "services/auth";
import styled from "styled-components";
import { Box, Flex, Link, Typography } from "components";
import { useNavigate } from "react-router";
import { useEffect, useMemo, useState } from "react";
import { Button, Checkbox, Radio, TextField } from "components/_form";
import { registerSchema } from "schemas/auth";
import { Spinner } from "components/Spinner";
import { ErrorText } from "components/_form/ErrorText";
import { UserRoleType } from "types/user";
import { FieldLabel as Label } from "components/_form/FieldLabel";
import { IRegulations } from "types/forms/regulation";
import { fetchRegulations } from "services/regulation";
import { useTranslation } from "react-i18next";

interface IRegisterFormData {
  email: string;
  password: string;
  password_confirmation: string;
  first_name?: string;
  company_name?: string;
  last_name?: string;
  rules: boolean;
  role: UserRoleType;
}

const StyledBoxWrapper = styled(Box)`
  width: 100%;

  > label {
    cursor: pointer;
    ${({ theme }) => `
      color: ${theme.palette.neutral.grey};
    `};
  }

  @media only screen and (max-width: 980px) {
    width: 90%;
  }
`;

const initialValues = {
  email: "",
  password: "",
  password_confirmation: "",
  first_name: "",
  last_name: "",
  company_name: "",
  role: "candidate" as "candidate",
};

export const RegisterView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { setToken, setUserProfile } = useAuthDispatchContext();
  const [accountRole, setAccountRole] = useState("candidate");
  const [regulations, setRegulations] = useState<IRegulations>();
  const [acceptedRegulations, setAcceptedRegulations] = useState<number[]>([]);
  const [regulationsRequired, setRegulationsRequired] = useState<
    IRegulations["data"]
  >([]);

  const checkRegulations = useMemo(() => {
    if (regulationsRequired && regulationsRequired.length) {
      return regulationsRequired
        .filter((el) => el.role == accountRole || el.role == "common")
        .every((el) => acceptedRegulations.includes(el.id));
    } else return true;
  }, [acceptedRegulations, regulationsRequired, accountRole]);

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm<IRegisterFormData>({
    mode: "onChange",
    resolver: yupResolver(registerSchema(accountRole)),
    defaultValues: initialValues,
  });

  const onRegulationsChange = (id: number, val: boolean) => {
    const exists = !!acceptedRegulations.find((el) => el === id);
    if (exists && !val)
      setAcceptedRegulations((prevState) => {
        return prevState.filter((el) => el !== id);
      });
    if (!exists && val)
      setAcceptedRegulations((prevState) => {
        return [...prevState, id];
      });
  };

  const onSubmit = async (data: IRegisterFormData) => {
    setError("");

    try {
      const response = await registerUser({
        ...data,
        regulation_ids: acceptedRegulations,
        role: accountRole as UserRoleType,
        profile_attributes: {
          first_name: data?.first_name || "",
          last_name: data?.last_name || "",
          company_name: data?.company_name || "",
        },
      });
      navigate("/?registerSuccess=true");
      if (!response.token) return;
      setToken(response.token);
      setUserProfile(response);
    } catch (err: any) {
      setError(err.error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRegulations().then((res) => {
      setRegulations(res);
      setRegulationsRequired(res?.data.filter((el) => el.required));
    });
  }, []);

  return (
    <Flex flexDirection="column" justifyContent="center">
      <Typography.H2
        variant="h2"
        color="primary"
        textAlign="start"
        fontWeight={600}
        mb={4}
      >
        {t("register")}
      </Typography.H2>

      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledBoxWrapper mb={3}>
          <TextField
            height="40px"
            label={t("eMail") + "..."}
            placeholder={t("emailPlaceholder") + "..."}
            {...register("email")}
            error={errors.email?.message}
          />
        </StyledBoxWrapper>
        <StyledBoxWrapper mb={2}>
          <TextField
            height="40px"
            type="password"
            placeholder={t("passwordPlaceholder") + "..."}
            label={t("password") + ":"}
            {...register("password")}
            error={errors.password?.message}
          />
        </StyledBoxWrapper>
        <StyledBoxWrapper mb={2}>
          <TextField
            height="40px"
            type="password"
            label={t("repeatPassword") + ":"}
            placeholder={t("passwordPlaceholder") + "..."}
            {...register("password_confirmation")}
            error={errors.password_confirmation?.message}
          />
        </StyledBoxWrapper>

        <Label>{t("accountType")}:</Label>
        <Flex mb={2}>
          <Box mr={3}>
            <Radio
              onChecked={(e) => e && setAccountRole("company")}
              label={t("company")}
              name="role"
              id="companyRole"
              value="company"
              checked={accountRole === "company"}
            />
          </Box>
          <Radio
            onChecked={(e) => e && setAccountRole("candidate")}
            label={t("candidate")}
            name="role"
            id="candidateRole"
            value="candidate"
            checked={accountRole === "candidate"}
          />
        </Flex>

        {accountRole === "candidate" ? (
          <>
            <StyledBoxWrapper mb={2}>
              <TextField
                key={"firstName"}
                height="40px"
                label={t("firstName") as string}
                {...register("first_name")}
                error={errors.first_name?.message}
              />
            </StyledBoxWrapper>
            <StyledBoxWrapper mb={3}>
              <TextField
                key={"lastName"}
                height="40px"
                label={t("lastName") as string}
                {...register("last_name")}
                error={errors.last_name?.message}
              />
            </StyledBoxWrapper>
          </>
        ) : (
          <StyledBoxWrapper mb={3}>
            <TextField
              height="40px"
              label={t("companyName") as string}
              {...register("company_name")}
              error={errors.company_name?.message}
            />
          </StyledBoxWrapper>
        )}
        {regulations &&
          regulations.data
            .filter((el) => el.role == accountRole || el.role == "common")
            .map((el) => {
              return (
                <StyledBoxWrapper
                  key={el.id}
                  display="flex"
                  justifyContent="start"
                  mb={2}
                  alignItems="center"
                >
                  <Checkbox
                    name={el.name}
                    onChange={(val) =>
                      onRegulationsChange(el.id, val.target.checked)
                    }
                    checked={
                      !!acceptedRegulations.find(
                        (accepted) => accepted === el.id
                      )
                    }
                  />
                  <Link
                    to={`/info/${el.link}`}
                    target="_blank"
                    fontSize="12px"
                    unactive={!el.link}
                  >
                    {el.name}
                  </Link>
                </StyledBoxWrapper>
              );
            })}

        {error && <ErrorText>{error}</ErrorText>}

        <Box mt={3} mb={4}>
          {isLoading ? (
            <Spinner />
          ) : (
            <Button
              variant="primary"
              width="220px"
              height="50"
              type="submit"
              id="submit"
              disabled={!formState.isValid || !checkRegulations}
            >
              {t("register")}
            </Button>
          )}
        </Box>
        <Flex>
          <Typography.P variant="body2" color="primary" mr={1}>
            {t("accountExists")}
          </Typography.P>
          <Link to="/signin" hideUnderline>
            <Typography.H4 variant="body2" fontWeight={600} color="coloured">
              {t("login")}
            </Typography.H4>
          </Link>
        </Flex>
      </form>
    </Flex>
  );
};
