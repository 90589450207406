import { Box } from "components/Box";
import React, { useEffect } from "react";
import { StylesConfig } from "react-select";
import { default as reactCreatable } from "react-select/creatable";
import styled from "styled-components";
import { FieldLabel as Label } from "components/_form/FieldLabel";
import { ErrorText } from "../ErrorText";
import { theme } from "config/theme";
import { useTranslation } from "react-i18next";

export interface IOption {
  label: string;
  value: string;
  __isNew__?: boolean;
}

interface ICreatable {
  error?: string;
  isClearable?: boolean;
  options: IOption[];
  selectedOptions?: IOption[] | IOption;

  onChange?(e: IOption | IOption[]): void;

  transparent?: boolean;
  label?: string;
  hideElements?: boolean;
  placeholder?: string;
  createLabel?: string;
}

interface IStyledTags {
  transparent?: ICreatable["transparent"];
}

const StyledCreatable = styled(reactCreatable)<IStyledTags>`
  ${({ transparent }) => `
    ${transparent && `& > div:nth-child(2) {background-color: transparent;}`}
  `}
  .valueContainer {
    opacity: 1;
  }
`;

export const Creatable: React.FC<ICreatable> = ({
  error,
  selectedOptions,
  isClearable = false,
  options,
  onChange,
  label,
  transparent,
  hideElements,
  placeholder,
  createLabel,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState<IOption[] | IOption>(
    selectedOptions || []
  );

  const customStyles: StylesConfig = {
    control: (baseStyles: any, state: any) => ({
      ...baseStyles,
      borderRadius: state.isFocused ? "20px 20px 0 0" : "20px",
      borderColor: state.isFocused ? theme.palette.primary.main : "white",
      backgroundColor: state.isFocused ? theme.palette.primary.main : "white",
      color: state.isFocused ? "white" : "",
    }),
    placeholder: (styles: any, state: any) => ({
      ...styles,
      color: state.isFocused ? "white" : "",
    }),
    input: (styles: any, state: any) => ({
      ...styles,
      color: state.isFocused ? "white" : "",
    }),
    singleValue: (styles: any, state: any) => ({
      ...styles,
      color: state.isFocused ? "white" : "",
    }),
  };
  if (hideElements) {
    customStyles.multiValue = (provided: any) => ({
      ...provided,
      display: "none",
    });
    customStyles.indicatorsContainer = (provided: any) => ({
      ...provided,
      display: "none",
    });
  }

  const handleChange = (newValue: IOption) => {
    setValue(newValue);
    if (onChange) onChange(newValue);
  };

  useEffect(() => {
    if (selectedOptions) setValue(selectedOptions);
  }, [selectedOptions]);

  return (
    <>
      <Box mb={3}>
        {label && <Label error={error}>{label}</Label>}
        <StyledCreatable
          /*// @ts-ignore*/
          onChange={handleChange}
          isClearable={isClearable}
          styles={customStyles}
          transparent={transparent}
          value={value}
          closeMenuOnSelect={true}
          options={options}
          placeholder={placeholder}
          formatCreateLabel={
            createLabel
              ? (inputValue) => {
                  return createLabel + ": " + inputValue;
                }
              : (inputValue) => {
                  return t("addNewElement") + ": " + inputValue;
                }
          }
        />
        {error && <ErrorText>{error}</ErrorText>}
      </Box>
    </>
  );
};
