import React, { useEffect, useState } from "react";
import { getApplications, getOffer } from "services/offer";
import { IOffer } from "types/offer";
import { OfferCard } from "components/_offer";
import { Flex, Spinner, Typography } from "components";
import { useNavigate } from "react-router-dom";
import { useAuthStateContext } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";

export const CandidateApplications = () => {
  const { t } = useTranslation();
  const [offers, setOffers] = useState<IOffer[]>();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { userProfile } = useAuthStateContext();

  useEffect(() => {
    setIsLoading(true);

    if (userProfile?.profile.id) {
      getApplications({ profile_id: userProfile.profile.id }).then(
        (applications) => {
          Promise.all(
            applications.data.map((application) => {
              return getOffer(application.offer.id);
            })
          ).then((res) => {
            setOffers(res);
            setIsLoading(false);
          });
        }
      );
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <Flex justifyContent="space-between" flexWrap="wrap">
          {offers && offers?.length ? (
            offers.map(({ data }) => (
              <OfferCard
                key={data.id}
                offer={data}
                button={{
                  text: t("details"),
                  onClick: () => navigate(`/app/active-offers/${data.id}`),
                }}
              />
            ))
          ) : (
            <Flex width="100%" justifyContent="center" pt={2}>
              <Typography.P variant="h2" color="dark">
                {t("noApplications")}
              </Typography.P>
            </Flex>
          )}
        </Flex>
      )}
    </>
  );
};
