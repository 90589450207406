const ONE_DAY = 24 * 60 * 60 * 1000;
const THREE_DAYS = 3 * ONE_DAY;

export const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);

export const isValidNip = (nip: string) => {
  nip = nip.replace(/[\ \-]/gi, "");

  let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  let sum = 0;
  let controlNumber = parseInt(nip.substring(9, 10));
  let weightCount = weight.length;
  for (let i = 0; i < weightCount; i++) {
    sum += parseInt(nip.substr(i, 1)) * weight[i];
  }

  return sum % 11 === controlNumber;
};

export const separator = (
  index: number,
  array: any,
  separator: string = " | "
) => (++index == array.length ? "" : separator);

export function isThreeDaysOld(date: Date): boolean {
  return new Date().getTime() - THREE_DAYS > date.getTime();
}

export function isOneDayOld(date: Date): boolean {
  return new Date().getTime() - ONE_DAY > date.getTime();
}
