import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Flex } from "components";
import { Button } from "components/_form";
import { StyledFlex } from "../Profile/ProfileView.styled";
import { updateCertificate } from "services/certificate";
import { yupResolver } from "@hookform/resolvers/yup";
import { certificateSchema } from "schemas/certificate";
import { IUpdateCertificate } from "types/forms/certificate";
import { ICertificateFormData } from "types/certificate";
import { CertificateFormSummaryView } from "./CertificateFormSummaryView";
import { getCertificate } from "services/certificate";
import { preparePatchData, prepareFormData } from "utilities/certificate";
import { CertificateForm } from "./CertificateForm";
import { useAuthStateContext } from "contexts/AuthContext";
import { ICertificate } from "types/certificate";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

export const CertificateSettingsView = (): JSX.Element => {
  const { t } = useTranslation();
  const { id = "" } = useParams<{ id: string }>();
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [isPreviewMode, setIsPreviewMode] = useState<boolean>(false);
  const [previewData, setPreviewData] = useState<IUpdateCertificate>({
    certificate: {},
  });
  const [error, setError] = useState("");

  const { userProfile } = useAuthStateContext();
  const role = userProfile?.role;

  const navigate = useNavigate();

  const {
    register,
    control,
    handleSubmit,
    getValues,
    trigger,
    reset,
    formState: { errors, isValid },
  } = useForm<ICertificateFormData>({
    mode: "onChange",
    resolver: yupResolver(certificateSchema),
  });

  const onGoToPreview = async () => {
    await trigger().then(() => {
      if (!uploadedFile) {
        toast.info(t("addCertificateForVerification"));
        return;
      }
      if (!isValid) return;
      const formData = preparePatchData(
        getValues(),
        userProfile?.id,
        uploadedFile
      );
      setPreviewData(formData);
      setIsPreviewMode(true);
    });
  };

  const navigateOnReturn = () => {
    if (role === "company") navigate("/app/certificates");
    if (role === "candidate")
      navigate("/app/candidate-user-details/certificates");
  };

  const setFormValues = (res: ICertificate) => {
    const formData = prepareFormData(res);
    reset(formData);
    setUploadedFile(res.data.file);
  };

  const fetchCertificateData = async (id: string) => {
    await getCertificate(id).then((res) => setFormValues(res));
  };

  useEffect(() => {
    id && fetchCertificateData(id);
  }, [id]);

  const onSubmit = handleSubmit(async (data) => {
    setError("");

    const reqPayload = preparePatchData(data, userProfile?.id, uploadedFile);

    try {
      const response = await updateCertificate(reqPayload, id);
      if (!response) return;
      navigateOnReturn();
    } catch (err: any) {
      setError(err.error);
    }
  });

  return (
    <Flex flexWrap="wrap" justifyContent="center" alignItems="center">
      <form onSubmit={onSubmit} style={{ width: "600px" }}>
        {!isPreviewMode ? (
          <>
            <CertificateForm
              uploadedFile={uploadedFile}
              setUploadedFile={setUploadedFile}
              register={register}
              control={control}
              errors={errors}
            />
            <StyledFlex justifyContent="end" alignItems="end" mt={4}>
              <Button mr={2} type="button" onClick={navigateOnReturn}>
                {t("cancel")}
              </Button>
              <Button type="button" onClick={onGoToPreview}>
                {t("goToSummary")}
              </Button>
            </StyledFlex>
          </>
        ) : (
          <CertificateFormSummaryView
            data={previewData}
            setIsPreviewMode={setIsPreviewMode}
            submitTitle={t("saveChanges")}
            summaryTitle={t("editCertificateSummary")}
          />
        )}
      </form>
    </Flex>
  );
};
