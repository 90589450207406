import "./ToggleSwitch.scss";

interface Props {
  id: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  name?: string;
  optionLabels?: string[];
  small?: boolean;
  disabled?: boolean;
}

export const ToggleSwitch = ({
  id,
  name,
  checked,
  onChange,
  optionLabels = ["Tak", "Nie"],
  small,
  disabled,
}: Props) => {
  return (
    <div className={"toggle-switch" + (small ? " small-switch" : "")}>
      <input
        type="checkbox"
        name={name}
        className="toggle-switch-checkbox"
        id={id}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
      />
      {id ? (
        <label className="toggle-switch-label" htmlFor={id}>
          <span
            className={
              disabled
                ? "toggle-switch-inner toggle-switch-disabled"
                : "toggle-switch-inner"
            }
            data-yes={optionLabels[0]}
            data-no={optionLabels[1]}
          />
          <span
            className={
              disabled
                ? "toggle-switch-switch toggle-switch-disabled"
                : "toggle-switch-switch"
            }
          />
        </label>
      ) : null}
    </div>
  );
};
