import { Flex, Box } from "components";
import { Button } from "components/_form";
import { useNavigate, useParams } from "react-router";
import { useEffect } from "react";
import { getMe } from "services/user";
import {
  useAuthDispatchContext,
  useAuthStateContext,
} from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { CompanyProfile } from "components/_profile/CompanyProfile";

export const CompanyProfileView = (): JSX.Element => {
  const { t } = useTranslation();
  const { userProfile } = useAuthStateContext();
  const { setUserProfile } = useAuthDispatchContext();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const fetchUserData = async () => {
    const { data } = await getMe();
    setUserProfile(data);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  if (!userProfile) return <></>;

  const { profile, addresses } = userProfile;

  return (
    <>
      <CompanyProfile company={{ ...profile, addresses }} />
      {!id && (
        <Flex justifyContent="end" alignItems="end" width="100%" mt={2}>
          <Box>
            <Button onClick={() => navigate(`settings`)}>
              {t("settings")}
            </Button>
          </Box>
        </Flex>
      )}
    </>
  );
};
