import React from "react";
import styled, { CSSProperties } from "styled-components";
import { Card } from "components";
import { Flex } from "components/Flex";
import { P } from "components/Typography";
import { useDropdown } from "hooks";
import { ReactComponent as SmallFullArrow } from "assets/icons/small-full-arrow.svg";
import { device } from "config/theme";

export interface IDropdown {
  styles?: CSSProperties;
  hideArrow?: boolean;
  items: Array<{
    label: string;
    onClick: () => void;
  }>;
  children?: React.ReactNode;
}

const Root = styled.div`
  position: relative;
`;

const WrapperChildren = styled(Flex)`
  cursor: pointer;
`;

const WrapperDropdown = styled.div`
  right: 0;
  position: absolute;
  top: 100%;
  margin-top: 16px;
  z-index: 1000;

  @media ${device.mobile} {
    right: -50px;
  }
`;

const StyledTypography = styled(P)`
  ${({ theme }) => `
    padding: 12px 0px;
    white-space: nowrap;
    cursor: pointer;
    padding: 10px;
    border-radius: 4px;

    &:hover {
      background-color: ${theme.palette.neutral.lightBlue};
    }
  `}
`;

const IconContainer = styled(Flex)<{ isOpen: boolean }>`
  justify-content: center;
  align-items: center;

  > svg {
    transition: 0.1s ease;
    ${({ isOpen }) => isOpen && "transform: rotate(180deg);"};
  }
`;

export const Dropdown: React.FC<IDropdown> = ({
  items,
  styles,
  hideArrow = false,
  children,
}) => {
  const { buttonRef, optionsRef, isVisible, setIsVisible } = useDropdown(false);

  const handleClick = (onClick: () => any) => () => {
    onClick();
    setIsVisible(false);
  };

  return (
    <Root>
      <WrapperChildren
        ref={buttonRef}
        role="button"
        aria-hidden="true"
        alignItems="center"
      >
        {children}
        {!hideArrow && (
          <IconContainer isOpen={isVisible} mr={1}>
            <SmallFullArrow />
          </IconContainer>
        )}
      </WrapperChildren>

      {isVisible && (
        <WrapperDropdown ref={optionsRef} style={styles}>
          <Card p={3}>
            {items.map(({ label, onClick }) => (
              <div
                key={label}
                onClick={handleClick(onClick)}
                aria-hidden="true"
                role="button"
              >
                <StyledTypography variant="body" color="grey">
                  {label}
                </StyledTypography>
              </div>
            ))}
          </Card>
        </WrapperDropdown>
      )}
    </Root>
  );
};
