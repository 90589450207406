import React, { useEffect, useState } from "react";
import { Flex, ColouredBox, Typography } from "components";
import { IApplications } from "types/offer";
import { ApplicationsAccordion, HireModal } from "components/_offer";
import { getOffers, getApplications } from "services/offer";
import { useAuthStateContext } from "contexts/AuthContext";
import { H3 } from "components/Typography";
import { ReactComponent as ScrollIcon } from "assets/icons/scroll.svg";
import { P } from "components/Typography";
import { Button } from "components/_form";
import { hire } from "services/offer";
import { useTranslation } from "react-i18next";

interface IOfferWithApplications {
  offer_id: number;
  offer_title: string;
  applications: IApplications["data"];
}

const updateStateAfterHiring = (
  prevState: IOfferWithApplications[],
  applicationToHire: IApplicationToHire
) => {
  return prevState.map((offer) => {
    const applicationIndex = offer.applications.findIndex(
      (el) => el.id === applicationToHire.id
    );
    if (applicationIndex !== -1) {
      const applications = [...offer.applications];
      applications[applicationIndex].hired =
        !applications[applicationIndex].hired;
      offer.applications = applications;
    }
    return offer;
  });
};

interface IApplicationToHire {
  id?: number;
  hired?: boolean;
}

export const ApplicationsView = () => {
  const { t } = useTranslation();
  const [offersWithApplications, setOffersWithApplications] =
    useState<IOfferWithApplications[]>();
  const { userProfile } = useAuthStateContext();
  const [page, setPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [applicationToHire, setApplicationToHire] =
    useState<IApplicationToHire>();

  const handleHire = async () => {
    if (!applicationToHire?.id) return;

    hire(applicationToHire.id.toString(), {
      job_application: {
        hired: !applicationToHire.hired,
      },
    }).then(() => {
      setOffersWithApplications(
        (prevState) =>
          prevState && updateStateAfterHiring(prevState, applicationToHire)
      );
    });
  };

  useEffect(() => {
    if (!userProfile || isModalOpen) return;

    const queryFilters = {
      profile_id: userProfile.profile.id,
      per_page: 20,
      page,
    };
    getOffers(queryFilters).then((offers) => {
      Promise.all(
        offers.data.map((offer) => {
          return getApplications({ offer_id: offer.id }).then(
            (applications) => {
              return {
                offer_id: offer.id,
                offer_title: offer.title,
                applications: applications.data,
              };
            }
          );
        })
      ).then((res) =>
        setOffersWithApplications((prevEntities) => {
          if (prevEntities?.length != res.length)
            return [...(prevEntities || []), ...res];
          else return res;
        })
      );
    });
  }, [page]);

  return (
    <Flex width="100%" flexDirection="column">
      <ColouredBox p={3} my={3} variant="coloured">
        <H3 variant="h3" fontWeight={600}>
          {t("applicationsForYourOffers")}:
        </H3>
      </ColouredBox>
      {offersWithApplications ? (
        offersWithApplications.map(
          ({ offer_title, offer_id, applications }) => {
            return (
              <ApplicationsAccordion
                key={offer_id}
                applications={applications}
                offer_title={offer_title}
                setIsModalOpen={setIsModalOpen}
                setApplicationToHire={setApplicationToHire}
              />
            );
          }
        )
      ) : (
        <Flex m="auto">
          <Typography.P variant="h2" color="dark">
            {t("noApplications")}
          </Typography.P>
        </Flex>
      )}
      {offersWithApplications?.length && (
        <Flex justifyContent="center" width="100%">
          <Button
            variant="white"
            unsetBG
            onClick={() => setPage((prevPage) => ++prevPage)}
          >
            <ScrollIcon />
            <P variant="body">{t("moreOffers")}</P>
          </Button>
        </Flex>
      )}
      <HireModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        applicationToHire={applicationToHire}
        setApplicationToHire={setApplicationToHire}
        handleHire={handleHire}
      />
    </Flex>
  );
};
