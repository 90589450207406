import * as yup from "yup";

export const certificateSchema = yup.object().shape({
  certificate_number: yup.string().required("certificateNumberRequired"),
  release_date: yup.date().nullable().required("releaseDateRequired"),
  expiration_date: yup.date().nullable().required("expirationDateRequired"),
  certificate_name: yup.object().shape({
    value: yup.string().required("certificateNameRequired"),
  }),
});
