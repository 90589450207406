import { H3 } from "components/Typography";
import styled from "styled-components";
import { device } from "config/theme";
import { ColouredBox } from "components";

export const StyledH3 = styled(H3)`
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 25px;
  font-size: 19px;
`;

export const ResponsiveColouredBox = styled(ColouredBox)`
  @media ${device.mobile} {
    max-width: none;
  }
`;
