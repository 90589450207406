import React from "react";
import { Typography, Flex } from "components";
import styled from "styled-components";

const StyledFlex = styled(Flex)`
  ${({ theme }) => `
    opacity: 0.8;
  `};
`;

export const Spinner = () => {
  return (
    <StyledFlex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Typography.P variant="h3" color="dark">
        Loading...
      </Typography.P>
    </StyledFlex>
  );
};
