import React, { useEffect, useState } from "react";
import { Flex, Box, Avatar, Dropdown } from "components";
import { Button } from "components/_form";
import {
  useAuthDispatchContext,
  useAuthStateContext,
} from "contexts/AuthContext";
import { useNavigate } from "react-router";
import { logoutUser } from "services/auth";
import { P } from "components/Typography";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { routes } from "routes";
import { API_PHOTO_URL } from "constants/paths";
import { NavText } from "components/NavText";
import { LangDropdown } from "components/LangDropdown";
import { useLocation } from "react-router-dom";
import { useWindowWidth } from "hooks/useWindowWidth";
import { useTranslation } from "react-i18next";
import {
  Logo,
  Wrapper,
  MenuToggleIcon,
  NoBreakSpan,
  Breadcrumbs,
  TabletBox,
  TabletFlex,
  LangWrapper,
  ZoomSize,
} from "./Navbar.styled";

export interface INavbar {
  logged: boolean;
  onMenuIconClick: () => void;
  isAppLayout?: boolean;
}

export const Navbar: React.FC<INavbar> = ({
  logged,
  onMenuIconClick,
  isAppLayout,
}) => {
  const { t } = useTranslation();
  const breadcrumbs = useBreadcrumbs(routes);
  const location = useLocation();
  const isLoginScreen = location.pathname === "/signin";

  const windowWidth = useWindowWidth();

  const navigate = useNavigate();
  const { setToken, setUserProfile } = useAuthDispatchContext();
  const { userProfile } = useAuthStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isZoomed, setIsZoomed] = useState(
    localStorage.getItem("isZoomed") === "1"
  );

  const logout = async () => {
    setIsLoading(true);
    try {
      await logoutUser();
      setToken(null);
      setUserProfile(null);
      setIsLoading(false);
      navigate("/");
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // @ts-ignore
    document.body.style.zoom = isZoomed ? 1.1 : 1;
    localStorage.setItem("isZoomed", isZoomed ? "1" : "0");
  }, [isZoomed]);

  const profileDropdownItems = [
    {
      label: t("profile"),
      onClick: () =>
        userProfile &&
        navigate(
          userProfile.role === "candidate"
            ? "/app/candidate-user-details/"
            : "/app/company-user-details/"
        ),
    },
    { label: t("logout"), onClick: logout },
  ];

  return (
    <Wrapper alignItems="center" justifyContent="flex-end">
      {!isAppLayout ? (
        <Flex alignItems="center" mr="auto">
          <TabletBox mr={4} alignItems="center">
            <Logo onClick={() => navigate("/")} />
            <NavText onClick={() => navigate("/info/o-nas")}>
              {t("aboutUs")}
            </NavText>
            <NavText onClick={() => navigate("/info/contact")}>
              {t("contact")}
            </NavText>
          </TabletBox>
          <MenuToggleIcon onClick={onMenuIconClick} />
        </Flex>
      ) : (
        <MenuToggleIcon onClick={onMenuIconClick} />
      )}

      {logged ? (
        <>
          <TabletFlex width="100%" justifyContent="flex-start">
            <Breadcrumbs>
              {breadcrumbs.map(({ breadcrumb }, index) => [
                index ? (
                  <React.Fragment key={index}>&nbsp;{"/"}&nbsp;</React.Fragment>
                ) : (
                  ""
                ),
                breadcrumb,
              ])}
            </Breadcrumbs>
          </TabletFlex>

          <Box>
            <Dropdown hideArrow items={profileDropdownItems}>
              <Avatar
                border
                url={`${API_PHOTO_URL}${userProfile?.profile?.avatar}`}
              />
              <Box>
                <P style={{ textOverflow: "ellipsis" }} variant="body" ml={2}>
                  <NoBreakSpan>{userProfile?.email}</NoBreakSpan>
                </P>
              </Box>
            </Dropdown>
          </Box>
        </>
      ) : (
        <Flex justifyContent="flex-end">
          {windowWidth > 400 && (
            <Box mr={4}>
              <Button
                boldText
                unsetWidth
                variant="white"
                unsetBG
                height="50px"
                type="submit"
                onClick={() => navigate("/signup")}
              >
                {t("register")}
              </Button>
            </Box>
          )}
          <Box mr={3}>
            <Button
              boldText
              variant={isLoginScreen ? "white" : "primary"}
              bordered={isLoginScreen}
              height="50px"
              type="submit"
              onClick={() => navigate("/signin")}
            >
              {t("login")}
            </Button>
          </Box>
        </Flex>
      )}
      {windowWidth > 400 && (
        <>
          <ZoomSize onClick={() => setIsZoomed((prevState) => !prevState)}>
            <p>{isZoomed ? "Aa-" : "Aa+"}</p>
          </ZoomSize>
          <LangWrapper>
            <LangDropdown />
          </LangWrapper>
        </>
      )}
    </Wrapper>
  );
};
