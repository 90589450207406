import styled from "styled-components";
import { Card } from "components";
import { Button } from "components/_form";
import { H3, P } from "components/Typography";
import { device } from "config/theme";
import { theme } from "config/theme";

interface IWrapper {
  isDisabled?: boolean;
  isFinished?: boolean;
}

export const Wrapper = styled(Card)<IWrapper>`
  ${({ isDisabled = false, isFinished = false }) => `
    ${isDisabled && `background-color: ${theme.palette.neutral.veryLight}`};
    ${isFinished && `background-color: ${theme.palette.neutral.medium}`};
    ${isDisabled && `opacity: 55%`};
  `}
  padding: unset;
  margin-bottom: 20px;
  box-shadow: unset;
  &:hover {
    box-shadow: 0 6px 24px -12px ${theme.palette.neutral.grey};
  }

  @media ${device.tablet} {
    width: 90%;
    margin: 0 auto 30px;
  }
`;

export const StyledImg = styled.img`
  width: 160px;
  height: 160px;
  object-fit: contain;

  @media ${device.mobile} {
    width: 80px;
    height: 80px;
  }
`;

export const StyledH3 = styled(H3)`
  text-overflow: ellipsis;
  overflow: hidden;
  @media ${device.mobile} {
    font-size: 12px;
  }
`;

export const StyledP = styled(P)`
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 20px;
  @media ${device.mobile} {
    font-size: 10px;
    max-height: 18px;
  }
`;

export const DisabledButton = styled(Button)`
  cursor: default;
  color: ${theme.palette.text.primary};
  border: 0.5px solid ${theme.palette.neutral.medium};
  &:hover {
    color: ${theme.palette.text.primary};
  }
`;

export const NewOfferIcon = styled.span`
  font-weight: 700;
  display: inline-block;
  text-align: center;
  margin: 5px;
  overflow: hidden;
  position: relative;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.neutral.orange};
  color: ${({ theme }) => theme.palette.neutral.white};
`;
export const StyledText = styled.p`
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
`;
