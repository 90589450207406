import { P } from "components/Typography";
import React, { useEffect } from "react";
import styled from "styled-components";
import { Dropdown } from "../Dropdown";
import { Flex } from "../Flex";
import { useTranslation } from "react-i18next";

export interface ILangDropdown {}

const Wrapper = styled(Flex)`
  height: 30px;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
`;

const LangDropdown: React.FC = () => {
  const { i18n } = useTranslation();

  const handleLangChange = (lang: string) => {
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang);
    window.location.reload();
  };

  useEffect(() => {
    if (localStorage.getItem("lang")) return;
    localStorage.setItem("lang", "pl");
    i18n.changeLanguage("pl");
  }, []);

  return (
    <Wrapper>
      <Dropdown
        styles={{ minWidth: "100px", top: "calc(100% + 14px)" }}
        items={[
          { label: "EN", onClick: () => handleLangChange("en") },
          { label: "PL", onClick: () => handleLangChange("pl") },
        ]}
      >
        <P variant="body2" mr={1} style={{ width: "22px" }}>
          {i18n.language.toUpperCase()}
        </P>
      </Dropdown>
    </Wrapper>
  );
};

export { LangDropdown };
