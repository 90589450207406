import { useEffect, useState } from "react";
import { Flex } from "components/Flex";
import styled from "styled-components";
import {
  LinkProps,
  NavLink,
  useLocation,
  useMatch,
  useResolvedPath,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface IMenuItem {
  path: string;
  label: string;
  private: boolean;
  type?: string;
}
export interface IMenu {
  items?: Array<IMenuItem>;
  onMenuIconClick: () => void;
  view?: "horizontal" | "vertical";
}
interface IListItem {
  active?: boolean;
}
export interface ILink {
  to: LinkProps["to"];
}

const NavigationLink = styled(NavLink)`
  text-align: center;
  ${({ theme }) => `
    display: block;
    color: ${theme.palette.text.primary};
    text-decoration: none;
    font-size: 14px;
  `};
`;
const ListItem = styled(Flex)<IListItem>`
  ${({ theme, active }) => `
    width: 100%;
    height: 50px;
    border-radius: 30px;
    background-color: ${active && theme.palette.neutral.white};  
    color: ${active ? theme.palette.text.main : theme.palette.neutral.white};
    font-weight: ${active ? "700;" : "400;"};
    &:hover {    
      font-weight: 700;
      background-color: ${theme.palette.neutral.lightBlue};        
      color: ${theme.palette.text.main};     
    }
  `}
`;

export const Menu = ({
  items,
  onMenuIconClick,
  view = "horizontal",
}: IMenu) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [active, setIsActive] = useState<string>(pathname);

  const handleOnClick = () => {
    onMenuIconClick();
  };

  useEffect(() => {
    setIsActive(pathname);
  }, [pathname]);

  const renderItems = () => {
    if (!items) return;
    return items.map(({ path, label }) => {
      const isItemSelected = active === path;

      return (
        <NavigationLink to={path} key={label}>
          <ListItem
            active={isItemSelected}
            px={4}
            my={1}
            alignItems="center"
            justifyContent="center"
            onClick={() => handleOnClick()}
          >
            {t(label)}
          </ListItem>
        </NavigationLink>
      );
    });
  };

  return (
    <Flex
      minWidth="240px"
      width="100%"
      flexDirection={view === "horizontal" ? "column" : "row"}
    >
      {renderItems()}
    </Flex>
  );
};
