import { Outlet, RouteObject } from "react-router-dom";
import { BreadcrumbsRoute } from "use-react-router-breadcrumbs";
import { ProtectedRoute } from "components/ProtectedRoute/ProtectedRoute";

//home
import { LoginView } from "views/auth/LoginView";
import { ContactView } from "views/projectData/ContactView/ContactView";
import { RegisterView } from "views/auth/RegisterView";
import { ResetPasswordView } from "views/auth/ResetPasswordView";
import { ChangePasswordView } from "views/auth/ChangePasswordView";
import { AppLayout } from "views/layouts/App/AppLayout";
import { GuestLayout } from "views/layouts/Guest/GuestLayout";
import { HomeView } from "./views/HomeView";
import { LoginConfirmView } from "./views/auth/LoginConfirmView";
import { FAQView } from "./views/FAQView";
import { CustomView } from "views/projectData/CustomView";

//certificates
import { CertificatesView } from "views/CertificatesView/CertificatesView";
import { CertificateAddView } from "views/CertificatesView/CertificateAddView";
import { CertificateSettingsView } from "views/CertificatesView/CertificateSettingsView";

//offers
import { OffersView } from "views/OffersView";
import { OfferView } from "views/OfferView/OfferView";
import { OfferSettingsView } from "views/OfferView/OfferSettingsView";

//applications
import { ApplicationsView } from "views/ApplicationsView";
import { CandidateApplications } from "views/CandidateApplications";

//candidate
import { CandidateProfileView } from "views/Profile/CandidateProfileView";
import { CandidateProfileSettingsView } from "views/Profile/CandidateProfileSettingsView";

//company
import { CompanyProfileView } from "views/Profile/CompanyProfileView";
import { CompanyProfileSettingsView } from "views/Profile/CompanyProfileSettingsView";

import { CodeEditor } from "components";

type IRoute = RouteObject & {
  breadcrumb?: BreadcrumbsRoute["breadcrumb"];
  children?: IRoute[];
};

export const routes: IRoute[] = [
  {
    path: "/",
    element: (
      <GuestLayout>
        <Outlet />
      </GuestLayout>
    ),
    children: [
      {
        index: true,
        element: <HomeView />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <ProtectedRoute isAuth>
        <GuestLayout>
          <Outlet />
        </GuestLayout>
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/signin",
        element: <LoginView />,
      },
      {
        path: "/signup",
        element: <RegisterView />,
      },
      {
        path: "/login-confirm",
        element: <LoginConfirmView />,
      },
      {
        path: "/password-reset",
        element: <ResetPasswordView />,
      },
      {
        path: "/password-reset/new-password",
        element: <ChangePasswordView />,
      },
    ],
  },
  {
    path: "/info",
    element: (
      <AppLayout>
        <Outlet />
      </AppLayout>
    ),
    children: [
      {
        path: ":slug",
        element: <CustomView />,
      },
      {
        path: "contact",
        element: <ContactView />,
      },
      {
        path: "faq",
        element: <FAQView />,
      },
    ],
  },
  {
    path: "/app",
    element: (
      <ProtectedRoute>
        <AppLayout>
          <Outlet />
        </AppLayout>
      </ProtectedRoute>
    ),
    children: [
      {
        path: "active-offers",
        element: <OffersView key="active" />,
      },

      {
        path: "active-offers/:id",
        element: <OfferView />,
      },
      {
        path: "active-offers/:id/settings",
        element: <OfferSettingsView />,
      },
      // tmp
      {
        path: "code-edit",
        element: <CodeEditor />,
      },
      //candidate
      {
        path: "candidate-user-details",
        element: <CandidateProfileView />,
      },
      {
        path: "candidate-user-details/settings",
        element: <CandidateProfileSettingsView />,
      },
      {
        path: "your-applies",
        element: <CandidateApplications />,
      },
      {
        path: "candidate-user-details/certificates",
        element: <CertificatesView />,
      },
      {
        path: "candidate-user-details/certificates/add",
        element: <CertificateAddView />,
      },
      {
        path: "candidate-user-details/certificates/:id/settings",
        element: <CertificateSettingsView />,
      },
      // company
      {
        path: "company-user-details",
        element: <CompanyProfileView />,
      },
      {
        path: "candidate-user-details/:id",
        element: <CandidateProfileView />,
      },
      {
        path: "company-user-details/settings",
        element: <CompanyProfileSettingsView />,
      },
      {
        path: "certificates",
        element: <CertificatesView />,
      },
      {
        path: "certificates/verification",
        element: <CertificateAddView />,
      },
      {
        path: "certificates/:id/settings",
        element: <CertificateSettingsView />,
      },
      {
        path: "applications",
        element: <ApplicationsView />,
      },
      {
        path: "add-offer",
        element: <OfferSettingsView />,
      },
      {
        path: "my-offers",
        element: <OffersView key="active" filter="my-offers" />,
      },
    ],
  },
];
