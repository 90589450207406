import React, { useState } from "react";
import { SideBar } from "views/layouts/App/components/Sidebar";
import { Navbar } from "views/layouts/App/components/Navbar";
import { Content, Wrapper } from "./AppLayout.styled";
import { sidebarItems } from "constants/sidebarItems";
import { navItems } from "constants/navItems";
import { useWindowWidth } from "hooks";

export const AppLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const isLoggedIn = !!localStorage.getItem("token");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const windowWidth = useWindowWidth();

  return (
    <Wrapper>
      <SideBar
        logged={isLoggedIn}
        isSidebarOpen={isSidebarOpen}
        sidebarItems={
          windowWidth < 768 ? [...navItems, ...sidebarItems] : sidebarItems
        }
        onMenuIconClick={() => setIsSidebarOpen((prevState) => !prevState)}
      />
      <Navbar
        logged={isLoggedIn}
        onMenuIconClick={() => setIsSidebarOpen((prevState) => !prevState)}
        isAppLayout={true}
      />
      <Content>{children}</Content>
    </Wrapper>
  );
};
