import * as yup from "yup";

export const offerSchema = yup.object().shape({
  title: yup.string().required("offerTitleRequired"),
  start_at: yup.date().nullable(),
  finish_at: yup.date().nullable(),
  min_range_salary: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  max_range_salary: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  requirements: yup.string(),
  responsibilities: yup.string(),
  benefits: yup.string(),
  description: yup.string(),
  locations_attributes: yup.array().of(
    yup.object().shape({
      country_id: yup.object().shape({
        value: yup.string(),
      }),
      city: yup.string(),
    })
  ),
  language_ids: yup.array(),
  working_time_ids: yup.array(),
  contract_type_ids: yup.array(),
  certificate_name_ids: yup.array(),
});
