export const navItems = [
  {
    label: "aboutUs",
    path: "/info/contact",
    private: false,
  },
  {
    label: "contact",
    path: "/info/contact",
    private: false,
  },
];

export const footerItems = [
  {
    label: "regulamin",
    path: "/info/regulamin",
    private: false,
  },
  {
    label: "privacyPolicy",
    path: "/info/polityka-prywatnosci",
    private: false,
  },
];
