import { useEffect, useState } from "react";
import { Box, Flex, Link } from "components";
import { useNavigate } from "react-router-dom";
import { useAuthStateContext } from "contexts/AuthContext";
import { IMenuItem, Menu } from "components/Menu/Menu";
import { P } from "components/Typography";
import { LangDropdown } from "components/LangDropdown";
import { useWindowWidth } from "hooks";
import { useTranslation } from "react-i18next";
import {
  EuLogo,
  Logo,
  Wrapper,
  MenuToggleIcon,
  SidebarFooter,
  LangWrapper,
} from "./Sidebar.styled";

interface ISideBar {
  logged: boolean;
  onMenuIconClick: () => void;
  isSidebarOpen: boolean;
  sidebarItems: Array<IMenuItem>;
}

export const SideBar = ({
  logged,
  onMenuIconClick,
  isSidebarOpen,
  sidebarItems,
}: ISideBar) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [menu, setMenu] = useState<Array<IMenuItem>>([]);
  const windowWidth = useWindowWidth();

  const { userProfile } = useAuthStateContext();

  const privatePathGuard = (
    isLogged: boolean,
    itemsToCheck: Array<IMenuItem>
  ) => {
    const filteredMenuItems = itemsToCheck.filter(
      ({ type }) => type === userProfile?.role || !type
    );
    isLogged
      ? setMenu(filteredMenuItems)
      : setMenu(filteredMenuItems.filter((item) => isLogged === item.private));
  };

  useEffect(() => {
    privatePathGuard(logged, sidebarItems);
  }, [logged, userProfile?.role, sidebarItems]);

  return (
    <Wrapper
      isSidebarOpen={isSidebarOpen}
      alignItems="center"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Flex alignItems="center" flexDirection="column">
        <MenuToggleIcon onClick={onMenuIconClick} />
        <Logo onClick={() => navigate("/")} />
        {windowWidth < 400 && (
          <LangWrapper px={2}>
            <LangDropdown />
          </LangWrapper>
        )}

        <Menu onMenuIconClick={onMenuIconClick} items={menu} />
      </Flex>

      <SidebarFooter
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box mb={2} mt={4}>
          <Link to="/info/regulamin">
            <P variant="body" fontWeight={400} color="white">
              {t("regulamin")}
            </P>
          </Link>
        </Box>
        <Box my={2}>
          <Link to="/info/polityka-prywatnosci">
            <P variant="body" fontWeight={400} color="white">
              {t("privacyPolicy")}
            </P>
          </Link>
        </Box>
        <Box my={2}>
          <Link to="/info/contact">
            <P variant="body" fontWeight={400} color="white">
              {t("contact")}
            </P>
          </Link>
        </Box>
        <Box my={2}>
          <P variant="body" fontWeight={400} color="grey">
            Copyrights © 2022. All rights reserved.
          </P>
        </Box>
        <Box width="100%" p={3}>
          <EuLogo />
        </Box>
      </SidebarFooter>
    </Wrapper>
  );
};
