import { useEffect, useState } from "react";
import { Flex, Box } from "components";
import { Button } from "components/_form";
import { CandidateProfile } from "components/_profile/CandidateProfile";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { getMe, getCandidate } from "services/user";
import { ICandidate } from "types/user";
import { useParams } from "react-router-dom";

export const CandidateProfileView = (): JSX.Element => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [candidateProfile, setCandidateProfile] = useState<ICandidate>();
  const navigate = useNavigate();

  const fetchUserData = async () => {
    if (id) {
      const { data } = await getCandidate(id);
      setCandidateProfile(data);
    } else {
      const { data } = await getMe();
      const prepareProfile: ICandidate = {
        ...data.profile,
        contact_email: data.email,
        certificates: data.certificates,
        languages: data.languages,
      };
      setCandidateProfile(prepareProfile);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  if (!candidateProfile) return <></>;

  return (
    <>
      <CandidateProfile candidate={candidateProfile} />
      {!id && (
        <Flex justifyContent="end" alignItems="end" width="100%" mt={2}>
          <Box>
            <Button onClick={() => navigate(`settings`)}>
              {t("settings")}
            </Button>
          </Box>
        </Flex>
      )}
    </>
  );
};
