import { ICertificate, ICertificateFormData } from "types/certificate";
import { dateToString, stringToDate } from "utilities/convertDate";

export function preparePostData(
  data: ICertificateFormData,
  user_id?: number,
  file?: File
) {
  const {
    certificate_name,
    certificate_number,
    release_date,
    expiration_date,
  } = data;

  return {
    certificate: {
      ...(!certificate_name.__isNew__ && {
        certificate_name_id: parseInt(certificate_name.value),
      }),
      certificate_name: certificate_name.__isNew__
        ? certificate_name.value
        : certificate_name.label,
      certificate_number,
      release_date: dateToString(release_date),
      expiration_date: dateToString(expiration_date),
      file,
      user_id,
    },
  };
}

export function preparePatchData(
  data: ICertificateFormData,
  user_id?: number,
  file?: File
) {
  const {
    certificate_name,
    certificate_number,
    release_date,
    expiration_date,
  } = data;

  return {
    certificate: {
      ...(!certificate_name.__isNew__ && {
        certificate_name_id: parseInt(certificate_name.value),
      }),
      ...(typeof file != "string" && { file }),
      certificate_name: certificate_name.__isNew__
        ? certificate_name.value
        : certificate_name.label,
      certificate_number,
      release_date: dateToString(release_date),
      expiration_date: dateToString(expiration_date),
      user_id,
    },
  };
}

export function prepareFormData({ data }: ICertificate): ICertificateFormData {
  return {
    certificate_name: {
      value: data.certificate_name.id.toString(),
      label: data.certificate_name.name,
    },
    certificate_number: data.certificate_number,
    release_date: stringToDate(data.release_date),
    expiration_date: stringToDate(data.expiration_date),
  };
}
