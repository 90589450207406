import { http } from "utilities/http";
import qs from "qs";

import { ICreateApplication, ICreateOffer, IHire } from "types/forms/offer";
import { serialize } from "object-to-formdata";
import {
  OFFER,
  OFFERS,
  APPLICATIONS,
  APPLICATION,
  HIRE,
} from "constants/paths";
import { IOffer, IOffers, IApplications, IApplication } from "types/offer";

export const getOffer = async (id: number | string) => {
  return await http<IOffer>(OFFER(id), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const deleteOffer = (id: number | string) =>
  http<IOffer>(OFFER(id), {
    method: "DELETE",
    headers: {
      Accept: "application/json",
    },
  });

export const updateOffer = (id: string, offer: ICreateOffer) => {
  return http<IOffer>(OFFER(id), {
    method: "PATCH",
    headers: {
      Accept: "application/json",
    },
    body: serialize(offer),
  });
};

export const createOffer = (offer: ICreateOffer) =>
  http<IOffer>(OFFERS, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: serialize(offer),
  });

export const getOffers = async (filters?: {
  filter_scope?: "available";
  page?: string | number;
  per_page?: string | number;
}) => {
  const query = qs.stringify(filters, { arrayFormat: "brackets" });

  return await http<IOffers>(`${OFFERS}?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getApplication = (id: number | string) =>
  http<IApplication>(APPLICATION(id), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

export const getApplications = async (filters?: {
  profile_id?: string | number;
  offer_id?: string | number;
}) => {
  const query = qs.stringify(filters, { arrayFormat: "brackets" });
  return await http<IApplications>(`${APPLICATIONS}?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const createApplication = async (application: ICreateApplication) => {
  const res = await http<IApplication>(APPLICATIONS, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: serialize(application),
  });

  return res.data;
};

export const hire = async (id: string, hire: IHire) => {
  return http<IHire>(HIRE(id), {
    method: "PATCH",
    headers: {
      Accept: "application/json",
    },
    body: serialize(hire),
  });
};
