import { useCallback, useEffect, useState } from "react";
import { Flex } from "components";
import { Button } from "components/_form";
import { useParams } from "react-router-dom";
import { H4 } from "components/Typography";
import { IOffer, IApplications } from "types/offer";
import { getOffer, getApplications } from "services/offer";
import {
  OfferDetails,
  DeleteButton,
  ApplicationsList,
  MainInfo,
  ApplicateButton,
  HireModal,
} from "components/_offer";
import { useNavigate } from "react-router";
import { useAuthStateContext } from "contexts/AuthContext";
import { hire } from "services/offer";
import { useTranslation } from "react-i18next";

export const OfferView = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [applicationToHire, setApplicationToHire] = useState<{
    id?: number;
    hired?: boolean;
  }>();

  const navigate = useNavigate();

  const { userProfile } = useAuthStateContext();

  const { id = "" } = useParams<{ id: string }>();

  const [offer, setOffer] = useState<IOffer["data"]>();
  const [applications, setApplications] = useState<IApplications["data"]>();

  const isUserApplicated = !applications?.find(
    (application) => application.profile.id == userProfile?.profile.id
  );

  const fetchOffer = useCallback(
    async (id: string) => {
      setError("");
      setIsLoading(true);
      try {
        const response = await getOffer(id);
        if (!response) return;
        setOffer(response.data);
      } catch (err: any) {
        setError(err.error);
      } finally {
        setIsLoading(false);
      }
    },
    [id]
  );

  const handleHire = async () => {
    if (applicationToHire?.id) {
      await hire(applicationToHire.id.toString(), {
        job_application: {
          hired: !applicationToHire.hired,
        },
      });
    }
  };

  const fetchApplications = useCallback(async (id: string) => {
    const response = await getApplications({ offer_id: id });
    setApplications(response.data);
  }, []);

  useEffect(() => {
    fetchOffer(id);
  }, []);

  useEffect(() => {
    if (!isModalOpen) fetchApplications(id);
  }, [isModalOpen]);

  return isLoading ? (
    <H4 variant="h3" fontWeight={600}>
      Loading...
    </H4>
  ) : (
    <Flex flexDirection="column">
      {offer && (
        <>
          <MainInfo offer={offer} />

          <OfferDetails offer={offer} />

          {userProfile?.role == "candidate" && isUserApplicated && (
            <ApplicateButton offer_id={offer.id} offer_title={offer.title} />
          )}

          {userProfile?.role == "company" && (
            <>
              <Flex justifyContent="end" alignItems="end">
                <DeleteButton offer_id={offer.id} offer_title={offer.title} />
                <Button onClick={() => navigate(`settings`)} ml={3}>
                  {t("editOffer")}
                </Button>
              </Flex>

              <ApplicationsList
                applications={applications}
                setIsModalOpen={setIsModalOpen}
                setApplicationToHire={setApplicationToHire}
              />
            </>
          )}
          <HireModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            applicationToHire={applicationToHire}
            setApplicationToHire={setApplicationToHire}
            handleHire={handleHire}
          />
        </>
      )}
    </Flex>
  );
};
