export const sidebarItems = [
  {
    label: "addOffer",
    path: "/app/add-offer",
    private: true,
    type: "company",
  },
  {
    label: "myOffers",
    path: "/app/my-offers",
    private: true,
    type: "company",
  },
  {
    label: "applications",
    path: "/app/applications",
    private: true,
    type: "company",
  },
  {
    label: "checkingCertificates",
    path: "/app/certificates",
    private: true,
    type: "company",
  },
  {
    label: "FAQ",
    path: "/info/faq",
    private: false,
    type: "company",
  },
  {
    label: "actualOffers",
    path: "/app/active-offers",
    private: false,
    type: "candidate",
  },
  {
    label: "myApplications",
    path: "/app/your-applies",
    private: true,
    type: "candidate",
  },
  {
    label: "myCertificates",
    path: "/app/candidate-user-details/certificates",
    private: true,
    type: "candidate",
  },
  {
    label: "FAQ",
    path: "/info/faq",
    private: false,
    type: "candidate",
  },
];
