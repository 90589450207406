import { useState } from "react";
import { Flex, Box } from "components";
import { ReactComponent as AccordionIcon } from "assets/icons/arrow-down.svg";
import { H3, P } from "components/Typography";
import { IApplications } from "types/offer";
import { ApplicationCard } from "../ApplicationCard";
import {
  StyledCard,
  StyledFlex,
  Wrapper,
} from "./ApplicationsAccordion.styled";
import { useTranslation } from "react-i18next";

export const ApplicationsAccordion = ({
  offer_title,
  applications,
  isAccordionOpen = false,
  setIsModalOpen,
  setApplicationToHire,
}: {
  offer_title: string;
  applications?: IApplications["data"];
  isAccordionOpen?: boolean;
  setIsModalOpen: any;
  setApplicationToHire: any;
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(isAccordionOpen);
  const handleClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  const isDisabled = !applications?.length;

  return (
    <StyledCard isDisabled={isDisabled} onClick={handleClick}>
      <StyledFlex justifyContent="space-between">
        <H3 variant="h3" color="coloured">
          {offer_title}
        </H3>
        <AccordionIcon />
      </StyledFlex>
      {isOpen && !isDisabled && (
        <Wrapper onClick={(e) => e.stopPropagation()}>
          {applications &&
            applications.length &&
            applications.map((application) => (
              <ApplicationCard
                key={application.id}
                application={application}
                variant="secondary"
                setIsModalOpen={setIsModalOpen}
                setApplicationToHire={setApplicationToHire}
              />
            ))}
        </Wrapper>
      )}
      {isOpen && isDisabled && (
        <Flex flexDirection="row" justifyContent="center" width="100%">
          <Box width={["30%", "20%"]}>
            <P variant="body2" m={2}>
              {t("noApplications")}
            </P>
          </Box>
        </Flex>
      )}
    </StyledCard>
  );
};
