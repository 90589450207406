import { WORKING_TIMES } from "constants/paths";
import { IWorkingTimes } from "types/workingTimes";
import { useFetchAPI } from "./useFetchAPI";

export const useFetchDictionaryWorkingTimes = () => {
  const { data } = useFetchAPI<IWorkingTimes>(WORKING_TIMES, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  return {
    dictionaryWorkingTimes: data?.data.map(({ id, name }) => ({
      label: name,
      value: id.toString(),
    })),
  };
};
