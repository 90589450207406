import { useRef } from "react";
import { Controller } from "react-hook-form";
import { Flex, Box, CustomDatePicker } from "components";
import { Button, TextField, Creatable, FieldLabel } from "components/_form";
import { ResponsiveBox } from "../Profile/ProfileView.styled";
import { useFetchDictionaryCertificates } from "hooks/useFetchDictionaryCertificates";
import { useTranslation } from "react-i18next";

interface ICertificateForm {
  uploadedFile?: File;
  setUploadedFile: any;
  register: any;
  control: any;
  errors: any;
}

export const CertificateForm = ({
  uploadedFile,
  setUploadedFile,
  register,
  control,
  errors,
}: ICertificateForm): JSX.Element => {
  const { t } = useTranslation();
  const { dictionaryCertificates } = useFetchDictionaryCertificates();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const certificateButtonPlacheholder = () => {
    if (!uploadedFile) return t("attachCertificate");
    if (uploadedFile.name) return uploadedFile.name;
    return t("updateCertificateFile");
  };

  return (
    <>
      <Box width="100%" my={3}>
        <Controller
          control={control}
          name="certificate_name"
          render={({ field: { onChange, value } }) => (
            <Creatable
              label={t("chooseCertificate") as string}
              options={dictionaryCertificates || []}
              onChange={(val) => onChange(val)}
              selectedOptions={value}
              error={errors.certificate_name?.value?.message}
              createLabel={t("addNewCertificate") as string}
              placeholder={t("enterOrChooseFromList") as string}
            />
          )}
        />
      </Box>
      <Flex flexWrap="wrap" my={3} justifyContent="space-between">
        <ResponsiveBox>
          <FieldLabel error={errors.release_date?.message}>
            {t("releaseDate")}
          </FieldLabel>
          <Controller
            control={control}
            name="release_date"
            render={({ field: { value, onChange } }) => (
              <CustomDatePicker
                value={value}
                onChange={onChange}
                error={errors.release_date?.message}
              />
            )}
          />
        </ResponsiveBox>
        <ResponsiveBox>
          <FieldLabel error={errors.expiration_date?.message}>
            {t("expirationDate")}
          </FieldLabel>
          <Controller
            control={control}
            name="expiration_date"
            render={({ field: { value, onChange } }) => (
              <CustomDatePicker
                value={value}
                onChange={onChange}
                error={errors.expiration_date?.message}
              />
            )}
          />
        </ResponsiveBox>
      </Flex>
      <Box width="100%">
        <TextField
          label={t("certificateNumber") as string}
          {...register("certificate_number")}
          error={errors.certificate_number?.message}
        />
      </Box>
      <Flex justifyContent="end" mt={4}>
        <Button
          fullWidth
          variant="primary"
          onClick={(event) => {
            event.preventDefault();
            fileInputRef.current?.click();
          }}
        >
          {certificateButtonPlacheholder()}
        </Button>

        <input
          type="file"
          multiple
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={(event) => {
            if (event.target.files) {
              setUploadedFile(event.target.files[0]);
            }
          }}
        />
      </Flex>
    </>
  );
};
