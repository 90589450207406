import { useState, useRef } from "react";
import { Box, Flex } from "components";
import { Button } from "components/_form";
import { Modal } from "components/Modal";
import { P } from "components/Typography";
import { useAuthStateContext } from "contexts/AuthContext";
import { createApplication } from "services/offer";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

export const ApplicateButton = ({
  offer_title,
  offer_id,
}: {
  offer_title: string;
  offer_id: number;
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const { userProfile } = useAuthStateContext();
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleApplicateForOffer = async () => {
    if (userProfile?.profile.id)
      try {
        await createApplication({
          job_application: {
            profile_id: userProfile.profile.id,
            offer_id,
            ...(uploadedFile && { cv: uploadedFile }),
          },
        });
        toast.success(`${t("applicationSucces")} ${offer_title}`);
      } catch (err: any) {
        setError(err.error);
      }
  };

  const onApplicateConfirmation = async () => {
    await handleApplicateForOffer();
    setIsModalOpen(false);
    navigate("/app/your-applies");
  };

  return (
    <>
      <Button onClick={() => setIsModalOpen(true)}>Aplikuj na ofertę</Button>
      <Modal isOpen={isModalOpen} onCancelClick={() => setIsModalOpen(false)}>
        <Box>
          <P variant="h3">
            {t("applyingForOffer")} {offer_title}
          </P>
          <Flex justifyContent="end" my={4}>
            <Button
              fullWidth
              bordered
              variant="white"
              onClick={(event) => {
                event.preventDefault();
                fileInputRef.current?.click();
              }}
            >
              {uploadedFile ? uploadedFile.name : t("attachCv")}
            </Button>

            <input
              type="file"
              multiple
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={(event) => {
                if (event.target.files) {
                  setUploadedFile(event.target.files[0]);
                }
              }}
            />
          </Flex>
          <Flex justifyContent="end" mt={3}>
            <Box mr={2}>
              <Button
                onClick={() => {
                  setUploadedFile(undefined);
                  setIsModalOpen(false);
                }}
              >
                {t("cancel")}
              </Button>
            </Box>
            <Button onClick={onApplicateConfirmation}>
              {t("sendApplication")}
            </Button>
          </Flex>
        </Box>
      </Modal>
    </>
  );
};
