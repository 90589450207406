import { Box } from "components";
import { H2, H3, P } from "components/Typography";
import { Button, Checkbox, TextareaField, TextField } from "components/_form";
import { theme } from "config/theme";
import { Controller, useForm } from "react-hook-form";
import { ColouredBox, ResponsiveBox, Wrapper } from "./ContactView.styled";
import contactImg from "assets/img/contactImg.png";
import { useTranslation } from "react-i18next";

export const ContactView = () => {
  const { t } = useTranslation();
  const { register, control } = useForm({
    defaultValues: {
      nameAndSurname: "",
      email: "",
      message: "",
      agreement: false,
    },
  });

  return (
    <Wrapper justifyContent="center">
      <ColouredBox>
        <H3 mb={4} variant="h3">
          Synergis BDT Sp. z o.o.
        </H3>
        <P variant="body" fontWeight={600}>
          Adama Mickiewicza 29
        </P>
        <P variant="body" fontWeight={600}>
          40-085 Katowice, Polska
        </P>
        <P variant="body" fontWeight={600}>
          NIP 6263024633
        </P>
        <Box mt={[3, 6]}>
          <img width="100%" src={contactImg} alt="contact" />
        </Box>
      </ColouredBox>
      <ResponsiveBox>
        <H2 mb={3} variant="h2">
          {t("contact")}
        </H2>
        <form>
          <Box mb={3}>
            <TextField
              label={t("nameAndSurname") as string}
              {...register("nameAndSurname")}
            />
          </Box>
          <Box mb={3}>
            <TextField
              label={t("yourEmail") as string}
              {...register("email")}
            />
          </Box>
          <Box mb={3}>
            <TextareaField
              bgColor={theme.palette.neutral.white}
              label={t("messageContent") as string}
              height="195px"
              {...register("message")}
            />
          </Box>
          <Box display="flex" justifyContent="center" mb={4} alignItems="start">
            <Controller
              control={control}
              name="agreement"
              render={({ field }) => <Checkbox onChange={field.onChange} />}
            />
            {t("regulationConsent")}
          </Box>

          <Button variant="primary" type="submit">
            {t("send")}
          </Button>
        </form>
      </ResponsiveBox>
    </Wrapper>
  );
};
