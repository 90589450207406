import { http } from "utilities/http";
import { FAQS } from "constants/paths";
import { IFaqs } from "types/faq";

export const getFaqs = async () => {
  return await http<IFaqs>(FAQS, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};
