export const API_URL = process.env.REACT_APP_API_URL + "/api/v1";
export const API_PHOTO_URL = process.env.REACT_APP_API_URL;

// auth
export const REGULATIONS_URL = `${API_URL}/regulations`;
export const PASSWORD_RESET = `${API_URL}/password`;

export const REGISTER_URL = `${API_URL}/signup`;
export const CONFIRMATION_URL = `${API_URL}/confirmation`;
export const LOGIN_URL = `${API_URL}/login`;
export const LOGOUT_URL = `${API_URL}/logout`;

//profile

export const PROFILE = `${API_URL}/profile`;
export const ME_URL = `${API_URL}/users/me`;
export const CANDIDATE = (id: string) => `${API_URL}/candidates/${id}`;
export const USER = (id: string) => `${API_URL}/users/${id}`;
export const ADDRESSES_URL = `${API_URL}/addresses`;

//offer

export const OFFER = (id: string | number) => `${API_URL}/offers/${id}`;
export const OFFERS = `${API_URL}/offers`;
export const APPLICATION = (id: string | number) =>
  `${API_URL}/job_applications/${id}`;
export const HIRE = (id: string | number) =>
  `${API_URL}/job_applications/hire/${id}`;
export const APPLICATIONS = `${API_URL}/job_applications`;

//certificate

export const CERTIFICATE = (id: string | number) =>
  `${API_URL}/certificates/${id}`;
export const CERTIFICATES = `${API_URL}/certificates`;
export const CERTIFICATE_NAMES = `${API_URL}/certificate_names`;

//dictionary

export const LANGUAGES = `${API_URL}/languages`;
export const COUNTRIES = `${API_URL}/countries`;
export const CONTRACT_TYPES = `${API_URL}/contract_types`;
export const WORKING_TIMES = `${API_URL}/working_times`;

export const PAGES = `${API_URL}/pages?page=1`;
export const PAGE = (slug: string) => `${API_URL}/pages/${slug}`;

export const FAQS = `${API_URL}/faqs`;
