import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { theme } from "config/theme";
import React, { ButtonHTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { rgba } from "utilities/rgba";
import { space, layout, LayoutProps, SpaceProps } from "styled-system";

export interface ButtonI
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    SpaceProps,
    LayoutProps {
  variant?: "primary" | "primaryDark" | "secondary" | "white" | "dark";
  disabled?: boolean;
  boldText?: boolean;
  label?: string;
  icon?: React.ReactNode;
  fullWidth?: boolean;
  bordered?: boolean;
  tooltipContent?: string;
  unsetBG?: boolean;
  unsetWidth?: boolean;
}

interface StyledButtonI extends SpaceProps, LayoutProps {
  variant: NonNullable<ButtonI["variant"]>;
  icon?: ButtonI["icon"];
  fullWidth?: ButtonI["fullWidth"];
  bordered?: ButtonI["bordered"];
  unsetBG?: boolean;
  boldText?: boolean;
  unsetWidth?: boolean;
}

const { palette, textStyles } = theme;

const colorVariantsMap = {
  primary: palette.neutral.white,
  primaryDark: palette.neutral.white,
  dark: palette.neutral.white,
  secondary: palette.neutral.white,
  white: palette.primary.main,
};

const colorHoverVariantsMap = {
  primary: palette.neutral.white,
  primaryDark: palette.neutral.white,
  dark: palette.neutral.white,
  secondary: palette.neutral.white,
  white: palette.primary.main,
};

const colorDisabledVariantsMap = {
  primary: palette.neutral.white,
  primaryDark: rgba(palette.neutral.dark, 0.4),
  dark: rgba(palette.neutral.dark, 0.4),
  secondary: rgba(palette.neutral.dark, 0.4),
  white: palette.primary.main,
};

const backgroundColorVariantsMap = {
  primary: palette.primary.main,
  primaryDark: palette.primary.mainDark,
  dark: palette.primary.dark,
  secondary: palette.primary.dark,
  white: palette.neutral.white,
};

const backgroundColorHoverVariantsMap = {
  primary: palette.primary.main,
  primaryDark: palette.primary.mainDark,
  dark: palette.primary.dark,
  secondary: palette.neutral.dark,
  white: palette.neutral.white,
};

const backgroundColorDisabledVariantsMap = {
  primary: palette.neutral.medium,
  primaryDark: palette.neutral.medium,
  dark: palette.neutral.medium,
  secondary: palette.neutral.veryLight,
  white: palette.neutral.white,
};

const borderColorVariantsMap = {
  primary: palette.neutral.white,
  primaryDark: palette.neutral.white,
  dark: palette.neutral.white,
  secondary: palette.neutral.white,
  white: palette.primary.main,
};

const StyledButton = styled.button<StyledButtonI>(
  ({ variant, fullWidth, bordered, unsetBG, boldText, unsetWidth }) => css`
    display: ${fullWidth ? "flex" : "inline-flex"};
    min-width: ${unsetWidth ? "unset;" : "200px;"};
    align-items: center;
    justify-content: center;
    ${fullWidth && `width: 100%;`};
    height: 40px;
    border-radius: 30px;
    font-family: "Lato", sans-serif;
    font-weight: ${boldText ? "700;" : "400;"};
    font-size: ${textStyles.button.fontSize}px;
    line-height: ${textStyles.button.lineHeight};
    color: ${colorVariantsMap[variant]};
    background-color: ${backgroundColorVariantsMap[variant]};
    border: 0;
    cursor: pointer;

    ${bordered && `border: 1px solid ${borderColorVariantsMap[variant]};`};
    ${unsetBG && `background: unset !important;`};

    &:hover {
      color: ${colorHoverVariantsMap[variant]};
      background-color: ${backgroundColorHoverVariantsMap[variant]};
    }

    &:disabled {
      pointer-events: none;
      color: ${colorDisabledVariantsMap[variant]};
      background-color: ${backgroundColorDisabledVariantsMap[variant]};
    }
  `,
  space,
  layout
);

export const Button: React.FC<ButtonI> = ({
  label,
  icon,
  bordered,
  variant = "primary",
  type = "button",
  disabled = false,
  boldText = false,
  unsetWidth = false,
  fullWidth,
  onClick,
  unsetBG,
  children,
  tooltipContent,
  ...rest
}) => (
  <StyledButton
    type={type}
    boldText={boldText}
    unsetWidth={unsetWidth}
    icon={icon}
    variant={variant}
    disabled={disabled}
    fullWidth={fullWidth}
    bordered={bordered}
    onClick={onClick}
    unsetBG={unsetBG}
    {...rest}
  >
    <Flex justifyContent="center" alignItems="center">
      {label}
      {icon}
      {children && <Box px={3}>{children}</Box>}
    </Flex>
  </StyledButton>
);
