import * as yup from "yup";

export const companyProfileSchema = yup.object().shape({
  profile: yup.object().shape({
    company_name: yup.string().nullable().required("nameRequired"),
    nip: yup
      .string()
      .nullable()
      .matches(/^\d{10}$/, "nipInvalid"),
    description: yup.string().nullable(),
    avatar: yup.string().nullable(),
  }),
  addresses: yup.array().of(
    yup.object().shape({
      country: yup.string().nullable().required("fieldRequired"),
      city: yup.string().nullable().required("fieldRequired"),
      zip_code: yup.string().nullable().required("fieldRequired"),
      details: yup.string().nullable().required("fieldRequired"),
      contact_number: yup
        .string()
        .nullable()
        .matches(
          /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$/,
          "contactNumberInvalid"
        )
        .required("fieldRequired"),
      contact_email: yup
        .string()
        .nullable()
        .matches(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "badEmailFormat"
        )
        .required("fieldRequired"),
    })
  ),
});

export const candidateProfileSchema = yup.object().shape({
  first_name: yup.string().nullable().required("firstNameRequired"),
  last_name: yup.string().nullable().required("lastNameRequired"),
  avatar: yup.string().nullable(),
  description: yup.string().nullable(),
  contact_number: yup
    .string()
    .nullable()
    .matches(
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$/,
      "contactNumberInvalid"
    ),
  contact_email: yup
    .string()
    .nullable()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "badEmailFormat"
    ),
  location: yup.string().nullable(),
  experience: yup.string().nullable(),
  skills: yup.string().nullable(),
  courses_and_training: yup.string().nullable(),
  interests: yup.string().nullable(),
});
