import { InputHTMLAttributes } from "react";
import styled from "styled-components";
import { rgba } from "utilities/rgba";

interface IFieldLabel {
  error?: string;
  disabled?: InputHTMLAttributes<HTMLInputElement>["disabled"];
}

export const FieldLabel = styled.label<IFieldLabel>`
  ${({ theme, disabled, error }) => `
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: ${theme.palette.text.primary};
    display: inline-block;
    width: 95%;    
    margin-bottom: 8px;
    ${error && `color: ${theme.palette.accent.red}`};
    ${disabled && `color: ${rgba(theme.palette.text.primary, 0.4)}`};
  `}
`;
