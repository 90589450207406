import { Controller } from "react-hook-form";
import { Flex, Box, CustomDatePicker } from "components";
import styled from "styled-components";
import {
  TextareaField,
  FieldLabel,
  TextField,
  Button,
  Select,
  Checkbox,
} from "components/_form/";
import { H2, P } from "components/Typography";
import { device } from "config/theme";
import { ErrorText } from "components/_form/ErrorText";
import {
  useFetchDictionaryLanguages,
  useFetchDictionaryCountries,
  useFetchDictionaryCertificates,
  useFetchDictionaryContractTypes,
  useFetchDictionaryWorkingTimes,
} from "hooks";
import { useFieldArray } from "react-hook-form";
import { ReactComponent as DeleteIcon } from "assets/icons/bx-x.svg";
import { useTranslation } from "react-i18next";

const SelectBox = styled(Box)`
  width: 32%;

  @media ${device.tablet} {
    width: 100%;
  }
`;

const DeleteButton = styled(Box)`
  cursor: pointer;
`;

interface IOfferForm {
  isLoading: boolean;
  error: any;
  register: any;
  control: any;
  errors: any;
  setValue: any;
  watch: any;
}

export const OfferForm = ({
  error,
  isLoading,
  register,
  control,
  errors,
  setValue,
  watch,
}: IOfferForm): JSX.Element => {
  const { t } = useTranslation();
  const { dictionaryLanguages } = useFetchDictionaryLanguages();
  const { dictionaryCountries } = useFetchDictionaryCountries();
  const { dictionaryCertificates } = useFetchDictionaryCertificates();
  const { dictionaryContractTypes } = useFetchDictionaryContractTypes();
  const { dictionaryWorkingTimes } = useFetchDictionaryWorkingTimes();

  const { fields, append, remove } = useFieldArray({
    name: "locations_attributes",
    control,
  });

  const addNewField = () => {
    append({
      city: "",
      country_id: { value: "", label: "" },
    });
  };

  return (
    <>
      <Box p={3}>
        <Flex flexDirection="column">
          <Flex>
            <Flex>
              <P variant="body" fontWeight={600} mr={1} my={3}>
                {t("continuousRecruitment")}
              </P>
              <Checkbox
                onChange={() => {
                  setValue(
                    "continuous_recruitment",
                    !watch("continuous_recruitment")
                  );
                }}
                checked={watch("continuous_recruitment")}
              />
            </Flex>
            <Flex ml={4}>
              <P variant="body" fontWeight={600} mr={1} my={3}>
                {t("deactivated")}
              </P>
              <Checkbox
                onChange={() => {
                  setValue("deactivated", !watch("deactivated"));
                }}
                checked={watch("deactivated")}
              />
            </Flex>
          </Flex>
          <Flex mb={3} flexWrap="wrap">
            <Flex mr={[0, 3]} flexDirection="column" width={["100%", "48%"]}>
              {!watch("continuous_recruitment") && (
                <>
                  <FieldLabel htmlFor="start_at" aria-label="start_at">
                    {t("duration")}
                  </FieldLabel>
                  <Flex>
                    <Controller
                      control={control}
                      name="start_at"
                      render={({ field: { value, onChange } }) => (
                        <CustomDatePicker
                          value={value}
                          onChange={onChange}
                          id="start_at"
                          error={errors.start_at?.message}
                        />
                      )}
                    />
                    <H2
                      variant="h2"
                      color="dark"
                      fontWeight={500}
                      mt={1}
                      mx={2}
                    >
                      -
                    </H2>
                    <label htmlFor="finish_at" aria-label="finish_at"></label>
                    <Controller
                      control={control}
                      name="finish_at"
                      render={({ field: { value, onChange } }) => (
                        <CustomDatePicker
                          value={value}
                          onChange={onChange}
                          id="finish_at"
                          error={errors.finish_at?.message}
                        />
                      )}
                    />
                  </Flex>
                </>
              )}
            </Flex>
            <Box width={["100%", "50%"]}>
              <TextField
                label={t("offerTitle")}
                placeholder={t("enterOfferTitle") + "..."}
                {...register("title")}
                aria-label="title"
                error={errors.title?.message}
              />
            </Box>
          </Flex>

          <Flex flexWrap="wrap">
            <Box mr={[0, 3]} width={["100%", "48%"]}>
              <Controller
                control={control}
                name="working_time_ids"
                render={({ field: { onChange, value } }) => (
                  <Select
                    label={t("workingTime") as string}
                    isMulti={true}
                    onChange={(val) => onChange(val)}
                    selectedOptions={value}
                    options={dictionaryWorkingTimes || []}
                    ariaLabel="working_time_ids"
                    error={errors.working_time_ids?.message}
                  />
                )}
              />
            </Box>
            <Flex flexDirection="column" width={["100%", "50%"]}>
              <FieldLabel>{t("grossSalary")}</FieldLabel>
              <Flex>
                <TextField
                  type="number"
                  {...register("min_range_salary", {
                    valueAsNumber: true,
                  })}
                  aria-label="min_range_salary"
                  unit="PLN"
                  error={errors.min_range_salary?.message}
                />
                <H2 variant="h2" color="dark" fontWeight={500} mt={1} mx={2}>
                  -
                </H2>
                <TextField
                  type="number"
                  {...register("max_range_salary", {
                    valueAsNumber: true,
                  })}
                  aria-label="max_range_salary"
                  unit="PLN"
                  error={errors.max_range_salary?.message}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex mt={3} flexWrap="wrap">
          <SelectBox mr={[0, 3]}>
            <Controller
              control={control}
              name="contract_type_ids"
              render={({ field: { onChange, value } }) => (
                <Select
                  label={t("contractType") as string}
                  isMulti={true}
                  onChange={(val) => onChange(val)}
                  selectedOptions={value}
                  options={dictionaryContractTypes || []}
                  ariaLabel="contract_type_ids"
                  error={errors.contract_type_ids?.message}
                />
              )}
            />
          </SelectBox>

          <SelectBox mr={[0, 3]}>
            <Controller
              control={control}
              name="language_ids"
              render={({ field: { onChange, value } }) => (
                <Select
                  label={t("languages") as string}
                  isMulti={true}
                  onChange={(val) => onChange(val)}
                  selectedOptions={value}
                  options={dictionaryLanguages || []}
                  ariaLabel="language_ids"
                  error={errors.language_ids?.message}
                />
              )}
            />
          </SelectBox>

          <SelectBox>
            <Controller
              control={control}
              name="certificate_name_ids"
              render={({ field: { onChange, value } }) => (
                <Select
                  label={t("certificates") as string}
                  isMulti={true}
                  onChange={(val) => onChange(val)}
                  selectedOptions={value}
                  options={dictionaryCertificates || []}
                  ariaLabel="certificate_name_ids"
                  error={errors.certificate_name_ids?.message}
                />
              )}
            />
          </SelectBox>
        </Flex>

        <Flex flexWrap="wrap" flexDirection="column">
          <Flex my={2} width="100%" justifyContent="flex-end">
            <Button type="button" bordered onClick={addNewField}>
              {t("addLocalisation")}
            </Button>
          </Flex>
          {fields.map((field, index) => (
            <Flex key={field.id}>
              <Box mr={[2, 3]} width="50%">
                <Controller
                  control={control}
                  name={`locations_attributes.${index}.country_id`}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      label={t("country") as string}
                      isMulti={false}
                      onChange={(val) => onChange(val)}
                      selectedOptions={value}
                      options={dictionaryCountries || []}
                      ariaLabel={`locations_attributes.${index}.country_id`}
                      error={
                        errors.locations_attributes &&
                        errors.locations_attributes[index]?.country_id?.message
                      }
                    />
                  )}
                />
              </Box>
              <Box width={["100%", "50%"]}>
                <TextField
                  label={t("city")}
                  {...register(`locations_attributes.${index}.city`)}
                  aria-label={`locations_attributes.${index}.city`}
                  error={
                    errors.locations_attributes &&
                    errors.locations_attributes[index]?.city?.message
                  }
                />
              </Box>
              {index != 0 && (
                <DeleteButton mt={2}>
                  <DeleteIcon onClick={() => remove(index)} />
                </DeleteButton>
              )}
            </Flex>
          ))}
        </Flex>

        <Box mt={3}>
          <TextareaField
            label={t("requirements")}
            bgColor="white"
            height="195px"
            {...register("requirements")}
            aria-label="requirements"
            placeholder={t("addRequirements") + "..."}
            error={errors.requirements?.message}
          />
        </Box>
        <Box mt={3}>
          <TextareaField
            label={t("responsibilities")}
            bgColor="white"
            height="195px"
            {...register("responsibilities")}
            aria-label="responsibilities"
            placeholder={t("addResponsibilities") + "..."}
            error={errors.responsibilities?.message}
          />
        </Box>
        <Box mt={3}>
          <TextareaField
            label={t("benefits")}
            bgColor="white"
            height="195px"
            {...register("benefits")}
            aria-label="benefits"
            placeholder={t("addBenefits") + "..."}
            error={errors.benefits?.message}
          />
        </Box>
        <Box mt={3}>
          <TextareaField
            label={t("description")}
            bgColor="white"
            height="195px"
            {...register("description")}
            aria-label="description"
            placeholder={t("addDescription") + "..."}
            error={errors.description?.message}
          />
        </Box>
      </Box>
      <Box mr={4}>{error && <ErrorText>{error}</ErrorText>}</Box>

      <Flex justifyContent="flex-end" mb={30} mr={14} mt={4}>
        {isLoading ? (
          "Loading..."
        ) : (
          <Button variant="secondary" type="submit" aria-label="submit">
            {t("save")}
          </Button>
        )}
      </Flex>
    </>
  );
};
