import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Flex } from "components";
import { Button } from "components/_form";
import { yupResolver } from "@hookform/resolvers/yup";
import { StyledFlex } from "../Profile/ProfileView.styled";
import { createCertificate } from "services/certificate";
import { certificateSchema } from "schemas/certificate";
import { ICreateCertificate } from "types/forms/certificate";
import { ICertificateFormData } from "types/certificate";
import { CertificateFormSummaryView } from "./CertificateFormSummaryView";
import { preparePostData } from "utilities/certificate";
import { CertificateForm } from "./CertificateForm";
import { useAuthStateContext } from "contexts/AuthContext";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

export const CertificateAddView = (): JSX.Element => {
  const { t } = useTranslation();
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [isPreviewMode, setIsPreviewMode] = useState<boolean>(false);
  const [previewData, setPreviewData] = useState<ICreateCertificate>({
    certificate: {
      release_date: "",
      expiration_date: "",
      certificate_number: "",
      certificate_name: "",
    },
  });
  const [error, setError] = useState("");
  const { userProfile } = useAuthStateContext();
  const role = userProfile?.role;
  const navigate = useNavigate();

  const navigateOnReturn = () => {
    if (role === "company") navigate("/app/certificates");
    if (role === "candidate")
      navigate("/app/candidate-user-details/certificates");
  };

  const {
    register,
    control,
    handleSubmit,
    getValues,
    trigger,
    formState: { errors, isValid },
  } = useForm<ICertificateFormData>({
    mode: "onChange",
    defaultValues: {
      certificate_number: "",
      release_date: new Date(),
      expiration_date: new Date(),
    },
    resolver: yupResolver(certificateSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    setError("");

    const reqPayload = preparePostData(data, userProfile?.id, uploadedFile);

    try {
      const response = await createCertificate(reqPayload);
      if (!response) return;
      navigateOnReturn();
    } catch (err: any) {
      setError(err.error);
    }
  });

  return (
    <Flex flexWrap="wrap" justifyContent="center" alignItems="center">
      <form onSubmit={onSubmit} style={{ width: "600px" }}>
        {!isPreviewMode ? (
          <>
            <CertificateForm
              uploadedFile={uploadedFile}
              setUploadedFile={setUploadedFile}
              register={register}
              control={control}
              errors={errors}
            />
            <StyledFlex justifyContent="end" alignItems="end" mt={4}>
              <Button mr={2} type="button" onClick={navigateOnReturn}>
                {t("cancel")}
              </Button>
              <Button
                type="button"
                onClick={async () => {
                  await trigger().then(() => {
                    if (isValid && uploadedFile) {
                      const formData = preparePostData(
                        getValues(),
                        userProfile?.id,
                        uploadedFile
                      );
                      setPreviewData(formData);
                      setIsPreviewMode(true);
                    } else if (!uploadedFile) {
                      toast.info(t("addFileWithCertificate"));
                    }
                  });
                }}
              >
                {t("goToSummary")}
              </Button>
            </StyledFlex>
          </>
        ) : (
          <CertificateFormSummaryView
            data={previewData}
            setIsPreviewMode={setIsPreviewMode}
            submitTitle={
              role === "company"
                ? t("sendForVerification")
                : t("addCertificate")
            }
            summaryTitle={
              role === "company"
                ? t("certificateForVerificationSummary")
                : t("addCertificateSummary")
            }
          />
        )}
      </form>
    </Flex>
  );
};
