import { Flex } from "components";
import { Box } from "components/Box";
import { device } from "config/theme";
import styled from "styled-components";

export const StyledFlex = styled(Flex)`
  width: 100%;
`;

export const ResponsiveBox = styled(Box)`
  width: 49%;
  padding: 5px;

  @media ${device.tablet} {
    padding: 0;
    width: 100%;
  }
`;

export const StyledLine = styled(Box)`
  width: 100%;
  height: 1px;
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.medium};
  `};
`;

export const ResponsiveFlex = styled(Flex)`
  @media ${device.mobile} {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
`;
