import { H3 } from "components/Typography";
import { ApplicationCard } from "./ApplicationCard";
import { ColouredBox } from "components";
import { IApplications } from "types/offer";
import { Wrapper } from "./ApplicationsAccordion/ApplicationsAccordion.styled";
import { useTranslation } from "react-i18next";

export const ApplicationsList = ({
  applications,
  setIsModalOpen,
  setApplicationToHire,
}: {
  applications?: IApplications["data"];
  setIsModalOpen: any;
  setApplicationToHire: any;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <ColouredBox p={3} my={3} variant="coloured">
        <H3 variant="h3" fontWeight={600}>
          {applications && applications.length
            ? t("applicationsForYourOffers") + ":"
            : t("noApplicationsForOffer")}
        </H3>
      </ColouredBox>
      <Wrapper>
        {applications?.map((application) => (
          <ApplicationCard
            key={application.profile.id}
            application={application}
            setIsModalOpen={setIsModalOpen}
            setApplicationToHire={setApplicationToHire}
          />
        ))}
      </Wrapper>
    </>
  );
};
