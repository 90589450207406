import { useEffect, useState } from "react";
import { Card } from "components";
import { useForm } from "react-hook-form";
import { H3 } from "components/Typography";
import { getOffer, updateOffer, createOffer } from "services/offer";
import { useNavigate, useParams } from "react-router";
import { OfferForm } from "components/_offer";
import { IOfferFormData } from "types/forms/offer";
import { prepareOfferFormData, preparePayloadData } from "utilities/offer";
import { useAuthStateContext } from "contexts/AuthContext";
import { yupResolver } from "@hookform/resolvers/yup";
import { offerSchema } from "schemas/offer";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledH3 = styled(H3)`
  font-size: 26px;
`;

const defaultOfferValues = {
  title: "",
  deactivated: false,
  continuous_recruitment: true,
  start_at: new Date(),
  finish_at: new Date(),
  min_range_salary: 1,
  max_range_salary: 1,
  requirements: "",
  responsibilities: "",
  benefits: "",
  description: "",
  working_time_ids: [],
  contract_type_ids: [],
  language_ids: [],
  locations_attributes: [{}],
  certificate_name_ids: [],
};

export const OfferSettingsView = () => {
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [locationsIds, setLocationsIds] = useState<number[]>([]);
  const { id } = useParams<{ id: string }>();
  const { userProfile } = useAuthStateContext();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: { errors },
    setValue,
    watch,
  } = useForm<IOfferFormData>({
    mode: "onChange",
    resolver: yupResolver(offerSchema),
    defaultValues: defaultOfferValues,
  });

  const onSubmit = handleSubmit(async (data) => {
    if (!userProfile?.profile?.id) {
      setError(t("noUserData") as string);
      return;
    }

    setError("");
    setIsLoading(true);

    const reqPayload = preparePayloadData(
      data,
      userProfile.profile.id,
      locationsIds
    );

    try {
      if (id) {
        const response = await updateOffer(id, reqPayload);
        if (!response) return;
        navigate(`/app/active-offers/${response.data.id}`);
      } else {
        const response = await createOffer(reqPayload);
        if (!response) return;
        navigate(`/app/active-offers/${response.data.id}`);
      }
    } catch (err: any) {
      if (err.error) {
        setError(err.error);
      } else {
        setError(t("unedintifiedError") as string);
      }
    } finally {
      setIsLoading(false);
    }
  });

  const loadOffer = async (id: number) => {
    setError("");
    setIsLoading(true);
    try {
      const offer = await getOffer(id);
      if (!offer) return;
      const dataToReset = prepareOfferFormData(offer);
      setLocationsIds(offer.data.locations.map((location) => location.id));
      reset(dataToReset);
    } catch (err: any) {
      setError(err.error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      loadOffer(+id);
    }
  }, [id]);

  return (
    <Card>
      <StyledH3 mt={2} mb={3} variant="h3" fontWeight={600} color="coloured">
        {id ? t("editOffer") : t("addNewOffer")}
      </StyledH3>

      <form onSubmit={onSubmit}>
        <OfferForm
          error={error}
          isLoading={isLoading}
          register={register}
          control={control}
          errors={errors}
          setValue={setValue}
          watch={watch}
        />
      </form>
    </Card>
  );
};
