import { http } from "utilities/http";

import { CERTIFICATES, CERTIFICATE } from "constants/paths";
import { ICertificates, ICertificate } from "types/certificate";
import {
  ICreateCertificate,
  IUpdateCertificate,
} from "types/forms/certificate";
import { serialize } from "object-to-formdata";

export const getCertificates = async () => {
  return await http<ICertificates>(`${CERTIFICATES}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getCertificate = async (id: string) => {
  return await http<ICertificate>(`${CERTIFICATES}/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const createCertificate = (certificate: ICreateCertificate) =>
  http<ICertificate>(CERTIFICATES, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: serialize(certificate),
  });

export const updateCertificate = (
  certificate: IUpdateCertificate,
  id: string
) =>
  http<ICertificate>(CERTIFICATE(id), {
    method: "PATCH",
    headers: {
      Accept: "application/json",
    },
    body: serialize(certificate),
  });

export const deleteCertificate = (id: string | number) =>
  http<ICertificate>(CERTIFICATE(id), {
    method: "DELETE",
    headers: {
      Accept: "application/json",
    },
  });
