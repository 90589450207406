import React from "react";
import "./index.css";
import { GlobalStyles } from "GlobalStyles";
import { ThemeProvider } from "styled-components";
import { theme } from "config/theme";
import reportWebVitals from "./reportWebVitals";
import { AuthContextProvider } from "contexts/AuthContext";
import "./config/i18n";

import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { FiltersContextProvider } from "contexts/FiltersContext";

const root = ReactDOM.createRoot(document.getElementById("root") as Element);

root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <AuthContextProvider>
        <FiltersContextProvider>
          <App />
        </FiltersContextProvider>
      </AuthContextProvider>
      <GlobalStyles />
    </ThemeProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
