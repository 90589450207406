import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { confirmUser } from "services/auth";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

export const LoginConfirmView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  if (!token) {
    toast.error(t("tokenDoesntExists") + "!");
    return <></>;
  }

  confirmUser(token)
    .then(() => navigate("/signin?confirmSuccess=true"))
    .catch((err) => {
      !!err?.confirmation_token?.length &&
        toast.error(`Token ${err.confirmation_token[0]}` || t("error"));
      !!err?.email?.length &&
        toast.error(`Email ${err.email[0]}` || t("error"));
    });

  return <></>;
};
