const auth = {
  password: "Password",
  passwordPlaceholder: "Enter password",
  repeatPassword: "Confirm password",
  emailPlaceholder: "Enter email address",
  accountType: "Account type",
  login: "Login",
  logout: "Logout",
  register: "Register",
  reset: "Reset",
  resetPassword: "Reset password",
  changePassword: "Change password",
  sendEmail: "Send e-mail",
  accountConfirmed: "The account has been confirmed",
  checkEmail: "Check your e-mail",
  passwordChangedSuccess: "Password has been changed",
  emailForLink: "Enter an e-mail to get a link",
  forgotPass: "Forgot password?",
  accountless: "Don't have account?",
  accountExists: "An account exists?",
};

const menu = {
  addOffer: "Add Offer",
  myTasks: "My tasks",
  myOffers: "My offers",
  applications: "Submissions/Applications",
  checkingCertificates: "Checking certificates",
  actualOffers: "Actual offers",
  myApplications: "My applications",
  myCertificates: "My certificates",
};

const common = {
  privacyPolicy: "Privacy Policy",
  regulamin: "Regulamin",
  aboutUs: "About us",
};

const error = {
  addressesEditError: "An error occurred while editing addresses",
  error: "An error occured",
  dataEditionError: "Failed to update data",
  unedintifiedError: "Something gone wrong",
  noUserData: "No user data",
};

const validation = {
  phoneRequired: "Phone is required",
  fieldRequired: "Field is required",
  emailRequired: "Email is required",
  passwordRequired: "Password is required",
  firstNameRequired: "First name is required",
  lastNameRequired: "Last name is required",
  nameRequired: "Name is required",
  nipRequired: "NIP is required",
  certificateNumberRequired: "Certificate number is required",
  releaseDateRequired: "Release date is required",
  expirationDateRequired: "Expiration date is required",
  certificateNameRequired: "Certificate name is required",
  offerTitleRequired: "Offer title is required",
  passDiffErr: "The passwords do not match",
  passMinVal: "The password should contain at least 6 characters",
  passMaxVal: "The password can be up to 18 characters long",
  badEmailFormat: "Invalid email format",
  contactNumberInvalid: "Contact number is invalid",
  nipInvalid: "NIP is invalid",
  postCodeInvalid: "Post code is invalid",
  tokenDoesntExists: "Token does not exists",
};

const functional = {
  delete: "Delete",
  cancel: "Cancel",
  settings: "Settings",
  edit: "Edit",
  send: "Send",
  yes: "Yes",
  no: "No",
  addNewElement: "Add new element",
  collapse: "Collapse",
  expand: "Expand",
  changeImage: "Change image",
  save: "Save",
  apply: "Apply",
  search: "Search",
};

const application = {
  viewProfile: "View profile",
  beenHired: "Are employed", //?
  noApplications: "No applications",
  applicationsForYourOffers: "Applications for your offer",
  noApplicationsForOffer: "No applications for your offer",
  areYouSureToHire: "Are you sure you want to hire candidate?",
  areYouSureToCancelHire: "Are you want to cancel hiring?", //?
  applicationSucces: "Succesfully applied for the offer",
  attachCv: "Attach cv (optional)",
};

const address = {
  country: "Country",
  city: "City",
  contactNumber: "Contact number",
  eMail: "E-mail",
  nip: "Nip",
  address: "Address",
  postCode: "Post code",
  addressArea: "Address (Area and Street)",
  yourEmail: "Your e-mail",
  applicationLocalisation: "Localisation of look for job", //?
};

const offer = {
  // fields
  duration: "Duration",
  continuousRecruitment: "Continuous recruitment",
  finished: "Finished",
  grossSalary: "Gross salary",
  workingTime: "Working time",
  contractType: "Contract type",
  deactivated: "Unactive",
  requirements: "Requirements",
  responsibilities: "Responsibilities",
  benefits: "Benefits",
  offerTitle: "Offer title",
  localisation: "Localisation",
  salary: "Salary",
  from: "from",
  offerFinishDate: "Offer finish date",
  companyOrOfferName: "Company/offer name",
  filters: "Filters",
  //
  addNewOffer: "Add new offer",
  enterOfferTitle: "Enter offer title",
  addLocalisation: "Add localisation",
  addRequirements: "Add requirements",
  addResponsibilities: "Add responsibilities",
  addBenefits: "Add benefits",
  addDescription: "Add description",
  youApplied: "You applied",
  youHaveAlreadyApplied: "You have applied", //?
  newOffer: "New offer",
  applyingForOffer: "Applying for offer",
  sendApplication: "Send application",
  areYouSureToDeleteOffer: "Are you sure you want to delete offer?",
  editOffer: "Edit offer",
  noOffers: "No offers",
  offerDeletionSuccesfull: "Offer deleted succesfully",
  deleteOffer: "Delete offer",
};

const userDetails = {
  //field
  available: "Available",
  availableFrom: "Available from",
  unavailable: "Unavailable",
  informations: "Informations",
  experience: "Experience",
  skills: "Skills",
  coursesAndTraining: "Courses and trainings",
  interests: "Interests",
  candidateStatus: "Candidate status",
  firstName: "First name",
  lastName: "Last name",
  employeeStatus: "Employee status",
  companyName: "Company name",
  //
  updatedProfileData: "Profile details updated",
  profile: "Profile",
  company: "Company",
  candidate: "Candidate",
  noInfo: "no information",
};

const certificate = {
  //fields
  releaseDate: "Release date",
  expirationDate: "Expiration date",
  certificateNumber: "Certificate number",
  certificateName: "Certificate name",
  //
  certificateForVerificationSummary: "Certificate for verification - summary",
  addCertificateSummary: "Add certificate - summary",
  editCertificateSummary: "Certificate edition - summary",
  areYouSureToDeleteCertificate: "Are you sure you want to delete certificate?",
  noCertificates: "No certificates",
  attachCertificate: "Attach certificate",
  addFileWithCertificate: "Add certificate file",
  addCertificateForVerification: "Add certificate for verification",
  saveChanges: "Save changes",
  goToSummary: "Go to summary",
  sendForVerification: "Send for verification",
  addCertificate: "Add certificate",
  chooseCertificate: "Choose certificate",
  addNewCertificate: "Add new certificate",
  enterOrChooseFromList: "Enter or choose from list",
  updateCertificateFile: "Update certificate file",
};

const home = {
  mainPageTitle: "Nowoczesny portal rekrutacyjny", //$
  companySlogan: "My weryfikujemy, Ty rekrutujesz!", //$
  checkNow: "Check now",
  cookieInfoPartOne:
    "Strona wykorzystuje pliki cookies. Korzystanie z witryny bez zmiany ustawień przeglądarki oznacza, że będą one umieszczane na Twoim urządzeniu. Zobacz dodatkowe informacje o wykorzystaniu plików cookies Zapoznaj się też z", //$
  cookieInfoPartTwo: "Polityką Prywatności", //$
  cookieAccept: "Accept cookies",
};

const contact = {
  nameAndSurname: "Name and surname",
  messageContent: "Message content",
  regulationConsent:
    "Wyrażam zgodę na przetwarzanie moich danych osobowych podanych formularzu w celu uzyskania od powiedzi na zapytanie. Moja zgoda jest dobrowolna, a jednocześnie niezbędna do uzyskania odpowiedzi.", //$
};

const shared = {
  certificates: "Certificates",
  languages: "Languages",
  moreOffers: "More offers",
  companyDescription: "Company description",
  description: "Description",
  details: "Details",
  addAddress: "Add address",
  contact: "Contact",
};

export default {
  ...auth,
  ...menu,
  ...common,
  ...error,
  ...validation,
  ...functional,
  ...application,
  ...address,
  ...offer,
  ...userDetails,
  ...certificate,
  ...home,
  ...contact,
  ...shared,
};
