import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import CodeMirror from "@uiw/react-codemirror";
import { python } from "@codemirror/lang-python";
import { java } from "@codemirror/lang-java";
import { javascript } from "@codemirror/lang-javascript";
import { html } from "@codemirror/lang-html";
import { css } from "@codemirror/lang-css";
import { dracula } from "@uiw/codemirror-theme-dracula";
import { okaidia } from "@uiw/codemirror-theme-okaidia";
import { Extension } from "@codemirror/state";

import { Select, IOption } from "components/_form/Select/Select";
import useLocalStorage from "hooks/useLocalStorage";
import { dragElement } from "utilities/split";
import { capitalize } from "utilities/functions";
import { Flex } from "components";
import { Box } from "components";
import {
  languages,
  programmingLanguages,
} from "constants/programmingLanguages";
import { device } from "config/theme";

const DisplayName = styled.h4`
  margin: 0;
  text-align: center;
`;
const CodeSection = styled.div`
  width: ${`calc((100% - 20px) / 3)`};

  @media ${device.tablet} {
    width: 250px;
  }
`;
const Separator = styled.div`
  width: 10px;
`;

type themes = "dracula" | "okaidia";

interface ICodePlace {
  theme: string;
  language: string;
  displayName: string;
  value: string;
  onChange?: any;
}

const themeOptions: IOption[] = [
  { label: "Dracula", value: "dracula" },
  { label: "Okaidia", value: "okaidia" },
];
const themeObjects: { [key: string]: Extension } = { dracula, okaidia };

export const CodePlace: React.FC<ICodePlace> = ({
  language,
  theme,
  displayName,
  value,
  onChange,
}) => {
  const onCodeChange = useCallback(
    (value: string) => {
      onChange && onChange(value);
    },
    [value]
  );

  let extensions = [];
  switch (language) {
    case "html":
      extensions.push(html());
      break;
    case "css":
      extensions.push(css());
      break;
    case "javascript":
      extensions.push(javascript({ jsx: true }));
      break;
    case "java":
      extensions.push(java());
      break;
    case "python":
      extensions.push(python());
      break;
  }

  return (
    <div style={{ overflow: "hidden", width: "100%" }}>
      <DisplayName>{displayName}</DisplayName>
      <CodeMirror
        value={value}
        height="500px"
        width="100%"
        minWidth="100%"
        theme={themeObjects[theme]}
        extensions={extensions}
        onChange={onCodeChange}
        placeholder="Wprowadź rozwiązanie"
      />
    </div>
  );
};

interface CodeEditorProps {
  language?: languages;
  customSetCode?: (value: string) => void;
  defaultValue?: string;
}

export const CodeEditor = ({
  language,
  customSetCode,
  defaultValue,
}: CodeEditorProps) => {
  // todo add users ID to key???
  const [html, setHtml] = useLocalStorage("html", "");
  const [css, setCss] = useLocalStorage("css", "");
  const [js, setJs] = useLocalStorage("js", "");
  const [javaCode, setJavaCode] = useLocalStorage("java", "");
  const [pythonCode, setPythonCode] = useLocalStorage("python", "");
  const [srcDoc, setSrcDoc] = useState("");

  const [lang, setLang] = useState<languages>(language ?? "javascript");
  const [theme, setTheme] = useState<themes>("dracula");

  const languageData: { [key: string]: any } = {
    javascript: {
      language: "html",
      displayName: "HTML",
      value: html,
      onChange: (value: string) => {
        setHtml(value);
      },
    },
    java: {
      language: "java",
      displayName: "Java",
      value: javaCode,
      onChange: (value: string) => {
        setJavaCode(value);
        customSetCode && customSetCode(value);
      },
    },
    python: {
      language: "python",
      displayName: "Python",
      value: pythonCode,
      onChange: (value: string) => {
        setPythonCode(value);
        customSetCode && customSetCode(value);
      },
    },
  };

  const [editorData, setEditorData] = useState(
    language
      ? languageData[language] || languageData["javascript"]
      : languageData["javascript"]
  );

  const onLanguageChange = (val: IOption) => {
    setLang(val?.value as languages);
    setEditorData(languageData[val?.value]);
  };
  const onThemeChange = (val: IOption) => {
    setTheme(val.value as themes);
  };

  // for WEB only
  useEffect(() => {
    const timeout = setTimeout(() => {
      const resultDoc = `
        <html lang="en" style="background: white; height: 100%">
          <body style="margin: unset; height: 100%">${html}</body>
          <style>${css}</style>
          <script>${js}</script>
        </html>
      `;

      setSrcDoc(resultDoc);

      if (lang === "javascript" && customSetCode) {
        customSetCode(resultDoc);
      }
    }, 250);

    return () => clearTimeout(timeout);
  }, [html, css, js]);

  // for WEB only
  useEffect(() => {
    if (lang === "javascript") {
      dragElement(["separator1", "html", "css"]);
      dragElement(["separator2", "css", "js"]);
    }
  }, [lang]);

  useEffect(() => {
    if (language) {
      setLang(language);

      setEditorData(languageData[language] || languageData["javascript"]);
    }
  }, [language]);

  useEffect(() => {
    defaultValue && editorData.onChange(defaultValue);
  }, [defaultValue]);

  return (
    <Flex flexDirection="column" style={{ width: "100%" }}>
      <div>
        <Flex>
          {!language && (
            <Select
              label="Język"
              options={programmingLanguages}
              onChange={onLanguageChange}
              selectedOptions={[{ label: capitalize(lang), value: lang }]}
            />
          )}
          <Box ml={3}>
            <Select
              label="Theme"
              options={themeOptions}
              onChange={onThemeChange}
              selectedOptions={[{ label: capitalize(theme), value: theme }]}
            />
          </Box>
        </Flex>
        <Flex>
          {lang !== "javascript" ? (
            <CodePlace
              theme={theme}
              language={editorData.language}
              displayName={editorData.displayName}
              value={editorData.value}
              onChange={editorData.onChange}
            />
          ) : (
            <Flex width="100%" flexWrap="wrap">
              <CodeSection id={editorData.language}>
                <CodePlace
                  theme={theme}
                  language={editorData.language}
                  displayName={editorData.displayName}
                  value={editorData.value}
                  onChange={editorData.onChange}
                />
              </CodeSection>
              <Separator id="separator1" />
              <CodeSection id="css">
                <CodePlace
                  key="css"
                  theme={theme}
                  language="css"
                  displayName="CSS"
                  value={css}
                  onChange={setCss}
                />
              </CodeSection>
              <Separator id="separator2" />
              <CodeSection id="js">
                <CodePlace
                  key="javascript"
                  theme={theme}
                  language="javascript"
                  displayName="JS"
                  value={js}
                  onChange={setJs}
                />
              </CodeSection>
            </Flex>
          )}
        </Flex>
      </div>
      {lang === "javascript" ? (
        <iframe
          style={{ minHeight: "400px" }}
          srcDoc={srcDoc}
          title="output"
          sandbox="allow-scripts"
          frameBorder="0"
          width="100%"
          height="100%"
        />
      ) : (
        <h3>console</h3>
      )}
    </Flex>
  );
};
