import { H2 } from "components/Typography";
import { device } from "config/theme";
import styled from "styled-components";
import { Flex } from "components";

export const ResponsiveFlex = styled(Flex)`
  @media ${device.mobile} {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
`;

export const NoBreakSpan = styled.span`
  white-space: nowrap;
`;

export const StyledFlex = styled(Flex)`
  grid-gap: 30px;
  }
`;

export const StyledIcon = styled.div`
  ${({ theme }) => `
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: ${theme.palette.primary.main};
  `}
`;

export const StyledH3 = styled(H2)`
  font-size: 32px;
`;
