import { ResponsiveBox } from "views/Profile/ProfileView.styled";
import { Flex } from "../Flex";
import { Button, TextField } from "../_form";
import React from "react";
import styled from "styled-components";
import { Box } from "../Box";
import {
  ArrayPath,
  FieldArrayWithId,
  FieldValues,
  Path,
  UseFieldArrayRemove,
  UseFormRegister,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { P } from "components/Typography";

export const FieldArrayBox = styled(Box)`
  position: relative;

  hr {
    width: 80%;
    margin: 12px auto;
    border: 1px solid;
    border-radius: 21px;
    color: ${({ theme }) => theme.palette.neutral.medium};
  }
`;

const StyledX = styled.span`
  cursor: pointer;
  display: block;
  height: 18px;
  width: 18px;
  text-align: center;
  line-height: 16px;
  border-radius: 50px;
  background: ${({ theme }) => theme.palette.neutral.mainBlue};
  color: ${({ theme }) => theme.palette.neutral.white};
`;

const StyledLine = styled(Box)`
  width: 100%;
  height: 1px;
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.medium};
  `};
`;

export type ErrorsAddressFormPart = {
  [key: string]: {
    message: string;
  };
}[];

interface IAddressFormPart<T, P> {
  fields: FieldArrayWithId<
    T extends FieldValues ? any : any,
    P extends ArrayPath<T> ? never : never
  >[];
  register: UseFormRegister<T extends FieldValues ? any : any>;
  onAppend: () => any;
  remove: UseFieldArrayRemove;
  errors: ErrorsAddressFormPart;
}

export const AddressFormPart = <T, P extends string>({
  fields,
  register,
  onAppend,
  remove,
  errors,
}: IAddressFormPart<T, P>) => {
  const { t } = useTranslation();

  return (
    <>
      {fields.map((field, index) => (
        <FieldArrayBox my={2} key={field.id}>
          <StyledLine my={3} />
          <Flex m={1}>
            <StyledX onClick={() => remove(index)}>x</StyledX>
            <P variant="body" color="grey" ml={1}>
              {t("delete")}
            </P>

            <P variant="body" fontWeight={700} ml={2}>
              {t("address")}:
            </P>
          </Flex>
          <Flex flexWrap="wrap">
            <ResponsiveBox>
              <TextField
                label={t("country") as string}
                error={errors && errors[index]?.country?.message}
                {...register(`addresses.${index}.country` as Path<T>)}
              />
            </ResponsiveBox>
            <ResponsiveBox>
              <TextField
                label={t("city") as string}
                error={errors && errors[index]?.city?.message}
                {...register(`addresses.${index}.city` as Path<T>)}
              />
            </ResponsiveBox>
            <ResponsiveBox>
              <TextField
                label={t("postCode") as string}
                error={errors && errors[index]?.zip_code?.message}
                {...register(`addresses.${index}.zip_code` as Path<T>)}
              />
            </ResponsiveBox>
            <ResponsiveBox>
              <TextField
                label={t("addressArea") as string}
                error={errors && errors[index]?.details?.message}
                {...register(`addresses.${index}.details` as Path<T>)}
              />
            </ResponsiveBox>
            <ResponsiveBox>
              <TextField
                label={t("contactNumber") as string}
                error={errors && errors[index]?.contact_number?.message}
                {...register(`addresses.${index}.contact_number` as Path<T>)}
              />
            </ResponsiveBox>
            <ResponsiveBox>
              <TextField
                label={t("eMail") as string}
                error={errors && errors[index]?.contact_email?.message}
                {...register(`addresses.${index}.contact_email` as Path<T>)}
              />
            </ResponsiveBox>
          </Flex>
        </FieldArrayBox>
      ))}
      <Flex>
        <Button onClick={() => onAppend()}>{t("addAddress")}</Button>
      </Flex>
    </>
  );
};
