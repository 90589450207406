import { P } from "components/Typography";
import { Button } from "components/_form";
import { Flex, Avatar, Box } from "components";
import { ReactComponent as LocalisationIcon } from "assets/icons/localisation.svg";
import { IApplication } from "types/offer";
import { useNavigate } from "react-router-dom";
import { API_PHOTO_URL } from "constants/paths";
import { ToggleSwitch } from "components/_form/ToggleSwitch";
import { StyledH3, ResponsiveColouredBox } from "./ApplicationCard.styled";
import { useTranslation } from "react-i18next";

type variantType = "primary" | "secondary";

const backgroundColorVariantMap = (variant: variantType) => {
  return variant == "primary" ? "primary" : "grey";
};

const avatarColorVariantMap = (variant: variantType) => {
  return variant == "primary" ? "grey" : "primary";
};

export const ApplicationCard = ({
  application,
  variant = "primary",
  setIsModalOpen,
  setApplicationToHire,
}: {
  application: IApplication["data"];
  variant?: variantType;
  setIsModalOpen: any;
  setApplicationToHire: any;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { profile, certificate_count, cv } = application;

  return (
    <>
      <ResponsiveColouredBox
        minWidth={230}
        maxWidth={270}
        my={3}
        variant={backgroundColorVariantMap(variant)}
      >
        <Flex flexDirection="column" alignItems="center" p={2} width="100%">
          <Box my={3}>
            <Avatar
              size="large"
              color={avatarColorVariantMap(variant)}
              url={`${API_PHOTO_URL}${profile?.avatar}`}
            />
          </Box>
          <StyledH3 variant="body" fontWeight={600} my={2} color="coloured">
            {profile.first_name + " " + profile.last_name}
          </StyledH3>
          <P variant="body" fontWeight={600} my={2}>
            <LocalisationIcon /> {profile?.location}
          </P>
          <P variant="body" fontWeight={600} my={2} color="coloured">
            {t("certificates")}: {certificate_count}
          </P>
          {cv && (
            <a href={`${API_PHOTO_URL}${cv}`} target="_blank" rel="noreferrer">
              <P variant="body" fontWeight={600} my={2} color="coloured">
                CV
              </P>
            </a>
          )}
          <Button
            variant="dark"
            m={2}
            onClick={() =>
              navigate(`/app/candidate-user-details/${profile.id}`)
            }
          >
            {t("viewProfile")}
          </Button>
          <Flex>
            <P variant="body" mr={1}>
              {t("beenHired")}
            </P>
            <ToggleSwitch
              key={application.id}
              id={`hired-switch-${application?.id}`}
              onChange={() => {
                setApplicationToHire({
                  hired: application.hired,
                  id: application.id,
                });
                setIsModalOpen(true);
              }}
              checked={application.hired}
              optionLabels={[t("yes"), t("no")]}
            />
          </Flex>
        </Flex>
      </ResponsiveColouredBox>
    </>
  );
};
