import styled from "styled-components";
import { Flex } from "components";
import euLogotypes from "assets/img/eu-logotypes.png";
import { LinkItem } from "components/LinkItem";

export const StyledLinkItem = styled(LinkItem)`
  margin-right: 24px;
`;

export const EuLogo = styled.div`
  background: url(${euLogotypes});
  min-height: 30px;
  min-width: 290px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

export const Wrapper = styled(Flex)`
  width: 172px;
  a {
    text-decoration: none;
  }
  flex-direction: column;

  svg {
    height: 30px;
    width: 30px;
  }
`;

export const VerticalLine = styled.div`
  height: 30px;
  min-width: 1px;
  margin-right: 24px;
  ${({ theme }) => `background-color: ${theme.palette.neutral.white}`}
`;

export const IconsWrapper = styled(Flex)`
  > a:first-child {
    margin-right: 18px;
  }
`;
