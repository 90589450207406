import { P } from "components/Typography";
import { IOffer } from "types/offer";
import { ColouredBox, Flex, Avatar } from "components";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { ReactComponent as LocalisationIcon } from "assets/icons/localisation.svg";
import { ReactComponent as SalaryIcon } from "assets/icons/salary.svg";
import { ReactComponent as WorkingTimesIcon } from "assets/icons/working-time.svg";
import { ReactComponent as ContractTypeIcon } from "assets/icons/contract-type.svg";
import { theme } from "config/theme";
import { separator } from "utilities/functions";
import { stringToDate } from "utilities/convertDate";
import { API_PHOTO_URL } from "constants/paths";
import { isOneDayOld } from "utilities/functions";
import {
  ResponsiveFlex,
  NoBreakSpan,
  StyledFlex,
  StyledIcon,
  StyledH3,
} from "./MainInfo.styled";
import { useTranslation } from "react-i18next";

export const MainInfo = ({ offer }: { offer: IOffer["data"] }) => {
  const { t } = useTranslation();
  const isOfferFinished = () => {
    if (!offer.finish_at) return false;
    return isOneDayOld(stringToDate(offer.finish_at));
  };
  return (
    <ColouredBox p={4} mb={3}>
      <ResponsiveFlex mb={3} alignItems="center">
        <Avatar
          size="large"
          color="grey"
          url={`${API_PHOTO_URL}${offer?.profile?.avatar}`}
        />
        <Flex flexDirection="column" ml={3}>
          <StyledH3 variant="h2" fontWeight={600} m={1} color="coloured">
            {offer?.title}
          </StyledH3>
          <P variant="body" m={1}>
            {offer?.profile?.company_name}
          </P>
          <Flex alignItems="center" m={1}>
            <LocalisationIcon fill={theme.palette.primary.main} />
            <P variant="body" fontWeight={600} ml={1}>
              {t("localisation")}:{" "}
              {offer?.locations?.map(
                (location, index, array) =>
                  `${location.country}, ${location.city}${separator(
                    index,
                    array
                  )}`
              )}
            </P>
          </Flex>
        </Flex>
      </ResponsiveFlex>

      <hr />

      <Flex my={3} alignItems="center">
        <CalendarIcon fill={theme.palette.primary.main} />
        <P variant="body" fontWeight={600} mx={2} color="coloured">
          {t("duration")}:
        </P>
        <P variant="body">
          {offer.continuous_recruitment ? (
            t("continuousRecruitment")
          ) : (
            <>
              <NoBreakSpan>{offer?.start_at}</NoBreakSpan> -{" "}
              <NoBreakSpan>{offer?.finish_at}</NoBreakSpan>
            </>
          )}
        </P>
        <P variant="body" fontWeight={600} ml={2}>
          {isOfferFinished() && t("finished")}
        </P>
      </Flex>

      <hr />

      <StyledFlex mt={3} py={3} flexWrap="wrap">
        <Flex alignItems="center">
          <StyledIcon>
            <SalaryIcon />
          </StyledIcon>
          <Flex flexDirection="column" ml={2}>
            <P variant="body" fontWeight={600} color="coloured">
              {t("grossSalary")}:
            </P>
            <P variant="body">
              {offer?.min_range_salary} - {offer?.max_range_salary} PLN
            </P>
          </Flex>
        </Flex>

        <Flex alignItems="center">
          <StyledIcon>
            <WorkingTimesIcon />
          </StyledIcon>
          <Flex flexDirection="column" ml={2}>
            <P variant="body" fontWeight={600} color="coloured">
              {t("workingTime")}:
            </P>
            <P variant="body">
              {offer?.working_times &&
                offer?.working_times.map(
                  ({ name }, index, array) =>
                    `${name}${separator(index, array)}`
                )}
            </P>
          </Flex>
        </Flex>

        <Flex alignItems="center">
          <StyledIcon>
            <ContractTypeIcon />
          </StyledIcon>
          <Flex flexDirection="column" ml={2}>
            <P variant="body" fontWeight={600} color="coloured">
              {t("contractType")}:
            </P>
            <P variant="body">
              {offer?.contract_types &&
                offer?.contract_types.map(
                  ({ name }, index, array) =>
                    `${name}${separator(index, array)}`
                )}
            </P>
          </Flex>
        </Flex>
      </StyledFlex>
    </ColouredBox>
  );
};
