import { Flex, Typography } from "components";
import { Button } from "components/_form";
import BgPattern from "assets/img/BgPattern.jpeg";
import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import euLogotypes from "assets/img/eu-logotypes.png";
import { useAuthStateContext } from "contexts/AuthContext";
import { device } from "config/theme";
import { useTranslation } from "react-i18next";

export const Logo = styled.div`
  background: url(${euLogotypes});
  height: 57px;
  width: 504px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  @media ${device.tablet} {
    height: 30px;
    width: 300px;
  }
`;

const Wrapper = styled(Flex)`
  padding: 10% 10% 10% 10%;
  position: relative;
  z-index: 1;
  background: linear-gradient(0deg, #0460a5, #0460a5);

  ::after {
    content: "";
    background: url(${BgPattern});
    background-position: 70%;
    opacity: 5%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
`;

export const HomeView = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { userProfile } = useAuthStateContext();

  if (searchParams.get("registerSuccess")) {
    searchParams.delete("registerSuccess");
    setSearchParams(searchParams);
    toast.success(t("checkEmail"));
    window.history.replaceState(null, "", window.location.pathname);
  }

  const handleCheckAll = () => {
    if (!userProfile?.id) {
      navigate("/signin");
    } else if (userProfile?.role === "candidate") {
      navigate("/app/active-offers");
    } else if (userProfile?.role === "company") {
      navigate("/app/applications");
    }
  };

  return (
    <Wrapper height="100%" flexDirection="column" justifyContent="end">
      <Typography.H1 variant={["h2", "h1"]} color="white">
        {t("mainPageTitle")}
      </Typography.H1>
      <Typography.H3 my={30} variant="h3" color="white">
        {t("companySlogan")}
      </Typography.H3>
      <Button
        variant="primary"
        bordered
        unsetBG
        height="50px"
        width="270px"
        onClick={handleCheckAll}
      >
        {t("checkNow")}
      </Button>
      <Flex mt={2} />
      <Flex mt="auto" flexDirection="column" alignItems="center">
        <Logo />
        <Flex flexDirection="row" alignItems="center" mt={10}>
          <Typography.P
            color="white"
            variant="body"
            style={{ fontSize: "12px" }}
          >
            Sfinansowano w ramach reakcji Unii na pandemię COVID 19&quot;,
            realizujemy projekt ze wsparciem z REACT-EU W RAMACH DZIAŁANIA 6.2
            WSPARCIE MŚP W OBSZARZE CYFRYZACJI - BONY NA CYFRYZACJĘ W RAMACH
            PROGRAMU OPERACYJNEGO INTELIGENTNY ROZWÓJ 2014-2020
          </Typography.P>
        </Flex>
      </Flex>
    </Wrapper>
  );
};
