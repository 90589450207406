export const http = async <T = null>(
  url: string,
  options?: RequestInit
): Promise<T> => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("lang");

  if (token && options) {
    options.headers = {
      ...options?.headers,
      Authorization: `${token}`,
      HTTP_ACCEPT_LANGUAGE: lang || "pl",
      "Accept-Language": lang || "pl",
    };
  }

  const response = await fetch(url, options);

  if (response.status === 401) {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("token");
    window.location.href = "/signin";
  }

  if (!response.ok) {
    const error = await response.json();
    throw error;
  }

  if (response.status === 204 || response.statusText === "No Content") {
    return new Promise((resolve) => {
      resolve(null as any);
    });
  }

  return response.json();
};
