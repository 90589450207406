import React, { useContext, useState } from "react";

export interface IFilters {
  min_range_salary?: number;
  finish_at?: Date;
  text?: string;
  country_ids?: string[];
  language_ids?: string[];
  certificate_name_ids?: string[];
}

export interface IFiltersStateContext {
  filters: IFilters | null;
}

export interface IFiltersDispatchContext {
  setFilters: (filters: IFilters) => void;
  cleanFilters: () => void;
}

const FiltersStateContext = React.createContext<
  IFiltersStateContext | undefined
>(undefined);

const FiltersDispatchContext = React.createContext<
  IFiltersDispatchContext | undefined
>(undefined);

export const FiltersContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [filters, setFilters] = useState<IFilters | null>(null);

  const cleanFilters = () => {
    setFilters(null);
  };

  return (
    <FiltersStateContext.Provider value={{ filters }}>
      <FiltersDispatchContext.Provider
        value={{
          setFilters,
          cleanFilters,
        }}
      >
        {children}
      </FiltersDispatchContext.Provider>
    </FiltersStateContext.Provider>
  );
};

export const useFiltersStateContext = () => {
  const context = useContext(FiltersStateContext);
  if (!context) {
    throw new Error(
      "useFiltersStateContext must be inside a FiltersStateContextProvider with a value"
    );
  }
  return context;
};

export const useFiltersDispatchContext = () => {
  const context = useContext(FiltersDispatchContext);
  if (!context) {
    throw new Error(
      "useFiltersDispatchContext must be inside a FiltersStateDispatchProvider with a value"
    );
  }
  return context;
};
