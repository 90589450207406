import * as yup from "yup";

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .required("emailRequired")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "badEmailFormat"
    ),
  password: yup
    .string()
    .min(6, "passMinVal")
    .max(18, "passMaxVal")
    .required("passwordRequired"),
});

export const registerSchema = (role: string) => {
  const validation = {
    email: yup
      .string()
      .required("emailRequired")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "badEmailFormat"
      ),
    password: yup
      .string()
      .min(6, "passMinVal")
      .max(18, "passMaxVal")
      .required("passwordRequired"),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password")], "passDiffErr"),
  };
  const candidate = {
    first_name: yup.string().required("firstNameRequired"),
    last_name: yup.string().required("lastNameRequired"),
  };
  const company = {
    company_name: yup.string().required("nameRequired"),
  };

  return yup
    .object()
    .shape(
      role === "candidate"
        ? { ...validation, ...candidate }
        : { ...validation, ...company }
    );
};

export const changePasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(6, "passMinVal")
    .max(18, "passMaxVal")
    .required("passwordRequired"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password")], "passDiffErr"),
});

export const resetPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .required("emailRequired")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "badEmailFormat"
    ),
});
