import { Flex, Typography, Box } from "components";
import { ReactComponent as EmailIcon } from "assets/icons/user.svg";
import { useForm } from "react-hook-form";
import { resetUserPassword } from "services/auth";
import { Button, TextField } from "components/_form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router";
import { useState } from "react";
import { Spinner } from "components/Spinner";
import { ErrorText } from "components/_form/ErrorText";
import { resetPasswordSchema } from "schemas/auth";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const ResetPasswordView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm<{ email: string }>({
    mode: "onChange",
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(resetPasswordSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    setError("");
    setIsLoading(true);

    try {
      const response = await resetUserPassword(data);
      if (!response) return;
      toast.success(t("checkEmail"));
      navigate("/");
    } catch (err: any) {
      toast.error(t("error"));
      setError(err.error);
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <Flex height="100%" flexDirection="column" justifyContent="center">
      <Typography.H2
        variant="h2"
        color="primary"
        textAlign="center"
        fontWeight={600}
        mb={4}
      >
        {t("resetPassword")}
      </Typography.H2>

      <form onSubmit={onSubmit}>
        <TextField
          label={t("emailForLink") as string}
          transparent
          icon={<EmailIcon />}
          {...register("email")}
          error={errors.email?.message}
        />

        {error && <ErrorText>{error}</ErrorText>}

        <Box my={4}>
          {isLoading ? (
            <Spinner />
          ) : (
            <Button
              variant="primary"
              fullWidth
              type="submit"
              disabled={!formState.isValid}
            >
              {t("send")}
            </Button>
          )}
        </Box>
      </form>
    </Flex>
  );
};
