import React, { useContext, useState } from "react";
import { IUser } from "types/user";

export interface IAuthContext {
  token: string | null;
  userProfile: IUser | null;
}

export interface IAuthDispatchContext {
  setToken: (token: string | null) => void;
  setUserProfile: (data: IUser | null) => void;
}

export const AuthContext = React.createContext<IAuthContext | undefined>(
  undefined
);

export const AuthDispatchContext = React.createContext<
  IAuthDispatchContext | undefined
>(undefined);

export const AuthContextProvider: React.FC<{
  children: React.ReactNode;
  initialUserProfile?: IUser;
}> = ({ children, initialUserProfile }) => {
  const [token, setToken] = useState<string | null>(
    localStorage.getItem("token") ?? null
  );
  const [userProfile, setUserProfile] = useState<IUser | null>(
    initialUserProfile ??
      JSON.parse(`${localStorage.getItem("currentUser")}`) ??
      null
  );

  return (
    <AuthContext.Provider value={{ token, userProfile }}>
      <AuthDispatchContext.Provider
        value={{
          setToken: (newToken: string | null) => {
            if (newToken === null) {
              localStorage.removeItem("token");
            } else {
              localStorage.setItem("token", newToken);
            }
            setToken(newToken);
          },
          setUserProfile: (newUser: IUser | null) => {
            if (newUser === null) {
              localStorage.removeItem("currentUser");
            } else {
              localStorage.setItem("currentUser", JSON.stringify(newUser));
            }
            setUserProfile(newUser || null);
          },
        }}
      >
        {children}
      </AuthDispatchContext.Provider>
    </AuthContext.Provider>
  );
};

export const useAuthStateContext = (): IAuthContext => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error(
      "useAuthContext must be inside a AuthContextProvider with a value"
    );
  }
  return context;
};

export const useAuthDispatchContext = (): IAuthDispatchContext => {
  const context = useContext(AuthDispatchContext);
  if (!context) {
    throw new Error(
      "useAuthDispatchContext must be inside a AuthStateDispatchProvider with a value"
    );
  }
  return context;
};
