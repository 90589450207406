import styled from "styled-components";
import { Card, Box, Flex } from "components";
import { ICertificate } from "types/user";
import { FC } from "react";
import { P } from "components/Typography";
import { device } from "config/theme";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/bx-x.svg";
import { ReactComponent as EditIcon } from "assets/icons/ICO-edit.svg";
import { Button } from "components/_form";
import { useNavigate } from "react-router";
import { useAuthStateContext } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";

interface ICertificateCard {
  certificate: ICertificate;
  setIsModalOpen: any;
  setDeleteCertificateId: any;
}

export const Wrapper = styled(Card)`
  padding: unset;
  margin: 10px;

  @media ${device.laptop} {
    width: 48%;
  }
  @media ${device.tablet} {
    width: 90%;
    margin: 0 auto 30px;
  }
`;

const StatusButton = styled(Button)`
  cursor: default;
`;

const EditButton = styled(Box)`
  cursor: pointer;
  filter: invert(1);
`;

const DeleteButton = styled(Box)`
  cursor: pointer;
`;

export const CertificateCard: FC<ICertificateCard> = ({
  certificate,
  setIsModalOpen,
  setDeleteCertificateId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userProfile } = useAuthStateContext();
  const role = userProfile?.role;
  const onDelete = () => {
    setDeleteCertificateId(certificate.id);
    setIsModalOpen(true);
  };
  const onEdit = () => navigate(`${certificate.id}/settings`);

  return (
    <Flex width="100%">
      <Wrapper>
        <Flex p={2} justifyContent="space-between">
          <Flex flexDirection="column" justifyContent="center" ml={2}>
            <P my={1} variant="body">
              {t("certificateName")}: {certificate.certificate_name?.name}
            </P>
            <Flex my={1}>
              <P variant="body">
                {t("expirationDate")}: {certificate.expiration_date}
              </P>
              <Box ml={2}>
                <CalendarIcon />
              </Box>
            </Flex>
          </Flex>
          <Flex justifyContent="space-between" alignItems="center">
            {role === "company" && (
              <StatusButton variant="primary" mx={3}>
                {certificate.verification_state}
              </StatusButton>
            )}
            {role === "candidate" && (
              <EditButton mx={3}>
                <EditIcon onClick={onEdit} />
              </EditButton>
            )}
            <DeleteButton ml={2} mr={3}>
              <DeleteIcon onClick={onDelete} />
            </DeleteButton>
          </Flex>
        </Flex>
      </Wrapper>
    </Flex>
  );
};
