import { http } from "utilities/http";
import {
  CONFIRMATION_URL,
  LOGIN_URL,
  LOGOUT_URL,
  PASSWORD_RESET,
  REGISTER_URL,
} from "constants/paths";
import {
  IChangePassword,
  ILogin,
  IRegister,
  IResetPassword,
} from "types/forms/auth";
import { IUser, IUserWithToken } from "types/user";

export const loginUser = async (
  user: ILogin
): Promise<{
  token: string;
  data: IUser;
}> => {
  const response = await fetch(LOGIN_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ user }),
  });

  const token = response.headers.get("authorization");
  const data = await response.json();
  if (!token || !data) throw new Error(data?.error || "");

  return { token, data: data.data };
};

export const registerUser = async (user: IRegister["user"]) =>
  await http<IUserWithToken>(REGISTER_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ user }),
  });

export const confirmUser = async (token: string) =>
  await http(CONFIRMATION_URL + "?confirmation_token=" + token, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

export const resetUserPassword = async (user: IResetPassword) =>
  await http(PASSWORD_RESET, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ user }),
  });

export const changeUserPassword = async (user: IChangePassword) =>
  await http(PASSWORD_RESET, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ user }),
  });

export const logoutUser = () =>
  http(LOGOUT_URL, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
