import {
  IOfferFormData,
  ISelect,
  ILocationAttribute,
  ICreateOffer,
} from "types/forms/offer";
import { IOffer, ILocation } from "types/offer";
import { stringToDate, dateToString } from "utilities/convertDate";

function mapToDictionary(entities: { id: number; name: string }[]): ISelect[] {
  return entities.map(({ id, name }) => {
    return {
      value: id.toString(),
      label: name,
    };
  });
}

function mapLocationsToDictionary(entities: ILocation[]): ILocationAttribute[] {
  return entities.map(({ country, country_id, city, id }) => {
    return {
      country_id: {
        value: country_id.toString(),
        label: country,
      },
      city,
      id,
    };
  });
}

interface ILocationDelete {
  id: number;
  _destroy: 1;
}

function prepareLocationPayload(
  locations_attributes: ILocationAttribute[],
  locationsIds: number[]
): {
  city?: string;
  country_id?: number;
  id?: number;
  _destroy?: 1;
}[] {
  const createAndUpdateLocations = locations_attributes.map((entity) => {
    return {
      country_id: parseInt(entity.country_id.value),
      city: entity.city,
      ...(entity.id && { id: entity.id }),
    };
  });
  const deleteLocations = locationsIds
    .map((id) => {
      if (!locations_attributes.find((entity) => entity.id == id))
        return {
          id,
          _destroy: 1,
        };
    })
    .filter((entity): entity is ILocationDelete => !!entity);
  return [...createAndUpdateLocations, ...deleteLocations];
}

export const preparePayloadData = (
  offer: IOfferFormData,
  profile_id: number,
  locationsIds: number[]
): ICreateOffer => {
  const {
    language_ids,
    certificate_name_ids,
    working_time_ids,
    contract_type_ids,
    locations_attributes,
    start_at,
    finish_at,
    ...rest
  } = offer;
  return {
    offer: {
      ...rest,
      start_at: dateToString(start_at),
      finish_at: dateToString(finish_at),
      ...(working_time_ids[0]?.value
        ? {
            working_time_ids: working_time_ids.map(({ value }) =>
              parseInt(value)
            ),
          }
        : []),
      ...(contract_type_ids[0]?.value
        ? {
            contract_type_ids: contract_type_ids.map(({ value }) =>
              parseInt(value)
            ),
          }
        : []),
      ...(language_ids[0]?.value
        ? { language_ids: language_ids.map(({ value }) => parseInt(value)) }
        : []),
      ...(certificate_name_ids[0]?.value
        ? {
            certificate_name_ids: certificate_name_ids.map(({ value }) =>
              parseInt(value)
            ),
          }
        : []),
      profile_id,
      ...(locations_attributes[0]?.country_id?.value
        ? {
            locations_attributes: prepareLocationPayload(
              locations_attributes,
              locationsIds
            ),
          }
        : []),
    },
  };
};

export const prepareOfferFormData = (offer: IOffer): IOfferFormData => {
  const { data } = offer;
  const {
    start_at,
    finish_at,
    working_times,
    contract_types,
    languages,
    certificate_names,
    locations,
    profile,
  } = data;
  return {
    ...data,
    start_at: start_at ? stringToDate(start_at) : new Date(),
    finish_at: finish_at ? stringToDate(finish_at) : new Date(),
    profile_id: profile.id,
    working_time_ids: mapToDictionary(working_times),
    contract_type_ids: mapToDictionary(contract_types),
    language_ids: mapToDictionary(languages),
    certificate_name_ids: mapToDictionary(certificate_names),
    locations_attributes: mapLocationsToDictionary(locations),
  };
};
