import { Flex, Link, Box } from "components";
import { ReactComponent as InstagramIcon } from "assets/icons/instagram.svg";
import { ReactComponent as FacebookIcon } from "assets/icons/ICO-FB.svg";
import { useLocation } from "react-router-dom";
import {
  EuLogo,
  Wrapper,
  VerticalLine,
  IconsWrapper,
  StyledLinkItem,
} from "./Footer.styled";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isLoginOrRegitrationScreen =
    location.pathname === "/signup" || location.pathname === "/signin";
  return (
    <Wrapper>
      <IconsWrapper mb={3}>
        <a
          href="https://www.facebook.com/checkcert"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookIcon />
        </a>
        <a
          href="https://www.instagram.com/checkcert/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramIcon />
        </a>
      </IconsWrapper>
      <Flex alignItems="center" flexWrap={["wrap", "nowrap"]}>
        <Link to="/info/regulamin">
          <StyledLinkItem variant="body" fontWeight={400} color="white">
            {t("regulamin")}
          </StyledLinkItem>
        </Link>
        <Link to="/info/polityka-prywatnosci">
          <StyledLinkItem variant="body" fontWeight={400} color="white">
            {t("privacyPolicy")}
          </StyledLinkItem>
        </Link>
        <Link to="/info/contact">
          <StyledLinkItem variant="body" fontWeight={400} color="white">
            {t("contact")}
          </StyledLinkItem>
        </Link>
        <Link to="/info/faq">
          <StyledLinkItem variant="body" fontWeight={400} color="white">
            FAQ
          </StyledLinkItem>
        </Link>
        <VerticalLine />
        <StyledLinkItem isItalic variant="body" fontWeight={400} color="white">
          Copyrights © 2022. All rights reserved.
        </StyledLinkItem>
        {isLoginOrRegitrationScreen && (
          <Box>
            <EuLogo />
          </Box>
        )}
      </Flex>
    </Wrapper>
  );
};
