import CookieConsent from "react-cookie-consent";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledA = styled.a`
  color: white;
`;

export const CookieInfo = () => {
  const { t } = useTranslation();
  return (
    <CookieConsent
      location="bottom"
      buttonText={t("acceptCookies")}
      cookieName="myAwesomeCookieName2"
      style={{ background: "#2B373B", zIndex: 10000 }}
      buttonStyle={{
        color: "black",
        backgroundColor: "lightBlue",
        fontSize: "13px",
      }}
      expires={150}
    >
      {t("cookieInfoPartOne")}{" "}
      <StyledA href="/info/polityka-prywatnosci">
        {t("cookieInfoPartTwo")}
      </StyledA>
    </CookieConsent>
  );
};
