import { useState } from "react";
import { Box, Flex } from "components";
import { Button } from "components/_form";
import { Modal } from "components/Modal";
import { P } from "components/Typography";
import { deleteOffer } from "services/offer";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

export const DeleteButton = ({
  offer_title,
  offer_id,
}: {
  offer_title: string;
  offer_id: number;
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleDeleteOffer = async () => {
    try {
      await deleteOffer(offer_id);
      toast.success(`${t("offerDeletionSuccesfull")} ${offer_title}`);
    } catch (err: any) {
      setError(err.error);
    }
  };

  const onDeleteConfirmation = async () => {
    await handleDeleteOffer();
    setIsModalOpen(false);
    navigate(`/app/my-offers`);
  };

  return (
    <>
      <Button onClick={() => setIsModalOpen(true)}>{t("deleteOffer")}</Button>
      <Modal isOpen={isModalOpen} onCancelClick={() => setIsModalOpen(false)}>
        <Box>
          <P variant="h3">
            {t("areYouSureToDeleteOffer")} {offer_title}?
          </P>
          <Flex justifyContent="end" mt={3}>
            <Box mr={2}>
              <Button
                onClick={() => {
                  setIsModalOpen(false);
                }}
              >
                {t("no")}
              </Button>
            </Box>
            <Button onClick={onDeleteConfirmation}>{t("yes")}</Button>
          </Flex>
        </Box>
      </Modal>
    </>
  );
};
