import { H3, P } from "components/Typography";
import { IOffer } from "types/offer";
import { ColouredBox } from "components";
import { useTranslation } from "react-i18next";
import { separator } from "utilities/functions";


export const OfferDetails = ({ offer }: { offer: IOffer["data"] }) => {
  const { t } = useTranslation();
  return (
    <>
      <ColouredBox my={3} p={4}>
        <H3 variant="h3" fontWeight={600} color="coloured">
          {t("companyDescription")}:
        </H3>
        <P variant="body" mt={3}>
          {offer?.profile?.description || "-"}
        </P>
      </ColouredBox>

      <ColouredBox my={3} p={4}>
        <H3 variant="h3" fontWeight={600} color="coloured">
          {t("requirements")}:
        </H3>
        <P variant="body" mt={3}>
          Języki:{" "}
          {offer?.languages?.length
            ? offer?.languages?.map(
                (language, index, array) =>
                  `${language.name}${separator(index, array)}`
              )
            : "brak"}
        </P>
        <P variant="body" mt={3}>
          Certyfikaty:{" "}
          {offer?.certificate_names?.length
            ? offer?.certificate_names?.map(
                (certificate_name, index, array) =>
                  `${certificate_name.name}${separator(index, array)}`
              )
            : "brak"}
        </P>
        <P variant="body" mt={3}>
          {offer?.requirements || "-"}
        </P>
      </ColouredBox>

      <ColouredBox my={3} p={4}>
        <H3 variant="h3" fontWeight={600} color="coloured">
          {t("responsibilities")}:
        </H3>
        <P variant="body" mt={3}>
          {offer?.responsibilities || "-"}
        </P>
      </ColouredBox>

      <ColouredBox my={3} p={4}>
        <H3 variant="h3" fontWeight={600} color="coloured">
          {t("benefits")}:
        </H3>
        <P variant="body" mt={3}>
          {offer?.benefits || "-"}
        </P>
      </ColouredBox>
    </>
  );
};
