// convert "dd.mm.yyyy hh:mm:ss" to "mm.dd.yyyy hh:mm:ss"

export const convertDate = (date: string) => {
  const splitedDate = date.split(" ");
  const mainDateArray = [...splitedDate[0].split(".")];

  return `${mainDateArray[1]}.${mainDateArray[0]}.${mainDateArray[2]} ${splitedDate[1]}`;
};

//converts Date to YYYY-MM-DD string
export function dateToString(date: Date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

//converts YYYY-MM-DD string to Date
export function stringToDate(dateString: string): Date {
  var parts = dateString.split("-"),
    year = +parts[0],
    month = +parts[1],
    day = +parts[2].slice(0, 2);
  return new Date(year, --month, day);
}
