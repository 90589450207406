import { Flex, Box } from "components";
import styled from "styled-components";
import { ReactComponent as MenuIcon } from "assets/icons/menu.svg";
import { device } from "config/theme";
import logo from "assets/img/logo.png";

export const Logo = styled.div`
  cursor: pointer;
  background: url(${logo});
  min-height: 26px;
  min-width: 180px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`;

export const Wrapper = styled(Flex)`
  grid-area: navbar;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  z-index: 5;

  ${({ theme }) => `
    background-color: ${theme.palette.neutral.white};
    max-width: 100vw;
    padding: 14px;
  `}
`;
export const MenuToggleIcon = styled(MenuIcon)`
  display: none;
  margin-right: auto;
  padding: 10px;
  height: 50px;
  width: 50px;
  cursor: pointer;

  @media ${device.tablet} {
    display: block;
    padding: 0px;
  }
`;

export const NoBreakSpan = styled.span`
  white-space: nowrap;
`;

export const Breadcrumbs = styled.ul`
  margin: 0;
  align-items: center;
  height: 100%;
  list-style: none;
  color: rgba(19, 19, 21, 0.65);
  font-size: 13px;
  letter-spacing: 0.4px;
  line-height: 18px;
  display: flex;
`;

export const TabletBox = styled(Box)`
  display: flex;
  > :nth-child(n) {
    margin-left: 50px;
    margin-right: 30px;
  }

  @media ${device.tablet} {
    display: none;
  }
`;

export const TabletFlex = styled(Flex)`
  display: block;

  @media ${device.tablet} {
    display: none;
  }
`;

export const LangWrapper = styled(Flex)`
  border-left: 1px solid grey;
  margin-left: 15px;
`;

export const ZoomSize = styled(Flex)`
  margin-left: 16px;
  height: 30px;
  border: 1px solid black;
  border-radius: 50px;
  min-width: 30px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
