import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

import { ReactComponent as PasswordIcon } from "assets/icons/lock-password-fill.svg";
import { useForm } from "react-hook-form";
import { changePasswordSchema } from "schemas/auth";
import { ErrorText } from "components/_form/ErrorText";
import { Box, Flex, Typography, Spinner } from "components";
import { Button, TextField } from "components/_form";
import { useNavigate } from "react-router";
import { changeUserPassword } from "services/auth";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IRegisterFormData {
  password: string;
  password_confirmation: string;
  reset_password_token: string;
}

export const ChangePasswordView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const token = searchParams.get("token");

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm<IRegisterFormData>({
    mode: "onChange",
    resolver: yupResolver(changePasswordSchema),
  });

  const onSubmit = async (data: IRegisterFormData) => {
    if (!token) return;

    setError("");
    setIsLoading(true);

    const dataToFetch = {
      ...data,
      reset_password_token: token,
    };

    try {
      await changeUserPassword(dataToFetch);
      setIsLoading(false);
      toast.success(t("passwordChangedSuccess"));
      navigate("/signin");
    } catch (err: any) {
      toast.error(t("error"));
      setError(err.error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    !token && navigate("/");
  }, [token]);

  return (
    <Flex height="100%" flexDirection="column" justifyContent="center">
      <Typography.H2
        variant="h2"
        color="primary"
        textAlign="center"
        fontWeight={600}
        mb={4}
      >
        {t("changePassword")}
      </Typography.H2>

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          type="password"
          label={t("password") as string}
          transparent
          icon={<PasswordIcon />}
          {...register("password")}
          error={errors.password?.message}
        />
        <Box mt={4} mb={2}>
          <TextField
            type="password"
            label={t("repeatPassword") as string}
            transparent
            icon={<PasswordIcon />}
            {...register("password_confirmation")}
            error={errors.password_confirmation?.message}
          />
        </Box>

        {error && <ErrorText>{error}</ErrorText>}

        <Box my={4}>
          {isLoading ? (
            <Spinner />
          ) : (
            <Button
              variant="primary"
              fullWidth
              type="submit"
              disabled={!formState.isValid}
            >
              {t("reset")}
            </Button>
          )}
        </Box>
      </form>
    </Flex>
  );
};
