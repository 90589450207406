import styled from "styled-components";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { rgba } from "utilities/rgba";
import { ErrorText } from "../_form/ErrorText";

interface CustomDatePickerProps extends Omit<ReactDatePickerProps, "value"> {
  value: Date;
  onChange: (...event: any[]) => void;
  error?: string;
  id?: string;
}

export const StyledDatePicker = styled(DatePicker)`
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.white};
    color: ${theme.palette.text.primary};
    border: 1px solid ${theme.palette.neutral.medium};

    &:focus {
      border-radius: 4px;
      border-color: ${theme.palette.primary.main};
    }

    &:disabled {
      color ${rgba(theme.palette.text.primary, 0.4)};
      background-color: ${theme.palette.neutral.veryLight};
    }
  `}

  border-radius: 30px;
  outline: none;
  display: block;
  width: 100%;
  height: 50px;
  padding: 0 12px;
  font-size: 13px;
  text-align: center;
`;

export const CustomDatePicker = ({
  value,
  onChange,
  error,
  id,
  ...props
}: CustomDatePickerProps) => (
  <>
    <StyledDatePicker
      selected={value ? new Date(value) : undefined}
      onChange={(date) => {
        onChange(date instanceof Date ? date : null);
      }}
      dateFormat="dd-MM-yyyy"
      id={id}
      {...props}
    />
    {error && <ErrorText>{error}</ErrorText>}
  </>
);
