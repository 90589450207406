import React, { FC, useEffect, useState } from "react";
import { getOffers, getApplications } from "services/offer";
import { IOffers, IOffer, IApplications } from "types/offer";
import { Flex, Typography } from "components";
import { OfferCard } from "components/_offer";
import { useAuthStateContext } from "contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Filters } from "components/Filters";
import { ReactComponent as ScrollIcon } from "assets/icons/scroll.svg";
import { P } from "components/Typography";
import { Button } from "components/_form";
import { useFiltersStateContext } from "contexts/FiltersContext";
import { useTranslation } from "react-i18next";

const ONE_DAY = 24 * 60 * 60 * 1000;

interface IOffersView {
  filter?: "my-offers" | "public-offers";
}

function isUserApplicated(
  offer: IOffer["data"],
  applications?: IApplications["data"]
): boolean {
  if (applications) {
    return !!applications.find(
      (application) => application.offer.id == offer.id
    );
  }
  return false;
}

export const OffersView: FC<IOffersView> = ({ filter }) => {
  const { t } = useTranslation();
  const [offers, setOffers] = useState<IOffers | null>();
  const [page, setPage] = useState(1);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [applications, setApplications] = useState<IApplications["data"]>();
  const { userProfile } = useAuthStateContext();
  const navigate = useNavigate();

  const { filters } = useFiltersStateContext();

  const handleGetOffers = async (customPage?: number) => {
    const queryFilters = {
      ...(filter === "my-offers"
        ? { profile_id: userProfile?.profile?.id }
        : {}),
      per_page: 20,
      page: customPage || page,
      ...filters,
    };

    getOffers(queryFilters).then((res) => {
      setShowMore(!!res.pagination?.next);
      setOffers((prevOffers) => {
        const offersCombined = {
          ...res,
          data: [...(prevOffers?.data || []), ...res.data],
        };
        offersCombined.data = offersCombined.data.sort((a, b) => {
          return b.finish_at
            ? new Date(b.finish_at).getTime() - new Date().getTime() + ONE_DAY
            : 1;
        });
        return offersCombined;
      });
    });
  };

  const handleLoadMoreOffers = async () => {
    setPage((prevState) => prevState + 1);
    handleGetOffers(page + 1);
  };

  const fetchApplications = async (id: string) => {
    const response = await getApplications({ profile_id: id });
    setApplications(response.data);
  };

  useEffect(() => {
    setIsLoading(true);
    if (filters !== null) {
      setOffers(null);
      setPage(1);

      handleGetOffers(1).then(() => {
        if (userProfile && !applications)
          fetchApplications(userProfile.profile.id.toString()).then(() => {
            setIsLoading(false);
          });
        setIsLoading(false);
      });
    }
  }, [JSON.stringify(filters)]);

  return (
    <Flex justifyContent="space-between" flexWrap="wrap">
      <Filters />
      {offers && offers?.data?.length ? (
        offers.data.map((offer) => (
          <OfferCard
            key={offer.id}
            offer={offer}
            button={{
              text: t("details"),
              onClick: () => navigate(`/app/active-offers/${offer.id}`),
            }}
            isDisabled={
              isUserApplicated(offer, applications) || offer.deactivated
            }
          />
        ))
      ) : (
        <Flex width="100%" justifyContent="center" pt={2}>
          <Typography.P variant="h2" color="dark">
            {t("noOffers")}
          </Typography.P>
        </Flex>
      )}
      {offers?.data?.length && !isLoading && showMore && (
        <Flex justifyContent="center" width="100%">
          <Button variant="white" unsetBG onClick={handleLoadMoreOffers}>
            <ScrollIcon />
            <P variant="body">{t("moreOffers")}</P>
          </Button>
        </Flex>
      )}
    </Flex>
  );
};
