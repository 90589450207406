import { ToastContainer } from "react-toastify";
import { useRoutes } from "react-router-dom";
import { routes } from "routes";
import { CookieInfo } from "components/CookieInfo";

export const App = () => {
  const appRoutes = useRoutes(routes);

  return (
    <div>
      {appRoutes}
      <ToastContainer position="bottom-right" />
      <CookieInfo />
    </div>
  );
};

export default App;
