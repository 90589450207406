import React, { useState } from "react";
import { Footer } from "views/layouts/components/Footer";
import { Card } from "components";
import { LogoContent, ViewContent, Wrapper } from "./GuestLayout.styled";
import { Navbar } from "../App/components/Navbar";
import { navItems } from "constants/navItems";
import { SideBar } from "../App/components/Sidebar";
import { useLocation } from "react-router-dom";
import homeBG from "assets/img/home-bg.jpg";
import registerBG from "assets/img/register-bg.jpg";
import loginBG from "assets/img/login-bg.jpg";

const screens: { [key: string]: string } = {
  "/": homeBG,
  "/login-confirm": homeBG,
  "/password-reset": homeBG,
  "/password-reset/new-password": homeBG,
  "/signin": loginBG,
  "/signup": registerBG,
};

export const GuestLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const isLoggedIn = !!localStorage.getItem("token");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();
  const isHomeScreen = location.pathname === "/";

  return (
    <Wrapper isHomeScreen={isHomeScreen}>
      {isSidebarOpen && (
        <SideBar
          logged={isLoggedIn}
          isSidebarOpen={isSidebarOpen}
          sidebarItems={navItems}
          onMenuIconClick={() => setIsSidebarOpen((prevState) => !prevState)}
        />
      )}
      <Navbar
        logged={isLoggedIn}
        onMenuIconClick={() => setIsSidebarOpen((prevState) => !prevState)}
      />
      <LogoContent background={screens[location.pathname]}>
        <Footer />
      </LogoContent>
      <ViewContent>
        <Card p={isHomeScreen ? 0 : 4}>{children}</Card>
      </ViewContent>
    </Wrapper>
  );
};
