import { Flex, Typography, Link, Box } from "components";
import { useAuthDispatchContext } from "contexts/AuthContext";
import { useForm } from "react-hook-form";
import { loginUser } from "services/auth";
import { Button, TextField } from "components/_form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "schemas/auth";
import { useNavigate } from "react-router";
import { useState } from "react";
import { Spinner } from "components/Spinner";
import { ErrorText } from "components/_form/ErrorText";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getErrorMessage } from "utilities/getErrorMessage";
import { useTranslation } from "react-i18next";

interface ILoginFormData {
  email: string;
  password: string;
}

export const LoginView = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { setToken, setUserProfile } = useAuthDispatchContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  if (searchParams.get("confirmSuccess")) {
    searchParams.delete("confirmSuccess");
    setSearchParams(searchParams);
    toast.success(t("accountConfirmed"));
    window.history.replaceState(null, "", window.location.pathname);
  }

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm<ILoginFormData>({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    setError("");
    setIsLoading(true);

    try {
      const response = await loginUser(data);
      setToken(response.token);
      setUserProfile(response.data);
      navigate("/");
    } catch (err) {
      setError(getErrorMessage(err));
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <Flex height="100%" flexDirection="column" justifyContent="center">
      <Typography.H2
        variant="h2"
        color="primary"
        textAlign="start"
        fontWeight={600}
        mb={4}
      >
        {t("login")}
      </Typography.H2>
      <form onSubmit={onSubmit}>
        <TextField
          label={t("eMail") + ":"}
          transparent
          placeholder={t("emailPlaceholder") + "..."}
          {...register("email")}
          error={errors.email?.message}
        />
        <Box mt={3} mb={3}>
          <TextField
            type="password"
            label={t("password") + ":"}
            placeholder={t("passwordPlaceholder") + "..."}
            transparent
            {...register("password")}
            error={errors.password?.message}
          />
        </Box>

        <Box display="flex" justifyContent="end">
          <Link to="/password-reset">{t("forgotPass")}</Link>
        </Box>

        {error && <ErrorText>{error}</ErrorText>}

        <Box mt={3} mb={4}>
          {isLoading ? (
            <Spinner />
          ) : (
            <Button
              variant="primary"
              width="220px"
              height="50px"
              type="submit"
              id="submit"
              disabled={!formState.isValid}
            >
              {t("login")}
            </Button>
          )}
        </Box>
      </form>
      <Flex>
        <Typography.P variant="body2" color="primary" mr={1}>
          {t("accountless")}
        </Typography.P>
        <Link to="/signup" hideUnderline>
          <Typography.H4 variant="body2" fontWeight={600} color="coloured">
            {t("register")}
          </Typography.H4>
        </Link>
      </Flex>
    </Flex>
  );
};
