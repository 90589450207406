import { IBox } from "components/Box/Box";
import styled, { css } from "styled-components";
import { theme } from "config/theme";
import { flexbox, space, layout } from "styled-system";

const { palette } = theme;

const backgroundColorVariantsMap = {
  primary: palette.neutral.white,
  primaryDark: palette.primary.mainDark,
  grey: palette.neutral.mediumLightGrey,
  light: palette.neutral.lightGrey,
  coloured: palette.primary.main,
};

const colorVariantsMap = {
  primary: palette.text.primary,
  primaryDark: palette.neutral.white,
  grey: palette.text.primary,
  light: palette.text.primary,
  coloured: palette.neutral.white,
};

interface IColouredBox extends IBox {
  variant?: "primary" | "primaryDark" | "grey" | "light" | "coloured";
}

export const ColouredBox = styled.div<IColouredBox>(
  ({ variant = "primary" }) => css`
    color: ${colorVariantsMap[variant]};
    background-color: ${backgroundColorVariantsMap[variant]};
    border-radius: 8px;
  `,
  space,
  flexbox,
  layout
);
