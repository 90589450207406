import { Flex, Box } from "components";
import { Button } from "components/_form";
import { H3, H2 } from "components/Typography";
import { device } from "config/theme";
import styled from "styled-components";

export const StyledLine = styled(Box)`
  width: 100%;
  height: 2px;
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.mediumLightGrey};
  `};
`;

export const ResponsiveFlex = styled(Flex)`
  @media ${device.mobile} {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
`;

export const StyledH2 = styled(H2)`
  font-size: 38px;
`;

export const StyledH3 = styled(H3)`
  font-size: 26px;
`;

export const StyledButton = styled(Button)`
  ${({ theme }) => `
    cursor: default;
    color: ${theme.palette.text.primary};
    border: 0.5px solid ${theme.palette.neutral.medium};
    &:hover {
      color: ${theme.palette.text.primary};
    }
  `}
`;
