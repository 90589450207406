const auth = {
  password: "Hasło",
  passwordPlaceholder: "Wpisz hasło",
  repeatPassword: "Powtórz hasło",
  emailPlaceholder: "Wpisz adres e-mail",
  accountType: "Typ konta",
  login: "Zaloguj się",
  logout: "Wyloguj się",
  register: "Zarejestruj",
  reset: "Resetuj",
  resetPassword: "Zresetuj hasło",
  changePassword: "Zmień hasło",
  send_email: "Wyślij e-mail",
  accountConfirmed: "Konto zostało potwierdzone",
  checkEmail: "Sprawdź swój e-mail",
  passwordChangedSuccess: "Hasło zostało zmienione",
  emailForLink: "Wpisz adres e-mail, aby otrzymać link",
  forgotPass: "Zapomniałeś hasła?",
  accountless: "Nie masz konta?",
  accountExists: "Masz konto?",
};

const menu = {
  addOffer: "Dodaj ogłoszenie",
  myTasks: "Moje zadania",
  myOffers: "Moje ogłoszenia",
  applications: "Zgłoszenia/aplikacje",
  checkingCertificates: "Sprawdzanie certyfikatów",
  actualOffers: "Aktualne ogłoszenia",
  myApplications: "Moje Aplikacje",
  myCertificates: "Moje certyfikaty",
};

const common = {
  privacyPolicy: "Polityka Prywatności",
  regulamin: "Regulamin",
  aboutUs: "O nas",
};

const error = {
  addressesEditError: "Wystąpił błąd podczas edycji adresu(ów)",
  error: "Wystąpił błąd",
  dataEditionError: "Nie udało się zaktualizować danych",
  unedintifiedError: "Coś poszło nie tak",
  noUserData: "Brak danych użytkownika",
};

const validation = {
  phoneRequired: "Telefon jest wymagany",
  fieldRequired: "To pole jest wymagane",
  emailRequired: "Email jest wymagany",
  passwordRequired: "Hasło jest wymagane",
  firstNameRequired: "Imię jest wymagane",
  lastNameRequired: "Nazwisko jest wymagane",
  nameRequired: "Nazwa jest wymagana",
  nipRequired: "NIP jest wymagany",
  certificateNumberRequired: "Numer certyfikatu jest wymagany",
  releaseDateRequired: "Data otrzymania jest wymagana",
  expirationDateRequired: "Data ważności jest wymagana",
  certificateNameRequired: "Nazwa certyfikatu jest wymagana",
  offerTitleRequired: "Nazwa stanowiska jest wymagana",
  passDiffErr: "Hasła nie są takie same",
  passMinVal: "Hasło powinno zawierać przynajmniej 6 znaków",
  passMaxVal: "Hasło może zawierać maksymalnie 18 znaków",
  badEmailFormat: "Niepoprawny format email",
  contactNumberInvalid: "Niepoprawny format numeru telefonu",
  nipInvalid: "Niepoprawny format NIP",
  postCodeInvalid: "Niepoprawny format kodu pocztowego",
  tokenDoesntExists: "Nie ma tokenu",
};

const functional = {
  delete: "Usuń",
  cancel: "Cofnij",
  settings: "Ustawienia",
  edit: "Edytuj",
  send: "Wyślij",
  yes: "Tak",
  no: "Nie",
  addNewElement: "Dodaj nowy element",
  collapse: "Zwiń",
  expand: "Rozwiń",
  changeImage: "Zmień zdjęcie",
  save: "Zapisz",
  apply: "Zastosuj",
  search: "Szukaj",
};

const application = {
  viewProfile: "Zobacz profil",
  beenHired: "Czy został zatrudniony?",
  noApplications: "Brak aplikacji",
  applicationsForYourOffers: "Aplikacje na twoje ogłoszenia",
  noApplicationsForOffer: "Brak aplikacji na stanowisko",
  areYouSureToHire: "Czy na pewno chcesz zatrudnić kandydata?",
  areYouSureToCancelHire: "Czy na pewno chcesz cofnąć zatrudnienie?",
  applicationSucces: "Pomyślnie zaaplikowano na ofertę",
  attachCv: "Załącz cv (opcjonalnie)",
};

const address = {
  country: "Kraj",
  city: "Miasto",
  contactNumber: "Numer kontaktowy",
  eMail: "E-mail",
  nip: "Nip",
  address: "Adres",
  postCode: "Kod pocztowy",
  addressArea: "Adres (Ulica)",
  yourEmail: "Twój e-mail",
  applicationLocalisation: "Lokalizacja poszukiwanej pracy",
};

const offer = {
  // fields
  duration: "Czas trwania",
  continuousRecruitment: "Rekrutacja ciągła",
  finished: "Zakończona",
  grossSalary: "Wynagrodzenie brutto",
  workingTime: "Wymiar pracy",
  contractType: "Typ umowy",
  deactivated: "Nieaktywne",
  requirements: "Wymagania",
  responsibilities: "Obowiązki",
  benefits: "Oferujemy",
  offerTitle: "Stanowisko",
  localisation: "Lokalizacja",
  salary: "Wynagrodzenie",
  from: "od",
  offerFinishDate: "Data zakończenia rekrutacji",
  companyOrOfferName: "Nazwa firmy/stanowisko",
  filters: "Filtry",
  //
  addNewOffer: "Dodaj nowe ogłoszenie",
  enterOfferTitle: "Wpisz nazwę stanowiska",
  addLocalisation: "Dodaj lokalizację",
  addRequirements: "Dodaj wymagania",
  addResponsibilities: "Dodaj obowiązki",
  addBenefits: "Dodaj co masz w ofercie",
  addDescription: "Dodaj opis",
  youApplied: "Aplikowałeś",
  youHaveAlreadyApplied: "Już aplikowałeś",
  newOffer: "Nowa oferta",
  applyingForOffer: "Aplikowanie na ofertę",
  sendApplication: "Wyślij aplikację",
  areYouSureToDeleteOffer: "Czy na pewno chcesz usunąć ofertę",
  editOffer: "Edytuj ogłoszenie",
  noOffers: "Brak ogłoszeń",
  offerDeletionSuccesfull: "Pomyślnie usunięto ofertę",
  deleteOffer: "Usuń ofertę",
};

const userDetails = {
  //field
  available: "Dostępny",
  availableFrom: "Dostępny od",
  unavailable: "Niedostępny",
  informations: "Informacje",
  experience: "Doświadczenie zawodowe",
  skills: "Umiejętności",
  coursesAndTraining: "Kursy i szkolenia",
  interests: "Zainteresowania",
  candidateStatus: "Status kandydata",
  firstName: "Imię",
  lastName: "Nazwisko",
  employeeStatus: "Status pracownika",
  companyName: "Nazwa firmy",
  //
  updatedProfileData: "Zmieniono dane profilu",
  profile: "Profil",
  company: "Pracodawca",
  candidate: "Kandydat",
  noInfo: "brak informacji",
};

const certificate = {
  //fields
  releaseDate: "Data otrzymania",
  expirationDate: "Data ważności",
  certificateNumber: "Numer certyfikatu",
  certificateName: "Nazwa certyfikatu",
  //
  certificateForVerificationSummary: "Certyfikat do sprawdzenia - podsumowanie",
  addCertificateSummary: "Dodaj certyfikat - podsumowanie",
  editCertificateSummary: "Edycja certyfikatu - podsumowanie",
  areYouSureToDeleteCertificate: "Czy na pewno chcesz usunąć certyfikat?",
  noCertificates: "Brak certyfikatów",
  attachCertificate: "Załącz certyfikat",
  addFileWithCertificate: "Dodaj plik z certyfikatem",
  addCertificateForVerification: "Dodaj certyfikat do sprawdzenia",
  saveChanges: "Zapisz zmiany",
  goToSummary: "Przejdź do podsumowania",
  sendForVerification: "Wyślij do sprawdzenia",
  addCertificate: "Dodaj certyfikat",
  chooseCertificate: "Wybierz certyfikat",
  addNewCertificate: "Dodaj nowy certyfikat",
  enterOrChooseFromList: "Wpisz lub wybierz z listy",
  updateCertificateFile: "Aktualizuj plik z certyfikatem",
};

const home = {
  mainPageTitle: "Nowoczesny portal rekrutacyjny",
  companySlogan: "My weryfikujemy, Ty rekrutujesz!",
  checkNow: "Sprawdź teraz",
  cookieInfoPartOne:
    "Strona wykorzystuje pliki cookies. Korzystanie z witryny bez zmiany ustawień przeglądarki oznacza, że będą one umieszczane na Twoim urządzeniu. Zobacz dodatkowe informacje o wykorzystaniu plików cookies Zapoznaj się też z",
  cookieInfoPartTwo: "Polityką Prywatności",
  cookieAccept: "Akceptuje cookies",
};

const contact = {
  nameAndSurname: "Imię i nazwisko",
  messageContent: "Treść wiadomości",
  regulationConsent:
    "Wyrażam zgodę na przetwarzanie moich danych osobowych podanych formularzu w celu uzyskania od powiedzi na zapytanie. Moja zgoda jest dobrowolna, a jednocześnie niezbędna do uzyskania odpowiedzi.",
};

const shared = {
  certificates: "Certyfikaty",
  languages: "Języki",
  moreOffers: "Więcej ofert",
  companyDescription: "Opis firmy",
  description: "Szczegóły",
  details: "Szczegóły",
  addAddress: "Dodaj adres",
  contact: "Kontakt",
};

export default {
  ...auth,
  ...menu,
  ...common,
  ...error,
  ...validation,
  ...functional,
  ...application,
  ...address,
  ...offer,
  ...userDetails,
  ...certificate,
  ...home,
  ...contact,
  ...shared,
};
