import React, { useEffect, useState } from "react";
import { ColouredBox } from "components";
import { useNavigate } from "react-router-dom";
import { useAuthDispatchContext } from "contexts/AuthContext";
import { useForm } from "react-hook-form";
import {
  updateProfile,
  createAddress,
  editAddress,
  getMe,
  removeAddress,
} from "services/user";
import { toast } from "react-toastify";
import { IAddress, IUser } from "types/user";
import { yupResolver } from "@hookform/resolvers/yup";
import { companyProfileSchema } from "schemas/user";
import { useTranslation } from "react-i18next";
import { CompanyProfileForm } from "components/_profile/CompanyProfileForm";

type IFilteredAddresses = {
  [key in "add" | "edit" | "remove"]: (IAddress | number)[];
};

const defaultValues = {
  id: 0,
  email: "",
  role: "company" as "company",
  profile: {
    company_name: "",
    description: "",
  },
  addresses: [],
};

export const CompanyProfileSettingsView = (): JSX.Element => {
  const { t } = useTranslation();
  const [actualAddressesIds, setActualAddressesIds] = useState<number[]>([]);
  const [preview, setPreview] = useState<string>();
  const [uploadedFile, setUploadedFile] = useState<File>();
  const navigate = useNavigate();

  const { setUserProfile } = useAuthDispatchContext();

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm<IUser>({
    resolver: yupResolver(companyProfileSchema),
    defaultValues,
  });

  const fetchUserData = async () => {
    const { data } = await getMe();
    setActualAddressesIds(data.addresses?.map((el) => el?.id || 0) || []);
    reset(data);
  };

  const handleFileUpload = () => {
    if (uploadedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(uploadedFile);
    } else {
      setPreview("");
    }
  };

  const getFilteredAddresses = (addresses: IAddress[]) => {
    const existingIds: number[] = [];
    const filtered = addresses.reduce<IFilteredAddresses>(
      (prev, curr) => {
        if (curr.id && actualAddressesIds.includes(curr.id)) {
          existingIds.push(curr.id);
          prev.edit.push(curr);
        }
        if (!curr.id) prev.add.push(curr);

        return prev;
      },
      {
        add: [],
        edit: [],
        remove: [],
      }
    );
    filtered.remove = actualAddressesIds.filter(
      (x) => !existingIds.includes(x)
    );

    return filtered;
  };

  const handleAddressesRequests = async (addresses: IFilteredAddresses) => {
    const createPromises = addresses.add.map(
      async (el) => await createAddress(el as IAddress)
    );
    const editPromises = addresses.edit.map(
      async (el) => await editAddress(el as IAddress)
    );
    const removePromises = addresses.remove.map(
      async (el) => await removeAddress(el as number)
    );

    try {
      const results = await Promise.all([
        ...createPromises,
        ...editPromises,
        ...removePromises,
      ]);
      const actualAddresses = results.filter((el) => !!el) as IAddress[];
      setActualAddressesIds(actualAddresses.map((el) => el.id as number));

      return actualAddresses;
    } catch (e) {
      toast.error(t("addressesEditError"));
      fetchUserData();
    }
  };

  const onSubmit = async (data: IUser) => {
    try {
      const profile = { ...data["profile"] };
      (!profile.avatar || typeof profile.avatar === "string") &&
        delete profile.avatar;
      uploadedFile && (profile.avatar = uploadedFile);
      const profileResponse = await updateProfile(profile);
      const addressesResult = await handleAddressesRequests(
        getFilteredAddresses(data.addresses || [])
      );
      if (addressesResult) {
        setValue("addresses", addressesResult);
        setUserProfile({ ...profileResponse, addresses: addressesResult });
      }
      setUploadedFile(undefined);
      if (profileResponse && addressesResult) {
        toast.success(t("updatedProfileData"));
      }
      navigate("/app/company-user-details");
    } catch (err: any) {
      toast.error(t("dataEditionError"));
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    handleFileUpload();
  }, [uploadedFile]);

  return (
    <ColouredBox p={4} mb={3}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CompanyProfileForm
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
          preview={preview}
          register={register}
          control={control}
          errors={errors}
          getValues={getValues}
        />
      </form>
    </ColouredBox>
  );
};
