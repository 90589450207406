import { Flex, Avatar, ColouredBox } from "components";
import { P } from "components/Typography";
import { API_PHOTO_URL } from "constants/paths";
import { ICandidate } from "types/user";
import { separator } from "utilities/functions";
import { stringToDate } from "utilities/convertDate";
import {
  StyledLine,
  ResponsiveFlex,
  StyledH2,
  StyledH3,
  StyledButton,
} from "./CandidateProfile.styled";
import { useTranslation } from "react-i18next";

export const CandidateProfile = ({ candidate }: { candidate: ICandidate }) => {
  const { t } = useTranslation();

  function availableStatus(
    availableFrom?: string,
    available?: boolean
  ): string {
    if (available) {
      if (availableFrom) {
        const convertedDate = stringToDate(availableFrom);
        if (convertedDate < new Date()) {
          return t("yes");
        }
        return t("from") + " " + availableFrom;
      }
      return t("noInfo");
    }
    return t("no");
  }

  return (
    <ColouredBox p={4} mb={3}>
      <ResponsiveFlex mb={3} alignItems="center">
        <Avatar
          color="grey"
          size="large"
          url={`${API_PHOTO_URL}${candidate?.avatar}`}
        />
        <Flex flexDirection="column" ml={3}>
          <StyledH2 variant="h2" fontWeight={600} m={1} color="coloured">
            {candidate?.first_name} {candidate?.last_name}
          </StyledH2>
        </Flex>
      </ResponsiveFlex>

      <StyledLine my={2} />

      <Flex flexDirection="column">
        <StyledH3 mt={2} mb={3} variant="h3" fontWeight={600} color="coloured">
          {t("candidateStatus")}:
        </StyledH3>
        <Flex my={2}>
          <P variant="body2" mr={1}>
            {t("available")}:
          </P>
          <P variant="body2" fontWeight={600}>
            {availableStatus(candidate?.available_from, candidate?.available)}
          </P>
        </Flex>
      </Flex>

      <StyledLine my={2} />

      <Flex flexDirection="column">
        <StyledH3 mt={2} mb={3} variant="h3" fontWeight={600} color="coloured">
          {t("contact")}:
        </StyledH3>
        <Flex my={2}>
          <P variant="body2" fontWeight={600} mr={1}>
            {t("contactNumber")}:
          </P>
          <P variant="body2">{candidate?.contact_number}</P>
        </Flex>
        <Flex my={2}>
          <P variant="body2" fontWeight={600} mr={1}>
            {t("eMail")}:
          </P>
          <P variant="body2">{candidate?.contact_email}</P>
        </Flex>

        <StyledLine my={2} />

        <Flex my={2}>
          <P variant="body2" fontWeight={600} mr={1}>
            {t("applicationLocalisation")}:
          </P>
          <P variant="body2">{candidate?.location}</P>
        </Flex>
      </Flex>

      <StyledLine my={2} />

      <Flex flexDirection="column">
        <StyledH3 mt={2} mb={3} variant="h3" fontWeight={600} color="coloured">
          {t("informations")}:
        </StyledH3>

        <Flex mt={2} mb={3}>
          <P variant="body2" fontWeight={600}>
            {t("experience")}:
          </P>
          <P variant="body2">{candidate?.experience}</P>
        </Flex>

        <Flex mt={2} mb={3} flexDirection="column">
          <P variant="body2" fontWeight={600} mb={2}>
            {t("skills")}:
          </P>
          <P variant="body2">{candidate?.skills}</P>
        </Flex>

        <Flex mt={2} mb={3}>
          <P variant="body2" fontWeight={600}>
            {t("coursesAndTraining")}:
          </P>
          <P variant="body2">{candidate?.courses_and_training}</P>
        </Flex>

        <Flex mt={2} mb={3}>
          <P variant="body2" fontWeight={600}>
            {t("interests")}:
          </P>
          <P variant="body2">{candidate?.interests}</P>
        </Flex>

        <Flex my={2}>
          <P variant="body2" fontWeight={600} mr={1}>
            {t("languages")}:
          </P>
          <P variant="body2">
            {candidate?.languages?.map(
              ({ name }, index, array) =>
                `${name}${separator(index, array, ", ")}`
            )}
          </P>
        </Flex>
      </Flex>

      <StyledLine my={2} />

      <Flex my={3} flexDirection="column">
        <StyledH3 mt={2} mb={3} variant="h3" fontWeight={600} color="coloured">
          {t("certificates")}:
        </StyledH3>
        <Flex alignItems="start" flexDirection="row" flexWrap="wrap">
          {candidate?.certificates?.map(({ certificate_name, file, id }) => (
            <a
              key={id}
              href={`${API_PHOTO_URL}${file}`}
              target="_blank"
              rel="noreferrer"
            >
              <StyledButton key={id} variant="white" mr={2} my={1} bordered>
                <P variant="body2" color="primary" fontWeight={600}>
                  {certificate_name.name}
                </P>
              </StyledButton>
            </a>
          ))}
        </Flex>
      </Flex>
    </ColouredBox>
  );
};
