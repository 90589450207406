// elements = ['separator', 'first', 'second']
export const dragElement = (elements: string[]) => {
  var md: {
    e: MouseEvent
    offsetLeft: number
    offsetTop: number
    firstWidth: number
    secondWidth: number
  }; // remember mouse down info

  // @ts-ignore
  const separator: HTMLElement = document.getElementById(elements[0]);
  // @ts-ignore
  const first: HTMLElement = document.getElementById(elements[1]);
  // @ts-ignore
  const second: HTMLElement = document.getElementById(elements[2]);

  function onMouseMove(e: MouseEvent) {
    //console.log("mouse move: " + e.clientX);
    var delta = {
      x: e.clientX - md.e.clientX,
      y: e.clientY - md.e.clientY
    };

    // Prevent negative-sized elements
    delta.x = Math.min(Math.max(delta.x, -md.firstWidth),
      md.secondWidth);

    separator.style.left = md.offsetLeft + delta.x + "px";
    first.style.width = (md.firstWidth + delta.x) + "px";
    second.style.width = (md.secondWidth - delta.x) + "px";
  }

  function onMouseDown(e: MouseEvent) {
    //console.log("mouse down: " + e.clientX);
    md = {
      e,
      offsetLeft: separator.offsetLeft,
      offsetTop: separator.offsetTop,
      firstWidth: first.offsetWidth,
      secondWidth: second.offsetWidth
    };

    document.onmousemove = onMouseMove;
    document.onmouseup = () => {
      //console.log("mouse up");
      document.onmousemove = document.onmouseup = null;
    }
  }

  separator.onmousedown = onMouseDown;
}
