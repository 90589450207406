import React, { useEffect, useState } from "react";
import { ColouredBox } from "components";
import { useNavigate } from "react-router-dom";
import {
  useAuthDispatchContext,
  useAuthStateContext,
} from "contexts/AuthContext";
import { useForm } from "react-hook-form";
import { updateProfile, getMe } from "services/user";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { IUserForm } from "types/user";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { candidateProfileSchema } from "schemas/user";
import { stringToDate, dateToString } from "utilities/convertDate";

import { CandidateProfileForm } from "components/_profile/CandidateProfileForm";

const defaultValues = {
  first_name: "",
  last_name: "",
};

export const CandidateProfileSettingsView = (): JSX.Element => {
  const { t } = useTranslation();
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [available, setAvailable] = useState<boolean>(true);
  const [selectedOptions, setSelectedOptions] =
    useState<IUserForm["language_dic"]>();
  const [preview, setPreview] = React.useState<string>();
  const navigate = useNavigate();

  const { userProfile } = useAuthStateContext();
  const { setUserProfile } = useAuthDispatchContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<IUserForm>({
    resolver: yupResolver(candidateProfileSchema),
    defaultValues,
  });

  const onSubmit = async (data: IUserForm) => {
    try {
      const language_ids = data.language_dic?.map((el) => +el.value);
      const profile = {
        ...data,
        language_ids,
        available,
        available_from: dateToString(data.available_from),
      };
      ("string" === typeof profile.avatar || !profile.avatar) &&
        delete profile.avatar;
      uploadedFile && (profile.avatar = uploadedFile);
      const profileResponse = await updateProfile(profile);
      setUserProfile(profileResponse);
      setUploadedFile(undefined);
      if (profileResponse) {
        toast.success(t("updatedProfileData"));
      }
      navigate("/app/candidate-user-details");
    } catch (err: any) {
      toast.error(t("dataEditionError"));
    }
  };

  const fetchUserData = async () => {
    getMe().then(({ data }) => {
      const formData = {
        ...data.profile,
        ...(data.languages && {
          language_dic: data.languages.map(({ id, name }) => {
            return { label: name, value: id.toString() };
          }),
        }),
        available_from: data.profile.available_from
          ? stringToDate(data.profile.available_from)
          : new Date(),
      };
      setSelectedOptions(
        data.languages?.map(({ id, name }) => {
          return { label: name, value: id.toString() };
        })
      );
      setAvailable(!!data.profile?.available);
      reset(formData);
    });
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    if (uploadedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(uploadedFile);
    } else {
      setPreview("");
    }
  }, [uploadedFile]);

  if (!userProfile) return <></>;

  return (
    <ColouredBox p={4} mb={3}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CandidateProfileForm
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
          preview={preview}
          register={register}
          control={control}
          errors={errors}
          available={available}
          setAvailable={setAvailable}
          selectedOptions={selectedOptions}
        />
      </form>
    </ColouredBox>
  );
};
