import React from "react";
import { Link as RouterLink, LinkProps } from "react-router-dom";
import styled from "styled-components";

export interface ILink {
  to: LinkProps["to"];
  target?: string;
  hideUnderline?: boolean;
  children?: React.ReactNode;
  fontSize?: string;
  unactive?: boolean;
}

const StyledLink = styled(RouterLink)`
  ${({ theme }) => `
    color: ${theme.palette.neutral.grey};
    display: block;
    font-size: 16px;
    line-height: 20px;
  `};
`;

const Link: React.FC<ILink> = ({
  children,
  hideUnderline = false,
  to,
  target,
  fontSize,
  unactive,
}) => (
  <StyledLink
    style={{
      textDecorationLine: hideUnderline || unactive ? "none" : "underline",
      ...(fontSize && { fontSize }),
      ...(unactive && { pointerEvents: "none" }),
    }}
    target={target}
    to={to}
  >
    {children}
  </StyledLink>
);

export { Link };
