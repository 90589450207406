import { Flex, Box } from "components";
import { Button } from "components/_form";
import { P } from "components/Typography";
import { Modal } from "components/Modal";
import { useTranslation } from "react-i18next";

interface IHireModal {
  isModalOpen: boolean;
  setIsModalOpen: any;
  applicationToHire?: {
    id?: number;
    hired?: boolean;
  };
  setApplicationToHire: any;
  handleHire: any;
}

export const HireModal = ({
  isModalOpen,
  setIsModalOpen,
  applicationToHire,
  setApplicationToHire,
  handleHire,
}: IHireModal) => {
  const { t } = useTranslation();
  const onHireConfirmation = async () => {
    await handleHire();
    setApplicationToHire(undefined);
    setIsModalOpen(false);
  };

  return (
    <Modal isOpen={isModalOpen} onCancelClick={() => setIsModalOpen(false)}>
      <Box>
        <P variant="h3">
          {!applicationToHire?.hired
            ? t("areYouSureToHire")
            : t("areYouSureToCancelHire")}
        </P>
        <Flex justifyContent="end" mt={3} flexWrap="wrap">
          <Box mr={2}>
            <Button
              onClick={() => {
                setApplicationToHire(undefined);
                setIsModalOpen(false);
              }}
            >
              {t("no")}
            </Button>
          </Box>
          <Button onClick={onHireConfirmation}>{t("yes")}</Button>
        </Flex>
      </Box>
    </Modal>
  );
};
