import { Box } from "components/Box";
import React, { useRef } from "react";
import styled from "styled-components";
import { ReactComponent as BlackArrowDown } from "assets/icons/black-arrow-down.svg";
import { ReactComponent as WhiteArrowUp } from "assets/icons/white-arrow-up.svg";
import { useOnClickOutside } from "hooks";
import { Flex } from "components/Flex";

export interface IOption {
  label: string;
  value: string;
}

const WhiteArrowDown = styled(BlackArrowDown)`
  filter: invert(1);
`;

interface IProps {
  placeholder?: string | React.ReactNode;
  children: React.ReactNode;
  active?: boolean;
  customSelected?: boolean;
  customSetSelected?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Accordion = styled(Flex)<{ selected?: boolean; active?: boolean }>`
  height: 50px;
  padding: 13px;
  ${({ theme, selected, active }) => `
    background-color: ${
      selected || active
        ? theme.palette.primary.main
        : theme.palette.neutral.white
    };
    color: ${
      selected || active
        ? theme.palette.neutral.white
        : theme.palette.primary.dark
    };
    border-radius: ${selected ? "20px 20px 0 0" : "20px"};
    border: 1px solid ${theme.palette.neutral.medium};
  `}
`;

const StyledBox = styled(Box)`
  z-index: 100;
  position: absolute;
`;

interface IArrow {
  selected?: boolean;
  active?: boolean;
}

const Arrow = ({ selected, active }: IArrow) => {
  if (selected) {
    return <WhiteArrowUp />;
  }
  if (active) {
    return <WhiteArrowDown />;
  } else {
    return <BlackArrowDown />;
  }
};

export const SelectAccordion: React.FC<IProps> = ({
  placeholder,
  children,
  customSelected,
  customSetSelected,
  active,
}) => {
  const [selected, setSelected] = React.useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(wrapperRef, () => {
    customSetSelected && customSetSelected(false);
    setSelected(false);
  });

  return (
    <Box mb={3} ref={wrapperRef}>
      <Accordion
        alignItems="center"
        selected={customSetSelected ? customSelected : selected}
        active={active}
        onClick={() => {
          if (customSetSelected) {
            customSetSelected((prevState) => !prevState);
          } else {
            setSelected((prevState) => !prevState);
          }
        }}
      >
        {placeholder}{" "}
        <Box ml={2}>
          <Arrow
            selected={customSetSelected ? customSelected : selected}
            active={active}
          />
        </Box>
      </Accordion>
      {(customSetSelected ? customSelected : selected) && (
        <StyledBox>{children}</StyledBox>
      )}
    </Box>
  );
};
