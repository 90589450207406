import { PAGE, PAGES } from "constants/paths";
import { IPage, IPagesRes } from "types/pages";
import { http } from "utilities/http";

export const getPages = () =>
  http<IPagesRes>(PAGES, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

export const getPage = (slug: string) =>
  http<{ data: IPage }>(PAGE(slug), {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
