import { Flex, Avatar, ColouredBox } from "components";
import { P } from "components/Typography";
import { API_PHOTO_URL } from "constants/paths";
import { IProfile } from "types/user";
import {
  StyledLine,
  ResponsiveFlex,
  StyledH2,
  StyledH3,
} from "./CompanyProfile.styled";
import { useTranslation } from "react-i18next";

export const CompanyProfile = ({ company }: { company: IProfile }) => {
  const { t } = useTranslation();
  return (
    <ColouredBox p={4} mb={3}>
      <ResponsiveFlex mb={3} alignItems="center">
        <Avatar
          color="grey"
          size="large"
          url={`${API_PHOTO_URL}${company?.avatar}`}
        />
        <Flex flexDirection="column" ml={3}>
          <StyledH2 variant="h2" fontWeight={600} m={1} color="coloured">
            {company?.company_name}
          </StyledH2>
          <Flex mx={1}>
            <P variant="body" color="coloured">
              {t("nip")}:{" "}
            </P>

            <P ml={1} variant="body">
              {company?.nip}
            </P>
          </Flex>
        </Flex>
      </ResponsiveFlex>

      <StyledLine my={2} />

      <Flex flexDirection="column">
        <StyledH3 mt={2} mb={3} variant="h3" fontWeight={600} color="coloured">
          {t("companyDescription")}:
        </StyledH3>
        <Flex my={2}>
          <P variant="body2" mr={1}>
            {company?.description || "brak opisu"}
          </P>
        </Flex>
      </Flex>

      <StyledLine my={2} />

      {company?.addresses?.map((address, index) => (
        <>
          <Flex flexDirection="column">
            <StyledH3
              mt={2}
              mb={3}
              variant="h3"
              fontWeight={600}
              color="coloured"
            >
              {t("address")} {index === 0 ? "" : index + 1}:
            </StyledH3>

            <Flex mt={2} mb={3}>
              <P variant="body2" fontWeight={600}>
                {t("country")}:
              </P>
              <P variant="body2" ml={1}>
                {address?.country}
              </P>
            </Flex>

            <Flex mt={2} mb={3}>
              <P variant="body2" fontWeight={600}>
                {t("city")}:
              </P>
              <P variant="body2" ml={1}>
                {address?.city}
              </P>
            </Flex>

            <Flex mt={2} mb={3}>
              <P variant="body2" fontWeight={600}>
                {t("address")}:
              </P>
              <P variant="body2" ml={1}>
                {address?.details}
              </P>
            </Flex>

            <Flex mt={2} mb={3}>
              <P variant="body2" fontWeight={600}>
                {t("postCode")}:
              </P>
              <P variant="body2" ml={1}>
                {address?.zip_code}
              </P>
            </Flex>

            <Flex mt={2} mb={3}>
              <P variant="body2" fontWeight={600}>
                {t("contactNumber")}:
              </P>
              <P variant="body2" ml={1}>
                {address?.contact_number}
              </P>
            </Flex>

            <Flex mt={2} mb={3}>
              <P variant="body2" fontWeight={600}>
                {t("eMail")}:
              </P>
              <P variant="body2" ml={1}>
                {address?.contact_email}
              </P>
            </Flex>
          </Flex>

          <StyledLine my={2} />
        </>
      ))}
    </ColouredBox>
  );
};
