import { http } from "utilities/http";
import { IAddress, IProfile, IUser, ICandidate } from "types/user";
import {
  ADDRESSES_URL,
  ME_URL,
  PROFILE,
  USER,
  CANDIDATE,
} from "constants/paths";
import { serialize } from "object-to-formdata";

export const getUser = (id: string) =>
  http<{ data: IUser }>(USER(id), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

export const getCandidate = (id: string) =>
  http<{ data: ICandidate }>(CANDIDATE(id), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

export const getMe = () =>
  http<{ data: IUser }>(ME_URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

export const updateProfile = async (profile: IProfile) => {
  const res = await http<{ data: IUser }>(PROFILE, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
    },
    body: serialize({ profile }),
  });

  return res.data;
};

export const createAddress = async (address: IAddress) => {
  const res = await http<{ data: IAddress }>(ADDRESSES_URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: serialize({ address }),
  });

  return res.data;
};

export const editAddress = async (address: IAddress) => {
  const res = await http<{ data: IAddress }>(`${ADDRESSES_URL}/${address.id}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
    },
    body: serialize({ address }),
  });

  return res.data;
};

export const removeAddress = async (id: number) => {
  return await http<{ data: IAddress }>(`${ADDRESSES_URL}/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
    },
  });
};
