import { LANGUAGES } from "constants/paths";
import { ILanguages } from "types/languages";
import { useFetchAPI } from "./useFetchAPI";

export const useFetchDictionaryLanguages = () => {
  const { data } = useFetchAPI<ILanguages>(LANGUAGES, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  return {
    dictionaryLanguages: data?.data.map(({ id, name }) => ({
      label: name,
      value: id.toString(),
    })),
  };
};
