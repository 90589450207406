import { Typography } from "components";
import styled, { css } from "styled-components";

export const LinkItem = styled(Typography.P)<{
  isItalic?: boolean;
  color: string;
}>`
  white-space: nowrap;
  margin-right: 30px;

  color: ${({ color }) => color || "rgba(255, 255, 255, 0.8)"};
  ${({ isItalic }) =>
    isItalic &&
    css`
      font-size: 12px;
      font-style: italic;
    `};
`;
