import { Box, Flex } from "components";
import { IOffer } from "types/offer";
import { FC } from "react";
import { P } from "components/Typography";
import { API_PHOTO_URL } from "constants/paths";
import { ReactComponent as LocalisationIcon } from "assets/icons/localisation.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { useWindowWidth } from "hooks/useWindowWidth";
import { separator } from "utilities/functions";
import { stringToDate } from "utilities/convertDate";
import { isOneDayOld, isThreeDaysOld } from "utilities/functions";
import {
  Wrapper,
  StyledImg,
  StyledH3,
  StyledP,
  DisabledButton,
  NewOfferIcon,
  StyledText,
} from "./OfferCard.styled";
import { useTranslation } from "react-i18next";

interface IOfferCard {
  offer: IOffer["data"];
  button: {
    text: string;
    onClick: () => any;
  };
  isDisabled?: boolean;
}

export const OfferCard: FC<IOfferCard> = ({ offer, button, isDisabled }) => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();

  const isOfferFinished = () => {
    if (!offer.finish_at) return false;
    return isOneDayOld(stringToDate(offer.finish_at));
  };

  return (
    <Wrapper
      onClick={button.onClick}
      isDisabled={isDisabled}
      isFinished={isOfferFinished()}
    >
      <Flex height={["80px", "160px"]}>
        <StyledImg
          src={
            offer.profile?.avatar
              ? `${API_PHOTO_URL}${offer.profile?.avatar}`
              : "https://via.placeholder.com/160x160"
          }
        />
        <Flex
          width="100%"
          justifyContent="space-between"
          flexWrap="wrap"
          py={[0, 3]}
          px={[1, 3]}
        >
          <Flex
            flexDirection="column"
            width={["100%", isDisabled && windowWidth > 600 ? "30%" : "65%"]}
          >
            <Flex flexDirection="row">
              <StyledH3 mb={[0, 2]} variant="h3" color="coloured">
                {offer.title}
              </StyledH3>
              {isDisabled && windowWidth < 600 && (
                <StyledH3 ml={1} mb={[0, 2]} variant="h3">
                  {offer.deactivated
                    ? `(${t("deactivated")})`
                    : `(${t("youApplied")})`}
                </StyledH3>
              )}
            </Flex>

            <StyledP mb={[0, 2]} variant="body">
              {offer.profile?.company_name}
            </StyledP>

            <Flex>
              <Box mr={1}>
                <LocalisationIcon />
              </Box>
              <StyledP mb={[0, 2]} variant="body" fontWeight={700}>
                {offer?.locations?.map(
                  (location, index, array) =>
                    `${location.country}, ${location.city}${separator(
                      index,
                      array
                    )}`
                )}
              </StyledP>
            </Flex>
            {windowWidth > 600 && (
              <P mb={2} variant="body" color="grey">
                {offer.working_times.map(
                  ({ name }, index, array) =>
                    `${name}${separator(index, array)}`
                )}
                {" | "}
                {offer.contract_types.map(
                  ({ name }, index, array) =>
                    `${name}${separator(index, array)}`
                )}
              </P>
            )}
          </Flex>
          {isDisabled && windowWidth > 600 && (
            <Flex flexDirection="column" justifyContent="center">
              <DisabledButton variant="white" bordered>
                {offer.deactivated
                  ? t("deactivated")
                  : t("youHaveAlreadyApplied")}
              </DisabledButton>
            </Flex>
          )}
          <Flex
            flexDirection="column"
            alignItems={["start", "end"]}
            {...(isDisabled && windowWidth > 600 && { width: "30%" })}
          >
            <StyledH3 mb={[0, 2]} variant="h3">
              {offer.min_range_salary} - {offer.max_range_salary} PLN
            </StyledH3>

            {windowWidth > 400 && (
              <Flex alignItems="center">
                <Flex flexDirection="column" alignItems="end" mr={1}>
                  <Flex mb={2} mt={[0, 3]}>
                    {offer.continuous_recruitment ? (
                      <P mb={2} variant="body" fontWeight={600}>
                        {t("continuousRecruitment")}
                      </P>
                    ) : (
                      <>
                        <P mb={2} variant="body" color="grey">
                          {offer.start_at && offer.start_at.split(" ")[0]}{" "}
                          -&nbsp;
                        </P>
                        <P mb={2} variant="body" fontWeight={600}>
                          {offer.finish_at && offer.finish_at.split(" ")[0]}
                        </P>
                      </>
                    )}
                    <Box ml={2}>
                      <CalendarIcon />
                    </Box>
                  </Flex>
                  <P mb={2} variant="body" fontWeight={600}>
                    {isOfferFinished() && t("finished")}
                  </P>
                </Flex>

                {offer.created_at &&
                  !isThreeDaysOld(stringToDate(offer.created_at)) && (
                    <NewOfferIcon>
                      <StyledText>{t("newOffer")}!</StyledText>
                    </NewOfferIcon>
                  )}
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Wrapper>
  );
};
