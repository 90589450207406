import { Flex } from "components/Flex";
import { P } from "components/Typography";
import { ReactComponent as CheckmarkIcon } from "assets/icons/checkmark.svg";
import React, { InputHTMLAttributes } from "react";
import styled from "styled-components";

export interface CheckboxI extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: boolean;
  name?: string;
  id?: string;
  checked?: boolean;
}

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: pre-wrap;

  + label {
    cursor: pointer;
    ${({ theme }) => `
      color: ${theme.palette.neutral.grey};
    `};
  }
`;

const Control = styled(Flex)`
  ${({ theme }) => `    
    position: relative;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border: 1px solid ${theme.palette.primary.main};
    border-radius: 3px;
    top: 0;
  `}
`;

const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  ${({ theme }) => `
    display: none;
    position: absolute;
    top: 1px;
    left: 2px;
    width: 12px;
    height: 12px;
    fill: ${theme.palette.neutral.white};
  `}
`;

interface IInput {
  error?: boolean;
}

const Input = styled.input<IInput>`
  ${({ theme, error }) => `
    opacity: 0;
    width: 16px;
    height: 16px;
    display: none;
    border-radius: 3px;
    + ${Control} {
      ${error ? `border-color: ${theme.palette.primary.main}` : ""};
    }

    &:checked {
      + ${Control} {
        background-color: ${theme.palette.primary.main};
        border-color: ${
          error ? theme.palette.accent.red : theme.palette.primary.main
        };

        ${StyledCheckmarkIcon} {
          display: block;
        }
      }
    }

    &:disabled {
      + ${Control} {
        opacity: 0.4;

        background-color: ${theme.palette.neutral.medium};
        border-color: ${theme.palette.neutral.medium};
      }
    }
  `}
`;

export const Checkbox: React.FC<CheckboxI> = ({
  label,
  name,
  error,
  checked,
  onChange,
  id,
}) => {
  return (
    <Label>
      <Input
        type="checkbox"
        checked={checked}
        error={error}
        name={name}
        onChange={onChange}
        id={id}
      />
      <Control>
        <StyledCheckmarkIcon />
      </Control>
      <P variant="body">{label}</P>
    </Label>
  );
};
