import styled, {css} from "styled-components";
import {
  space,
  SpaceProps,
} from "styled-system"

export const NavText = styled.p<SpaceProps>(
  () => css`
    cursor: pointer;
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.05em;
    white-space: nowrap;
    color: ${({theme}) => theme.palette.text.primary};
  `,
  space,
)
