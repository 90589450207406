import { Card, Flex } from "components";
import styled from "styled-components";

export const StyledCard = styled(Card)<{ isDisabled?: boolean }>`
  ${({ isDisabled = false }) => `
    ${isDisabled && `cursor: default`};
  `}
  margin: 12px 0;
  padding: 12px 20px;
  cursor: pointer;
`;

export const StyledFlex = styled(Flex)`
  user-select: none;
`;

export const Wrapper = styled.div`
  ${() => `
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr) );
    grid-gap: 15px
  `}
`;
