import { Flex, Box, CustomDatePicker } from "components";
import { SelectAccordion } from "components/SelectAccordion";
import { P } from "components/Typography";
import { Button, Select, TextField } from "components/_form";
import styled from "styled-components";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/bx-x.svg";
import { theme } from "config/theme";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  useFetchDictionaryCountries,
  useFetchDictionaryLanguages,
  useFetchDictionaryCertificates,
} from "hooks";
import { useFiltersDispatchContext } from "contexts/FiltersContext";
import { useTranslation } from "react-i18next";
import { capitalize } from "utilities/functions";

const DeleteButton = styled(Box)`
  cursor: pointer;
  filter: invert(1);
`;

const StyledDatesFlex = styled(Flex)`
  padding: 10px;
  border-radius: 0px 20px 20px 20px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  ${({ theme }) => `
      background-color: ${theme.palette.neutral.white};
  `}
`;

export const Filters = () => {
  const { t } = useTranslation();
  const [isSalarySelectOpen, setIsSalarySelectOpen] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const { dictionaryCountries } = useFetchDictionaryCountries();
  const { dictionaryLanguages } = useFetchDictionaryLanguages();
  const { dictionaryCertificates } = useFetchDictionaryCertificates();

  const { setFilters } = useFiltersDispatchContext();

  const { handleSubmit, control, watch, register, resetField, getValues } =
    useForm<{
      min_range_salary: number;
      finish_at: Date;
      text: string;
      country_ids: { value: string; label: string }[];
      language_ids: { value: string; label: string }[];
      certificate_name_ids: { value: string; label: string }[];
    }>({
      mode: "onChange",
    });

  const onSubmit = handleSubmit(async (data) => {
    let keyData: keyof typeof data;
    for (keyData in data) {
      if (data[keyData] === undefined) delete data[keyData];
    }

    const {
      text,
      country_ids,
      language_ids,
      certificate_name_ids,
      min_range_salary,
      finish_at,
      ...rest
    } = data;

    const preparedFilters = {
      ...rest,
      ...(finish_at
        ? { finish_at: new Date(finish_at.setDate(finish_at.getDate() + 1)) }
        : []),
      ...(text ? { text } : []),
      ...(min_range_salary && { min_range_salary }),
      ...(country_ids && country_ids[0]?.value
        ? { country_ids: country_ids.map(({ value }) => value) }
        : []),
      ...(language_ids && language_ids[0]?.value
        ? { language_ids: language_ids.map(({ value }) => value) }
        : []),
      ...(certificate_name_ids && certificate_name_ids[0]?.value
        ? {
            certificate_name_ids: certificate_name_ids.map(
              ({ value }) => value
            ),
          }
        : []),
    };

    setFilters(preparedFilters);
  });

  useEffect(() => {
    onSubmit();
  }, [
    watch("country_ids"),
    watch("language_ids"),
    watch("certificate_name_ids"),
    watch("finish_at"),
  ]);

  return (
    <form>
      <Flex
        width="100%"
        alignItems="center"
        flexWrap="wrap"
        justifyContent="space-between"
      >
        <Flex alignItems="center" flexWrap="wrap">
          <Box mr={2} mb={3}>
            <P variant="body">{t("filters")}: </P>
          </Box>
          <Box mr={2}>
            <SelectAccordion
              placeholder={
                getValues("min_range_salary") && !isSalarySelectOpen ? (
                  <>
                    {capitalize(t("from")) +
                      ": " +
                      getValues("min_range_salary") +
                      " PLN"}
                    <DeleteButton
                      onClick={() => {
                        resetField("min_range_salary");
                        onSubmit();
                      }}
                      ml={1}
                    >
                      <DeleteIcon />
                    </DeleteButton>
                  </>
                ) : (
                  t("salary")
                )
              }
              customSelected={isSalarySelectOpen}
              customSetSelected={setIsSalarySelectOpen}
              active={!!getValues("min_range_salary")}
            >
              <StyledDatesFlex flexDirection="column">
                <Flex justifyContent="center" alignItems="center" mt={1} mb={3}>
                  <P variant="body">{t("from")}: </P>
                  <Box ml={2}>
                    <TextField
                      height="32px"
                      {...register("min_range_salary")}
                    />
                  </Box>
                </Flex>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setIsSalarySelectOpen(false);
                    onSubmit();
                  }}
                >
                  {t("apply")}
                </Button>
              </StyledDatesFlex>
            </SelectAccordion>
          </Box>

          <Box mr={2}>
            <SelectAccordion
              placeholder={
                watch("finish_at") instanceof Date ? (
                  <>
                    {watch("finish_at").toLocaleDateString()}
                    <DeleteButton
                      onClick={() => resetField("finish_at")}
                      ml={1}
                    >
                      <DeleteIcon />
                    </DeleteButton>
                  </>
                ) : (
                  t("offerFinishDate")
                )
              }
              customSelected={isDatePickerOpen}
              customSetSelected={setIsDatePickerOpen}
              active={!!getValues("finish_at")}
            >
              <Controller
                control={control}
                name="finish_at"
                render={({ field: { value, onChange } }) => (
                  <CustomDatePicker
                    value={value}
                    onChange={(date: Date) => {
                      onChange(date);
                      setIsDatePickerOpen((prevState) => !prevState);
                    }}
                    id="finish_at"
                    inline
                  />
                )}
              />
            </SelectAccordion>
          </Box>
          <Box mr={2} width="150px">
            <Controller
              control={control}
              name="certificate_name_ids"
              render={({ field: { onChange } }) => (
                <Select
                  placeholder={t("certificates") as string}
                  isMulti={true}
                  onChange={(val) => onChange(val)}
                  options={dictionaryCertificates || []}
                  ariaLabel="certificate_name_ids"
                  highlight
                />
              )}
            />
          </Box>
          <Box mr={2} width="150px">
            <Controller
              control={control}
              name="language_ids"
              render={({ field: { onChange } }) => (
                <Select
                  placeholder={t("languages") as string}
                  isMulti={true}
                  onChange={(val) => onChange(val)}
                  options={dictionaryLanguages || []}
                  ariaLabel="language_ids"
                  highlight
                />
              )}
            />
          </Box>
          <Box mr={2} width="150px">
            <Controller
              control={control}
              name="country_ids"
              render={({ field: { onChange } }) => (
                <Select
                  placeholder={t("country") as string}
                  isMulti={true}
                  onChange={(val) => onChange(val)}
                  options={dictionaryCountries || []}
                  ariaLabel="country_ids"
                  highlight
                />
              )}
            />
          </Box>
        </Flex>

        <Flex mb={3} width={["100%", "480px"]}>
          <TextField
            height="40px"
            placeholder={t("companyOrOfferName") + "..."}
            iconSize={93}
            iconBackgroundColor={theme.palette.primary.dark}
            icon={
              <>
                <Box mr={2}>
                  <SearchIcon />
                </Box>
                {t("search")}
              </>
            }
            onIconClick={onSubmit}
            {...register("text")}
          />
        </Flex>
      </Flex>
    </form>
  );
};
