import styled from "styled-components";
import { Card, Flex } from "components";
import { device } from "config/theme";
import { ReactComponent as Illustration } from "assets/img/main-illustration.svg";

export const LogoContent = styled(Flex)<{ background: string }>`
  justify-content: left;
  align-items: flex-end;
  ${({ background }) => background && `background: url(${background});`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

export const Logo = styled(Illustration)`
  min-width: 300px;
  min-height: 300px;
  @media ${device.laptop} {
    min-width: 200px;
    min-height: 300px;
  }
`;
export const FooterContent = styled(Flex)`
  grid-area: footer;
  @media ${device.laptop} {
    justify-content: center;
  }
  @media ${device.mobile} {
    ${({ theme }) => `
      background-color: ${theme.palette.primary.main};
    `}
  }
`;
export const ViewContent = styled(Flex)`
  max-width: 100vw;
  grid-area: content;
  > ${Card} {
    background: ${({ theme }) => theme.palette.neutral.lightGrey};
    border-radius: 0px;
    overflow: auto;
  }
`;

export const Wrapper = styled.main<{ isHomeScreen?: boolean }>`
  ${({ theme }) => `
    background-color: ${theme.palette.primary.main};
  `}
  display: grid;
  height: 100vh;

  grid-template-columns: ${({ isHomeScreen }) =>
    isHomeScreen ? "50% 50%;" : "5fr 2fr;"};
  grid-template-rows: 60px 1fr;
  grid-template-areas:
    "navbar navbar"
    "description content";
  //"footer content";

  > ${LogoContent}, ${FooterContent} {
    padding: 21px;
  }

  @media ${device.laptop} {
    grid-template-columns: 1fr 1fr;

    > ${LogoContent}, ${FooterContent} {
      padding: 30px;
    }
  }

  @media ${device.mobile} {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "navbar"
      "description"
      "content"
      "footer";
  }
`;
