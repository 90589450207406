import { Box } from "components/Box";
import React, { useEffect } from "react";
import { default as reactSelect, StylesConfig } from "react-select";
import styled from "styled-components";
import { FieldLabel as Label } from "components/_form/FieldLabel";
import { ErrorText } from "../ErrorText";
import { theme } from "config/theme";

export interface IOption {
  label: string;
  value: string;
}

interface ISelect {
  error?: string;
  isClearable?: boolean;
  options: IOption[];
  selectedOptions?: IOption[] | IOption;

  onChange?(e: IOption | IOption[]): void;

  transparent?: boolean;
  label?: string;
  hideElements?: boolean;
  isMulti?: boolean;
  placeholder?: string;
  ariaLabel?: string;
  highlight?: boolean;
}

interface IStyledTags {
  transparent?: ISelect["transparent"];
}

const StyledSelect = styled(reactSelect)<IStyledTags>`
  ${({ transparent }) => `
    ${transparent && `& > div:nth-child(2) {background-color: transparent;}`}
  `}
  .valueContainer {
    opacity: 1;
  }
`;

type IsMulti = false;

export const Select: React.FC<ISelect> = ({
  error,
  selectedOptions,
  isClearable = false,
  options,
  onChange,
  label,
  transparent,
  hideElements,
  isMulti,
  placeholder,
  ariaLabel,
  highlight,
}) => {
  const [value, setValue] = React.useState<IOption[] | IOption>(
    selectedOptions || []
  );

  const customStyles: StylesConfig = {
    control: (baseStyles: any, state: any) => ({
      ...baseStyles,
      borderRadius: state.isFocused ? "20px 20px 0 0" : "20px",
      borderColor: state.isFocused ? theme.palette.primary.main : "white",
      backgroundColor:
        state.isFocused || (highlight && state.hasValue)
          ? theme.palette.primary.main
          : "white",
      color: state.isFocused ? "white" : "",
      minHeight: "50px",
      width: "100%",
      border: `1px solid ${theme.palette.neutral.medium}`,
    }),
    placeholder: (styles: any, state: any) => ({
      ...styles,
      color: state.isFocused ? "white" : "",
    }),
    input: (styles: any, state: any) => ({
      ...styles,
      color: state.isFocused ? "white" : "",
    }),
    singleValue: (styles: any, state: any) => ({
      ...styles,
      color: state.isFocused ? "white" : "",
    }),
  };
  if (hideElements) {
    customStyles.multiValue = (provided: any) => ({
      ...provided,
      display: "none",
    });
    customStyles.indicatorsContainer = (provided: any) => ({
      ...provided,
      display: "none",
    });
  }

  const handleChange = (newValue: IOption) => {
    const val = isMulti
      ? Array.isArray(newValue)
        ? newValue
        : [newValue]
      : newValue;
    setValue(val);
    if (onChange) onChange(val);
  };

  useEffect(() => {
    if (selectedOptions) setValue(selectedOptions);
  }, [selectedOptions]);

  return (
    <Box width="100%" mb={3}>
      {label && <Label error={error}>{label}</Label>}
      <StyledSelect
        /*// @ts-ignore*/
        onChange={handleChange}
        isClearable={isClearable}
        styles={customStyles}
        transparent={transparent}
        value={value}
        isMulti={isMulti}
        closeMenuOnSelect={true}
        options={options}
        placeholder={placeholder}
        aria-label={ariaLabel}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </Box>
  );
};
