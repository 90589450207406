import React, { useRef } from "react";
import { Flex, Box, Avatar } from "components";
import { useNavigate } from "react-router-dom";
import { Button, TextField, TextareaField } from "components/_form";
import { useAuthStateContext } from "contexts/AuthContext";
import { theme } from "config/theme";
import { useFieldArray } from "react-hook-form";
import { API_PHOTO_URL } from "constants/paths";
import {
  StyledFlex,
  StyledLine,
  ResponsiveFlex,
} from "views/Profile/ProfileView.styled";
import { AddressFormPart } from "components/AddressFormPart";
import { IUser } from "types/user";
import { ErrorsAddressFormPart } from "components/AddressFormPart/AddressFormPart";
import { useTranslation } from "react-i18next";

interface ICandidateProfileForm {
  uploadedFile?: File;
  setUploadedFile: any;
  preview?: string;
  register: any;
  control: any;
  errors: any;
  getValues: any;
}

export const CompanyProfileForm = ({
  uploadedFile,
  setUploadedFile,
  preview,
  register,
  control,
  errors,
  getValues,
}: ICandidateProfileForm): JSX.Element => {
  const { userProfile } = useAuthStateContext();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "addresses",
  });

  return (
    <>
      <ResponsiveFlex mb={3} alignItems="center">
        <Avatar
          size="large"
          color="grey"
          url={preview || `${API_PHOTO_URL}${userProfile?.profile?.avatar}`}
        />

        <Flex flexDirection="column" ml={3}>
          <Button
            variant="primary"
            onClick={(event) => {
              event.preventDefault();
              fileInputRef.current?.click();
            }}
          >
            {uploadedFile ? uploadedFile.name : t("changeImage")}
          </Button>
          <input
            type="file"
            multiple
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={(event) => {
              if (event.target.files) {
                setUploadedFile(event.target.files[0]);
              }
            }}
          />
        </Flex>
      </ResponsiveFlex>

      <Box width="100%" p={1}>
        <TextField
          label={t("companyName")}
          {...register("profile.company_name")}
          error={errors.profile?.company_name?.message}
        />
      </Box>
      <Flex>
        <TextField
          label={t("nip")}
          {...register("profile.nip")}
          error={errors.profile?.nip?.message}
        />
      </Flex>
      <StyledLine mt={3} />
      <Box my={2} p={1}>
        <TextareaField
          rows={5}
          bgColor={theme.palette.neutral.white}
          label={t("companyDescription") as string}
          {...register("profile.description")}
          error={errors.profile?.description?.message}
        />
      </Box>
      <AddressFormPart<IUser, "addresses">
        register={register}
        errors={errors.addresses as ErrorsAddressFormPart}
        fields={fields}
        remove={remove}
        onAppend={() => {
          append({
            country: "",
            city: "",
            zip_code: "",
            details: "",
            contact_number: "",
            contact_email: "",
            profile_id: getValues("profile.id"),
          });
        }}
      />
      <StyledFlex justifyContent="end" alignItems="end" mt={4}>
        <Button
          bordered={false}
          unsetBG
          variant="white"
          onClick={() => userProfile && navigate("/app/company-user-details")}
        >
          {t("cancel")}
        </Button>
        <Button type="submit">{t("save")}</Button>
      </StyledFlex>
    </>
  );
};
