import { FieldLabel as Label } from "components/_form/FieldLabel";
import React, { TextareaHTMLAttributes } from "react";
import styled from "styled-components";
import { rgba } from "utilities/rgba";
import { ErrorText } from "../ErrorText";
import { useTranslation } from "react-i18next";

export interface ITextareaField
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: string;
  unit?: string;
  icon?: React.ReactNode;
  bgColor?: string;
  height?: string;
}

interface StyledTextareaI {
  error?: ITextareaField["error"];
  unit?: ITextareaField["unit"];
  icon?: ITextareaField["icon"];
  bgColor?: ITextareaField["bgColor"];
  height?: ITextareaField["height"];
}

const unitWidthInPx = 44;
const iconSizeInPx = 24;
const iconPositionRight = 4;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Unit = styled.div`
  ${({ theme }) => `
    position: absolute;
    top: 0;
    right: 0;   
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${unitWidthInPx}px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 8px;
      left: 0;
      height: 24px;
      width: 1px;
      background-color: ${theme.palette.neutral.medium};
    }
  `};
`;

const Icon = styled.div`
  position: absolute;
  right: ${iconPositionRight}px;
  top: 8px;
  display: flex;
  height: ${iconSizeInPx}px;
  width: ${iconSizeInPx}px;
`;

const StyledTextarea = styled.textarea<StyledTextareaI>`
  ${({ theme, error, unit, icon, bgColor, height }) => `
    display: block;
    background: white;
    width: 100%;
    height: ${height ? height : "100%"};
    padding: 8px 15px;
    resize: none;
    ${unit ? `padding-right: ${16 + unitWidthInPx}px` : ""};
    ${icon ? `padding-right: ${16 + iconSizeInPx + iconPositionRight}px` : ""};
    font-size: 13px;
    font-family: inherit;
    color: ${theme.palette.text.primary};
    border: 1px solid #C7C7C7;
    border-radius: 20px;
    outline: none;

    &:focus {
      border-color: ${theme.palette.primary.main};

      + ${Unit}::before {
        background-color: ${theme.palette.primary.main};
      }

      + ${Icon} {
        color: ${theme.palette.primary.main};
      }
    }

    &:disabled {
      color ${rgba(theme.palette.text.primary, 0.4)};
      background-color: ${theme.palette.neutral.veryLight};

      + ${Unit} {
        color: ${rgba(theme.palette.text.primary, 0.4)};

        &::before {
          background-color: ${rgba(theme.palette.text.primary, 0.4)};
        }
      }

      + ${Icon} {
        color: ${rgba(theme.palette.text.primary, 0.4)};
      }
    }
  `};
`;

export const TextareaField = React.forwardRef<
  HTMLTextAreaElement,
  ITextareaField
>(({ label, error, unit, icon, disabled, bgColor, height, ...props }, ref) => {
  const { t } = useTranslation();

  return (
    <InputWrapper>
      {label ? (
        <Label error={error} disabled={disabled}>
          {label}
        </Label>
      ) : null}
      <>
        <StyledTextarea
          ref={ref}
          error={error}
          unit={unit}
          icon={icon}
          bgColor={bgColor}
          disabled={disabled}
          height={height}
          {...props}
        />
        {unit && <Unit>{unit}</Unit>}
        {icon && <Icon>{icon}</Icon>}
      </>
      {error && <ErrorText>{t(error)}</ErrorText>}
    </InputWrapper>
  );
});

TextareaField.displayName = "TextareaField";
