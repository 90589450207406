import { CERTIFICATE_NAMES } from "constants/paths";
import { ICertificateNames } from "types/certificate";
import { useFetchAPI } from "./useFetchAPI";

export const useFetchDictionaryCertificates = () => {
  const { data } = useFetchAPI<ICertificateNames>(CERTIFICATE_NAMES, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  return {
    dictionaryCertificates: data?.data.map(({ id, name }) => ({
      label: name,
      value: id.toString(),
    })),
  };
};
