import { useEffect, useState } from "react";
import { http } from "utilities/http";

export const useFetchAPI = <T>(url: string, options?: RequestInit) => {
  const [data, setData] = useState<T | null>(null);

  useEffect(() => {
    http<T>(url, options).then((response) => setData(response));
  }, []);

  return { data };
};
