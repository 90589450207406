import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
  }
  
  body {
    margin: unset;
    font-family: "Lato", sans-serif;
    font-size: 13px;
  }
  
  #root {
    height: 100vh;
  }

  select {
    font-family: "Lato", sans-serif;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    margin: unset;
    padding: unset;
  }
`;
