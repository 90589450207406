import { useState } from "react";
import { Card, Flex } from "components/index";
import { ReactComponent as AccordionIcon } from "assets/icons/arrow-down.svg";
import { H3, P } from "components/Typography";
import styled from "styled-components";

const StyledCard = styled(Card)<{ isDisabled?: boolean }>`
  ${({ isDisabled = false }) => `
    ${isDisabled && `cursor: default`};
  `}
  margin: 12px 0;
  padding: 12px 20px;
  cursor: pointer;
`;

const StyledFlex = styled(Flex)`
  user-select: none; /* unwanted text selection on accordion clicking */
`;

export const FaqAccordion = ({
  question,
  answer,
}: {
  question: string;
  answer: any;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <StyledCard onClick={() => setIsOpen((prevState) => !prevState)}>
      <StyledFlex justifyContent="space-between">
        <H3 variant="h3" color="coloured">
          {question}
        </H3>
        <AccordionIcon />
      </StyledFlex>
      {isOpen && (
        <P variant="body2" mt={3}>
          {answer}
        </P>
      )}
    </StyledCard>
  );
};
