import { FC, useEffect, useState } from "react";
import { getMe } from "services/user";
import { Box, Flex, CertificateCard, Typography } from "components";
import { Button } from "components/_form";
import styled from "styled-components";
import { ICertificate } from "types/user";
import { Modal } from "components/Modal";
import { deleteCertificate } from "services/certificate";
import { P } from "components/Typography";
import { useAuthStateContext } from "contexts/AuthContext";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

interface ICertificatesView {
  filter?: "available";
}

export const StyledFlex = styled(Flex)`
  width: 100%;
`;

export const CertificatesView: FC<ICertificatesView> = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [certificates, setCertificates] = useState<ICertificate[] | null>();
  const [deleteCertificateId, setDeleteCertificateId] = useState<
    number | undefined
  >();
  const navigate = useNavigate();

  const { userProfile } = useAuthStateContext();
  const role = userProfile?.role;

  function certificateHeader(): string {
    return role === "company"
      ? t("addCertificateForVerification")
      : t("addCertificate");
  }

  const navigateToAddCertificate = () => {
    if (role === "company") navigate(`verification`);
    if (role === "candidate") navigate(`add`);
  };

  const handleDeleteCertificate = async () => {
    if (deleteCertificateId) {
      await deleteCertificate(deleteCertificateId);
    }
  };

  const onDeleteConfirmation = async () => {
    await handleDeleteCertificate();
    setDeleteCertificateId(undefined);
    setIsModalOpen(false);
  };

  useEffect(() => {
    getMe().then(({ data }) => {
      setCertificates(data.certificates);
    });
  }, [isModalOpen]);

  return (
    <Flex flexWrap="wrap" justifyContent="center" alignItems="center">
      <StyledFlex justifyContent="end" alignItems="end" mb={3}>
        <Button px={3} onClick={navigateToAddCertificate}>
          {certificateHeader()}
        </Button>
      </StyledFlex>
      <StyledFlex justifyContent="space-between" flexWrap="wrap">
        {certificates ? (
          certificates.map((el) => (
            <CertificateCard
              key={el.id}
              certificate={el}
              setIsModalOpen={setIsModalOpen}
              setDeleteCertificateId={setDeleteCertificateId}
            />
          ))
        ) : (
          <Flex m="auto">
            <Typography.P variant="h2" color="dark">
              {t("noCertificates")}
            </Typography.P>
          </Flex>
        )}
      </StyledFlex>
      <Modal isOpen={isModalOpen} onCancelClick={() => setIsModalOpen(false)}>
        <Box>
          <P variant="h3">{t("areYouSureToDeleteCertificate")}</P>
          <Flex justifyContent="end" mt={3} flexWrap="wrap">
            <Box mr={2}>
              <Button
                onClick={() => {
                  setDeleteCertificateId(undefined);
                  setIsModalOpen(false);
                }}
              >
                {t("no")}
              </Button>
            </Box>
            <Button onClick={onDeleteConfirmation}>{t("yes")}</Button>
          </Flex>
        </Box>
      </Modal>
    </Flex>
  );
};
