import { http } from "utilities/http";
import {
  REGULATIONS_URL,
} from "constants/paths";
import {IRegulations} from "types/forms/regulation";

export const fetchRegulations = () =>
  http<IRegulations>(REGULATIONS_URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
